import validators from './index';

const validateAll = (data, setError) => {
    const errors = Object.entries(data).reduce((arr, [k, o]) => {
        const res = validators[k](o, data);
        if (res)
            arr[k] = res;
        return arr;
    }, {});
    setError(errors);
    return Object.values(errors).length <= 0;
};

export default validateAll;