import { useState } from "react";
import List from "./list";
import ListForm from "./listForm";


const Burger = props => {
    const [show, setShow] = useState(false);
    const click = _ => setShow(!show);
    const close = _ => setShow(false);

    return (
            <span onClick={click} style = {{backgroundImage: 'url(skin/img/burger.svg)', backgroundRepeat:'no-repeat', width:'30px', height:'30px'}}>
                {show &&  props.table && <List all = {props.all} onClose = {close} click = {props.click} />}
                {show && !props.table && <ListForm all = {props.all} onClose = {close} click = {props.click} />}
            </span>  
    )
}

export default Burger;