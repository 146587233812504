import { useState } from "react";

// Состояние кода проверки телефона;
const useCode = (submit) => {
    // Значение введённого кода в поле ввода; 
    const [value, setValue] = useState('');
    // Флаг ожидания ответа от сервера после отправки кода;
    const [pending, setPending] = useState(false);

    // Обработчик контролируемого поля ввода;
    const onChange = (v) => {
        // Поле ввода не может быть длиннее 4 символов,
        // и его нельзя изменять, пока код отправляется на сервер или
        // если он уже подтверждён;
        if (submit || pending || v.length > 4) return;
        setValue(v);
    };
    // code, setPending, setSubmit, setError, phone, setTimer
    return { value, setValue, onChange, pending, setPending };
};

export default useCode;