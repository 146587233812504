import { status } from 'skin/event/status';
import { message } from 'skin/event';

const category = (obj, ms, story, data, d, action) => {
	const info = data.curData.values.fld.cPartId
	if (!info) {
		message(`Укажите категорию партнера`, 'Партнерские продукты');
		return status.ignore
	}
	return status.action
};

export default category;
