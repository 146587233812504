import { status } from 'skin/event/status';
import step from './step'

const master = (obj, ms, story, data, d, action) => {
	switch (obj.action) {
		case 'step':
			return step(obj, ms, story, data, d, action);
		default:
			return status.action;
	}
}

export default master;
