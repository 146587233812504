import { useEffect, useState } from "react";
import useBase from "../base/use_base";

// Состояние поля с информацией об организации;
const useCompany = (company) => {
    // Поле ввода инн
    const {value, setValue, onChange} = useBase();
    // Опционально: адрес организации (виден, если апи не нашло его)
    const {value: address, setValue: setAddress, onChange: onAddressChange} = useBase();

    // Сбрасываем ошибку и адрес, если информация о компании изменилась;
    useEffect(() => {
        setAddress('');
    }, [company]);

    return {address, setAddress, onAddressChange, value, setValue, onChange}
};

export default useCompany;