import { status } from 'skin/event/status';
import select from './select'
import unselect from './unselect'

const click = (obj, ms, story, data, d, action) => {
    switch (obj.code) {
        case 'select': return select(obj, ms, story, data, d, action)
        case 'unselect': return unselect(obj, ms, story, data, d, action)
        default: return status.action
    }
};

export default click;