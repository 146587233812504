import { useEffect, useState, useRef } from 'react'
import { CKEditor } from 'ckeditor4-react'
import Error from '../error'
import Label from './lable'
import './style.css'
import config from './fn'

const Editor = ({ s, v, e, setValue }) => {
	const {
		_id,
		name,
		// title = '',
		disabled = false,
		off = false,
		type = 'type',
		width = '100%',
		height = '200px',
	} = s

	let ed = useRef(null)
	const [flag, setFlag] = useState(false)
	const style = off ? { display: 'none', visibility: 'hidden' } : {}
	const cnf = { ...config, width, height }
	// Значение
	const [val, setVal] = useState(v)

	// Массив ошибок - получаем пропсами из mobx
	const [err, setErr] = useState(e)
	// Индикатор Показывали ошибки или нет
	const [status, setStatus] = useState(false)

	useEffect(() => {
		// Защита от сброса курсора в конец текста
		if (v !== val) {
			setVal(v)
		}
	}, [val])

	useEffect(() => {
		setErr(e)
	}, [e])

	useEffect(() => {
		if (ed.current) ed.current.setData(v)
	}, [flag])

	let cls = ['skin-input-base', 'input-base', 'input-editor']

	if (err) cls.push('error')
	cls = cls.join(' ')
	return (
		<div className={cls} style={style}>
			<Label label={s?.label} />
			<div className='editor' id={_id} onClick={onFocus}>
				<CKEditor
					style={{ borderRadius: '20px', overflow: 'hidden', borderColor: '#f3f3f3' }}
					config={cnf}
					readOnly={disabled}
					onChange={({ editor }) => onChange(editor)}
					initData={val}
					onInstanceReady={({ editor }) => {
						ed.current = editor
						setFlag(true)
					}}
				/>
			</div>

			{/* Ошибки */}
			{status ? <Error err={err} onClick={() => setStatus(false)} /> : null}
		</div>
	)

	function onChange(e) {
		const data = e.getData()
		setStatus(false)
		setErr(null)
		setVal(data)

		setValue(name, data, type)
	}

	// Показываем список ошибок
	function onFocus(e) {
		if (err && !status) {
			setStatus(true)
		}
	}
}

export default Editor

// config={{
//     uiColor: '#FFFFFF',
//     toolbarGroups: toolbarGroups,
//     extraPlugins: 'editorplaceholder,colorbutton, justify,font',
//     editorplaceholder: '...',
//     removeButtons: 'About,Scayt',
// }}
