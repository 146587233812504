import { status } from 'skin/event/status'

const row = (obj, ms, story, data, d, action) => {
	const list = data.curData.values.table.product.list
	const i = list.findIndex(el => el.key === obj.key)
	const val = list[i].list[0] === '+' ? '' : '+'
	data.setCell('product', obj.key, 0, val)
	return status.ignore
};

export default row