import { status } from 'skin/event/status';
import table from './table';
import dt from './data'

const msPickup = (obj, ms, story, data, d, action) => {
    console.log('[Выгрузка отзывов - Мастер] событие', obj);
    switch (obj.type) {
        case 'table': return table(obj, ms, story, data, d, action);
        case 'data': return dt(obj, ms, story, data, d, action)
        default: return status.action;
    }
}

export default msPickup