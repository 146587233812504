import { status } from 'skin/event/status'
import combi from './combi'

const setInput = (obj, ms, story, data, d, action) => {
	switch (obj.name) {
		case 'combi':
			return combi(obj, ms, story, data, d, action)
		default:
			return status.action
	}
}

export default setInput
