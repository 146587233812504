import { status } from 'skin/event/status';
import dt from 'skin/store/master/data'

const row = (obj, ms, story, data, d, action) => {
    const list = story.curData.values.table.msCell.list
    const o = story.curData.values.fld.object
    list.forEach(el => {
        if(el.key === obj.key) {
            dt.setValue('place', el.list[0])
            dt.setValue('txtCell', el.list[2])
            dt.setValue('txtRack', el.list[1])
            dt.setValue('cellId', o[el.key])
        }
    });
    const key = ms.s.pickCell.table.msCell.row
    if(obj.key === key) {
        return status.ignore
    }
	return status.action
};

export default row;
