import InputMask from 'react-input-mask';
import Button from 'skin/entry/signup/cmp/button';
import { dictionary } from 'skin/event';
import './base.css';

import Err from 'skin/entry/signup/cmp/err';

// Компонент - поле ввода текста;
const Base = ({ value, setValue, title, mask, placeholder, hide, button, img, err, setError, dict, disabled }) => {
    return (
        <div className='field-container'>
            <span className="field-title">{ title }</span>
            <div className='field-input'>
                <InputMask
                    mask={mask}
                    value={value}
					disabled={disabled}
                    onChange={e => {
                        setValue(e.target.value);
                        setError(null);
                    }}
                    placeholder={placeholder}

                    type={hide ? 'password' : 'text'}
                />
                {button && <Button {...button} />}
                {img && <div className='img-container'><img alt={title} src={img}/></div>}
				{dict && 
					<div className='reverseBtn' onClick={icoClick}>
            			<img className='alt-img-container' src={dict.img} alt=""/>
            			<span>{dict.text}</span>
					</div>
				}
            </div>
            <Err msg={err} />
        </div>
    );
	function icoClick() {
		if (!dict?.code) return;
		// Отображаем дешифратор
		const o = { code: dict.code, func: setV, unauth: true };
		dictionary(o);
		function setV(v) {
			// const fld = document.getElementById(id)
			// fld.value = v
			v = Array.isArray(v) ? v[0] : v
			setValue(v)
		}
	
	}
};


export default Base;