const password2 = (v, data) => {
    const pwd = data?.password;
    if (!v) {
        return 'Введите пароль';
    };
    if (pwd !== v) {
        return 'Пароли не совпадают';
    }
};

export default password2;