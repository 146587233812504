import { status } from 'skin/event/status';
import master from './master';

const syncTo = (obj, ms, story, data, d, action) => {
    console.log('[Ячейки - Мастер] событие', obj);
    switch (obj.type) {
        case 'master': 
            return master(obj, ms, story, data, d, action)
        default:
			return status.action
    }
}

export default syncTo