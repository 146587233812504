import api from 'skin/http';

//Запрос на отправку смс
function check(phone) {
	return new Promise((resolve, reject) => {
		const opt = {
			method: 'GET',
			url: 'api/other/signup/phone/check',
			params: {
				val: phone
			},
			headers: {
				nowait: true
			}
		}
		api(opt)
		.then(r => resolve(r.data))
		.catch(reject)
	})
}

export default check