import { status } from 'skin/event/status'

const row = (obj, ms, story, data, d, action) => {
	const reserveCol = ['_id','order'] //'name', 'value', 'unit', 'make'
	// const list = data.curData.values.table.column.list
	// const i = list.findIndex((el) => el.key === obj.key && el.key !== '_id')
	// const val = list[i].list[0] === '+' ? '' : '+'
	// data.setCell('column', obj.key, 0, val)
	// Зарезервированные колонки (всегда экспортируются в файл)
	if (!reserveCol.includes(obj.key)) data.setCell('column', obj.key, 0, '+')
	return status.ignore
}

export default row
