import { status } from "skin/event/status";
import screen from "./screen";
import screenIOS from "./screenIOS";

const table = (obj, form, story, data, d, action) => {
    console.log('[Графика - таблица] событие', obj);
    switch(obj.table) {
        case 'screen':
            return screen(obj, form, story, data, d, action);
        case 'screenIOS':
            return screenIOS(obj, form, story, data, d, action);
        
        default:
    }
    return status.action;
}


export default table;