import { status } from "skin/event/status";
import { toJS } from "mobx";

const wOut = (obj, ms, story, data, d, action) => {
	console.log("[Загрузка отчёта - Мастер] событие", obj);
	const dt = toJS(data);
	const oo = { code: "rfmData", info: [dt.s.msData.values.fld["_id"]] };
	const o = {
		type: "form",
		title: "Отчёт",
		data: oo,
	};
	const act = {
		type: "master",
		action: "finish",
		finish: o,
	};
	action(act);
	return status.ignore;
};

export default wOut;
