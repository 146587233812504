import '../code.css';
// import Button from 'skin/entry/signup/cmp/button';
import iCross from 'skin/entry/signup/images/cross.svg';

// Панель справа от поля ввода кода - при ошибке;
const fail = (err) => {
    if (!err) return null;
    return(
        <div className='code-check-failed'>
            {/*<Button title={`${err}`} isError={true} type="alt" onClick={onClick} />*/}
            <div className='errorText'>{err}</div>
            <div className='img-container red-circle-bound'><img alt={'fail'} src={iCross}/></div>
        </div>
    );
}

export default fail;