import {info} from 'skin/event';
import './style.css';

const Info = props => {
	let cls = ['skin-header-info', 'header-info', 'ico', props.mb ? 'mb-und' : '']
	cls = cls.join(' ');
	const st = { backgroundImage: 'url(skin/img/functions.svg)' };
	return (
		<span
			className={cls}
			style={st}
			title='Служебная информация'
			onClick={getInfo}
		></span>
	)
}
// Информация о странице
function getInfo() {
	const a = document.querySelectorAll('[data-info]')
	const inf = []
	a.forEach(el => {
		const o = {}
		o.title = el.dataset.infoTitle ?? ''
		o.value = el.dataset.info
		inf.push(o)
	})
	info(inf)
}

export default Info
