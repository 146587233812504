import { status } from 'skin/event/status';
import act from './action';

const traffic = (obj, form, story, data, d, action) => {
	console.log('[Трафик - форма] событие', obj);
	switch (obj.type) {
		case 'action':
			return act(obj, form, story, data, d, action);
		default:
	}
	return status.action;
};

export default traffic;
