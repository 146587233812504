import iKey from 'skin/entry/signup/images/key.svg';
import iUser from 'skin/entry/signup/images/login.svg';
import iMarker from 'skin/entry/signup/images/marker.svg';

const data = {
    login: {
        type: 'base',
        category: 'user',
        name: 'login',
        title: 'Логин',
        placeholder: 'Введите логин',
        img: iUser,
    },
    password: {
        type: 'base',
        category: 'user',
        name: 'password',
        title: 'Пароль',
        placeholder: 'Введите пароль',
        img: iKey,
        hide: true,
    },
    password2: {
        type: 'base',
        category: 'user',
        name: 'password2',
        title: 'Повторите пароль',
        placeholder: 'Введите пароль',
        img: iKey,
        hide: true,
    },
    phone: {
        type: 'phone',
        category: 'user',
        name: 'phone',
        title: 'Номер телефона',
        placeholder: '+7 (___) ____ __-__',
    },
    company: {
        type: 'company',
        category: 'company',
        name: 'company',
        title: 'Организация',
        placeholder: 'Введите ИНН организации',
        def: null,
    },
    where: {
        type: 'base',
        category: 'company',
        name: 'where',
        title: 'Откуда Вы о нас узнали?',
        placeholder: 'Выберите из',
		disabled: true,
        dict: {
			text:'Источники',
			code: 'where',
			img: iMarker
		},
    },
};

export default data;