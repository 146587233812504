import { status } from 'skin/event/status';
import row from './row';

const table = (obj, form, story, data, d, action) => {
	switch (obj.action) {
		case 'row':
			return row(obj, form, story, data, d, action);
		default:
	}
	return status.action;
};

export default table;
