import { status } from 'skin/event/status';
import del from './del'

const control = (obj, ms, story, data, d, action) => {
	console.log('[Ячейки - Мастер] событие', obj);
	switch (obj.action) {
		case 'delete': return del(obj, ms, story, data, d, action)
		default:
			return status.ignore
	}
}

export default control