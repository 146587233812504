import { status } from 'skin/event/status';
import product from './product';

const setInput = (obj, ms, story, data, d, action) => {
	switch (obj.name) {
		case 'productId':
			return product(obj, ms, story, data, d, action);
		default:
			return status.action;
	}
};

export default setInput;
