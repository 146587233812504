import { status } from 'skin/event/status';

const chkAll = (obj, ms, story, data, d, action) => {
	const list = data.curData.values.table.product.list

	if (list.every(el => el.list[0] === '+')) {
		list.forEach(el => {
			data.setCell('product', el.key, 0, '')
		})
	} else list.forEach(el => {
		data.setCell('product', el.key, 0, '')
		data.setCell('product', el.key, 0, '+')
	})
	return status.action
}

export default chkAll