

//тип элемента (заголовок, таблица, текст)
import { Title } from "./title"
import { Table } from "./table"
import { Str } from "./str"

export default function Item({txt, style}) {
	let i = txt.indexOf('!')
	if (i === 0) return <Title txt={txt.substring(i+1)} style={style} />
	i = txt.indexOf('[]')
	if (i > -1) return <Title txt={txt.substring(i+2)} style={style} />
	i = txt.indexOf('[t]')
	if (i > -1) return <Table txt={txt.substring(i+3)} style={style} />
	i = txt.indexOf(';')
	if (i > -1 && i < 100) return <Table txt={txt} style={style} />
	return <Str txt={txt} style={style} />
}