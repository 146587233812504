import { status } from 'skin/event/status';
import check from './check';

const msDeliv = (obj, ms, story, data, d, action) => {
	console.log('[Маршрутный лист - Мастер] событие', obj);
	switch (obj.action) {
		case 'check':
			return check(data, story, action);
		default:
			return status.action;
	}
};

export default msDeliv;
