import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Button from 'skin/entry/signup/cmp/button';

import { cooldown } from "../code_check";

// Панель справа от поля ввода кода - при заполенном поле;
const submit = ({timer/*, onClick*/}) => (
    <>
        {/*<Button title={'Проверить код'} type="alt" onClick={onClick} />*/}
        <div></div>
        <CountdownCircleTimer
            key={'codeTimer'}
            isPlaying={timer > 0}
            initialRemainingTime={timer}
            duration={cooldown}
            colors={'#73992A'}
            size={22}
            strokeWidth={1}
            onComplete={() => ({
                shouldRepeat: true,
            })}
        >
        {() => timer}
        </CountdownCircleTimer>
    </>
);

export default submit;