import { status } from 'skin/event/status';
import month from './month'

const dict = (obj, ms, story, data, d, action) => {
	switch (obj.code) {
		case 'month':
			return month(obj, ms, story, data, d, action);
		default:
			return status.action;
	}
};

export default dict;
