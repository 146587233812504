import Title from './title'
import Main from './main'
import Action from './action'
import './style.css'

const Content = props => {
	let cls = [
		'skin-mess-content',
		'mess-content'
	]
	cls = cls.join(' ')
	return (
		<div className={cls}>
			<Title data={props.data} />
			<Main text={props.data.text} />
			<Action action={props.data.action} />
		</div>
	)
}


export default Content
