import { status } from 'skin/event/status';
import act from './act';
import master from './master'

const makeProd = (obj, ms, story, data, d, action) => {
	console.log('[Ячейки - Мастер] событие', obj);	
	switch (obj.type) {
		case 'master': {
			return master(obj, ms, story, data, d, action)
		}
		case 'action':
			return act(obj, ms, story, data, d, action)
		
		default:
			return status.action;
	}
};

export default makeProd;
