import './company.css';

import Err from 'skin/entry/signup/cmp/err';
import CompanyField from './company_field';
import CompanyInfo from './company_info';
import useCompany from './use_company';

const Company = ({ value: company, setValue: setCompany, err, ...props }) => {
    const state = useCompany(company, setCompany);
    return (
        <div>
            {
                company
                ?
                <CompanyInfo company={company} setCompany={setCompany} {...props} {...state}/>
                :
                <CompanyField company={company} setCompany={setCompany} {...props} {...state}/>
            }
            <Err msg={err} />
        </div>
    );
};

export default Company;