import { message } from 'skin/event';
import { status } from 'skin/event/status';
import api from 'skin/http';

const act = (obj, form, story, data, d, action) => {
	if (obj.id !== '638ef16d702f822632a52282') return status.action
	const id = story.current.info[0]
	const config = {
		method: 'delete',
		url: 'api/other/form/goods/discount/' + id,
		
	};
	api(config)
		.then(res => {
			const o = {
				type: 'action',
				action: 'close'
			}
			action(o)
			message(`Удаление скидок выполнено успешно`, 'Категории и товары');
		})
		.catch(_ => {
			const o = {
				type: 'action',
				action: 'close'
			}
			action(o)	
			message(`Удаление скидок выполнено с ошибками.\n Обратитесь к администратору`, 'Категории и товары')
		});
	return status.ignore;
};

export default act;
