import Button from 'skin/entry/signup/cmp/button';
import { cooldown } from "../code_check";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import iPhone from 'skin/entry/signup/images/phone.svg';

// Панель справа от поля ввода кода - при незаполенном поле;
const idle = ({timer, onClick}) => (
    <>
        <Button title={'Новый код'} disabled={timer>0} img={iPhone} type="reverse" onClick={onClick} />
        <div className='code-sidebar-timeleft'>
            <CountdownCircleTimer
                key={'codeTimer'}
                isPlaying={timer > 0}
                initialRemainingTime={timer}
                duration={cooldown}
                colors={'#73992A'}
                size={22}
                strokeWidth={1}
                onComplete={() => ({
                    shouldRepeat: true,
                })}
            >
                {() => timer}
            </CountdownCircleTimer>
        </div>
    </>
);

export default idle;