import { status } from 'skin/event/status';

const unselect = (obj, ms, story, data, d, action) => {
    const list = data.curData.values.table.prod.list
    list.forEach(el => {
        if (el.list[0]) data.setCell('prod', el.key, 0, '+')
    })
    return status.action
}

export default unselect