import { observer } from 'mobx-react-lite'
import dict from 'skin/store/dict'
import './style.css'
import { useEffect, useRef, useState } from 'react'

// Заголовк справочника с полем поиска
const Head = props => {
	const v = props.find.value
	const ref = useRef()
	const [value, setValue] = useState(v)
	useEffect(_ => {
		setValue(v)
		return _ => ref.current && clearTimeout(ref.current)
	}, [v])
	let cls = [
		'skin-dct-cnt-head',
		'dct-cnt-head'
	]
	cls = cls.join(' ')
	const st = { backgroundImage: 'url(skin/img/dct_find.svg)' }
	return (
		<div className={cls}>
			<p>{dict.s.title}</p>
			<div style={st}>
				<input type='text' value={value} onChange={find} />
			</div>
		</div>
	)
	// Поиск в справочнике
	function find(e) {
		const val = e.target.value
		setValue(val)
		if (ref.current) clearTimeout(ref.current)
		ref.current = setTimeout(_ => {
			props.find.setFind(val)
		}, 500
		)
	}
}

export default observer(Head)
