import { status } from 'skin/event/status';
import user from 'skin/store/user';
import api from 'skin/http';

const load = (obj, table, story, d, action) => {
	api.get('api/data/count/product')
		.then((res) => {
			user.chPrd(res.data.result);
			return status.action;
		})
		.catch(console.log);
};
export default load;
