import './style.css'

const Btn = ({ title, act, submit }) => {
    return (
        <button 
            className='login-main-btn'
            onClick={act}
            type={submit ? 'submit' : 'button'}
            >
            {title}
        </button>
    )
}

export default Btn