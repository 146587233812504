import { createElement } from 'react';
//Просто текст 
export const Str = ({txt, style}) => {
  const str = txt.split("\n");
  return (
    <>
		{str.map((el,i) => {
			const a = el.split('http')
			const r = a.map(el => {
				if (!/^s?:\/\//.test(el)) return el
				const i = el.indexOf(' ')
				if (i === -1) return createElement('a', {href: 'http' + el}, 'http' + el)
				const link = 'http' + el.substring(0, i)
				const t = el.slice(i - el.length)
				return [createElement('a', {href: link}, link), ' ' + t]
			}).flat()
			return 	createElement('p', {}, ...r)

		})}
    </>
  )
}