import { status } from 'skin/event/status';
import user from 'skin/store/user';
import { message } from 'skin/event';

const add = (obj, table, story, d, action) => {
	const cmpP = table.s.body.count;
	const tarP = user.tar.max?.pickup;
	if (!tarP) return status.action;
	if (cmpP < tarP) return status.action;
	message(
		'Достигнут максимум пунктов самовывоза по тарифу',
		'Максимум пунктов самовывоза',
		null,
		'!'
	);
	return status.ignore;
};
export default add;
