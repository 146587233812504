import { status } from 'skin/event/status'

const next = (obj, ms, story, data, d, action) => {
    const list = data.curData.values.table.prod.list
    const fld = data.curData.values.fld
    const prod = list.filter(el => el.list[0]).map(el => ({
        _id: el.list[1],
        name: el.list[2]
    }))
    // if (!prod.length) return status.action
    const o = {
        name: 'Выгрузка файла с отзывами',
        in: {
            name: fld.name,
            count: fld.count,
            category: fld.txtCtg,
            prod
        },
        pug: true
    }
    const act = {
        type: 'data',
        action: 'wAdd',
        code: 'rewDown',
        data: o
    }
	action(act)
    return status.action
}

export default next