import { status } from 'skin/event/status';
import cnfTask from 'config_task'
import user from 'skin/store/user';

const add = (obj, form, story, data, d, action) => {
	if(obj.id === '62ac433db8963d491b7127f1') {
		const productId = story.current.info[0]
		const o = {
			productId,
			imgUrl: `${cnfTask.url}company/${user.code}/img/`
		}
		const oo = {
			type: 'form',
			code: 'varProd',
			action: 'print',
			params: o
		}
		action(oo)
		return status.ignore
	}
};

export default add;
