import { status } from 'skin/event/status';
import prod from './prod'

const del = (obj, form, story, data, d, action) => {
	switch (obj.table) {
		case 'mProd':
			return prod(obj, form, story, data, d, action);
		default:
	}
	return status.action;
};

export default del;
