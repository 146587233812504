import { status } from 'skin/event/status';
import api from 'skin/http';

const product = (obj, ms, story, data, d, action) => {
	const idP = obj.value
		const config = {
			method: 'get',
			url: 'api/other/master/pac/'+idP,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		};
		api(config)
			.then(res => {
				data.delList('propItem')
				data.setValue('txtMod', '')
				data.setValue('prdVal', '')
				data.setValue('productId',idP)
				res.data.rows.forEach(el => {
					data.addRow(ms, 'propItem', el)
				});
			})
			.catch(console.log)
			.finally(_ => {return status.action})
};

export default product;
