import { status } from 'skin/event/status';

const row = (obj, ms, story, data, d, action) => {
    const list = data.curData.values.table.except.list
    const idx = []
    list.forEach(el => {
        if (el.key === obj.key) child(list, el.list[1], idx)
    })
    idx.forEach((el, ind) => {
        const i = list.findIndex(e => e.key === el)
        const val = ind === 0 ?
            (list[i].list[0] === 'v' ? '' : 'v')
            :
            list[list.findIndex(e => e.key === idx[0])].list[0]
        data.setCell('except', el, 0, val, _ => val)
    })
    return status.ignore
};

export default row;

function child(list, id, arr) {
    arr.push(id)
    const elem = list.filter(el => el.key === id)[0].list
    console.log(elem);
    if (elem[4]?.length) {
        elem[4].split('|').map(e => child(list, e, arr))
    }
}