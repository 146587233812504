import Command from './command';
import Footer from './footer';
import Body from './body';
import './style.css';
import m from "skin/store/mb";
import { observer } from 'mobx-react-lite'

const Content = () => {
	let cls = ['skin-form-content', 'form-content'];
	cls = cls.join(' ');
	const mb = m.isMobile;
	return (
		<div className={cls}>
			<Command />
			<Body />
			{!mb && <Footer />}
		</div>
	);
};

export default observer(Content);
