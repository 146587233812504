import Command from './command';
import './style.css';
import cnfTask from 'config_task';
import data from '../../../config_task';

const Footer = () => {
	let cls = ['skin-footer', 'clr-t3', 'footer'];
	cls = cls.join(' ');
	const st = { backgroundImage: 'url(skin/img/doc.svg)' };
	const link = data.docs.map((el) => {
		const href = data.url + el.file + ms();
		return (
			<a
				key={el.file}
				href={href}
				target="_blank"
				rel="noopener noreferrer"
			>
				<div className="offer" style={st}>
					<p>{el.text}</p>
				</div>
			</a>
		);
	});

	return (
		<footer className={cls}>
			<div className="d-flex jc-between f-grow-1">
				<Command />
				<div className="docs">{link}</div>
				<p>{cnfTask.copyright}</p>
			</div>
		</footer>
	);
};

export default Footer;

function ms(){
	const t = new Date()
	return `?${t.getMinutes()}:${t.getSeconds()}`
}