import { useEffect, useState } from "react"; 

// Пользовательский хук состояния поля ввода;
const useBase = (initialValue='') => {
    // Значение в поле ввода;
    const [value, setValue] = useState(initialValue);

    // Обработчик контролируемого поля ввода;
    const onChange = (e) => {
        if (e.target.value !== value) {
            setValue(e.target.value);
        }
    };

    return {value, setValue, onChange};
};

export default useBase;