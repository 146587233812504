import { message } from 'skin/event';
import { status } from 'skin/event/status';
import api from 'skin/http';
//Добавление сотрудника для интеграции с яндекс едой
function yandex(obj, table, story, d, action) {
	const opt = {
		method: 'get',
		url: 'api/other/table/userEmp/ya'
	}
	api(opt)
	.then(r => {
		const d = r.data
		const msg = ['Пользователь успешно добавлен!',`Логин: ${d.login}`,`Пароль: ${d.password}`]
		message(msg, 'Создание пользователя Яндекс')
	})
	.catch(err => console.log('err', err))
	return status.ignore
}

export default yandex