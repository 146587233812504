import { status } from 'skin/event/status'
import row from './row'

const tbl = (obj, table, story, d, action) => {
    switch (obj.action) {
        case 'row': return row(obj, table, story, d, action)
        default: return status.action
    }
}

export default tbl