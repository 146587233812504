import { observer } from 'mobx-react-lite';
import LogoL from 'skin/cmp/logo-l';
import mode from 'skin/data/mode';
import user from 'skin/store/user';
import './style.css';

const Left = props => {
	let cls = ['skin-header-left', 'header-left', 'd-flex jc-between ai-center'];
	cls = cls.join(' ');
	const md = props.mode ?? 'menu'
	let st = mode[md]?.img ?? ''
	st = { backgroundImage: `url(skin/img/mode_${st}.svg)` };
	const name = mode[md]?.name ?? ''

	return (
		<div className={cls}>
			<LogoL task={true} />
			<button className="btn-i info">
				Код  <strong>{user.code}</strong>
			</button>
			{!props.mb && <><button className="btn-i info">
				<span style={st}></span>
				<span className='mode'>{name}</span>
			</button></> }
		</div>
	)
}

export default observer(Left)
