import { status } from "skin/event/status"
import btn from './btn'

const property = (obj, ms, story, data, d, action) => {
    console.log('[Ячейки - Мастер] событие', obj);
    switch (obj.type) {
        case 'btn': return btn(obj, ms, story, data, d, action)
        case 'table': return status.ignore
        default:
			return status.action
    }
}

export default property