import { status } from 'skin/event/status';
import table from './table';
import act from './action';

const orders = (obj, form, story, data, d, action) => {
	console.log('[Заказы - форма] событие', obj);
	switch (obj.type) {
		case 'table':
			return table(obj, form, story, data, d, action);
		case 'action':
			return act(obj, form, story, data, d, action);
		default:
	}
	return status.action;
};

export default orders;
