import { status } from 'skin/event/status'
import { space } from 'skin/event';

const btnAdd = (obj, ms, story, data, d, action) => {
	console.log('[Перевод в остатки, клик "Добавить категорию" - Мастер] событие', obj)
    const o = {
		type: 'master',
		title: 'Добавление категории',
		data: { code: 'msCtg', info: [], type:'Приход' },
	};
	space(o, true);
	return status.ignore
}

export default btnAdd
