import { status } from 'skin/event/status';
import { message } from 'skin/event';

const btn = (obj, ms, story, data, d, action) => {
	console.log('[Перевод в остатки, клик "Заполнить" - Мастер] событие', obj);
    const pName = data.curData.values.fld.pName
    const key = story.current.table.arrToRem.row
    const productId = data.curData.values.fld.productId
    if(productId ===null) {
      message(`Выберите товар`, 'Перевод в остатки');
      return status.ignore
    }
    data.setCell('arrToRem', key, 2, pName, _=> pName)
	data.setCell('arrToRem', key, 3, productId, _=> productId)
	return status.action
};

export default btn;
