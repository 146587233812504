import { status } from 'skin/event/status';
import table from './table';
import btn from './btn'
import master from './master';

const pickCell = (obj, ms, story, data, d, action) => {
	console.log('[Ячейки - Мастер] событие', obj);
	switch (obj.type) {
		case 'master':
			return master(obj, d, action, ms, story, data)
		case 'table':
			return table(obj, d, action, ms, story, data);
		case 'btn':
			return btn(obj, d, action, ms, story, data);
		default:
			return status.action;
	}
};

export default pickCell;
