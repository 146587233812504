import { status } from "skin/event/status";

const row = (obj, form, story, data, d, action) => {
    switch (obj.table) {
        case 'modCli': {
            const cur = story.current.table.modCli.row
            if (obj.key === cur) return status.ignore
            return status.action
        }
        default: return status.action
    }
}

export default row