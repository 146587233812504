import { status } from "skin/event/status";
import { message } from "skin/event";

const next = (obj, ms, story, data, d, action) => {
    console.log("[Отчет - Мастер] событие", obj);
    const f = data.getAll(ms);
    // Проверка полей
    for (let key in f.date) {
        if (!f.date[key]) {
            message("Ошибка в данных.\n Проверте значения полей.");
            return status.ignore;
        }
    }

    const o = {
        name: 'Отчет "Пригласи друга"',
        in: { ...f.date, min: f.all["min"] ?? 1 },
        pug: true,
    };

    const act = {
        type: "data",
        action: "wAdd",
        code: "msFriend",
        data: o,
    };
    action(act);
    return status.action;
};

export default next;
