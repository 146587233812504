import './form.css';

import classList from './cmp/class_list.js';
import fields from './fn/fields.js';

import Field from './cmp/field';
import useReg from './fn/use.js';

import { observer } from 'mobx-react-lite';
import Menu from 'skin/cmp/header';
import Notify from 'skin/cmp/notify';
import Dict from 'skin/dict';
import Shadow from 'skin/space/shadow';
import Wait from 'skin/space/wait';
import m from 'skin/store/mb';
import Body from './body';
import Footer from './footer';
import Header from './header';

const Signup = ({data}) => {
    // Вид формы (false - компактный | true - широкий)
    const wide = !m.isMobile;
    // Все поля
    const {result, err, updateData, getValue, setError, updateError, getError} = useReg();

    // Обёртка для поля;
    const wrap = o => <Field
        type={o.type}
        key={`field_${o.name}`}
        value={getValue(o.name)}
        setValue={v => updateData(o.name, v)}
        err={getError(o.name)}
        setError={v => updateError(o.name, v)}
        {...o}
    />;
    
    // Разбиение на группы "company" и "user";
    const blocks = Object.values(fields).reduce((arr, o) => {
        arr[o.category].push(wrap(o))
        return arr;
    }, {company:[], user:[]})

    return (
		<main
			className='login'
			style={{ backgroundImage: 'url(/task/img/main.png)' }}
		>
			<Menu />
			<form className={classList('form-wrapper', !wide && 'form-wrapper-mobile')}>
				<Header wide={wide} />
				<Body wide={wide} blocks={Object.values(blocks)}/>
				<Footer wide={wide} data={result} err={err} setError={setError} />
			</form>
			<div >
				<Dict data={data.dictionary} />
				<Shadow data={data.wait.on} />
				<Wait data={data.wait} />
				<Notify data={data.notify} />
			</div>
		</main>
    );
};

export default observer(Signup);