import { useEffect } from "react";
import params from "./params";
import query from "./query";

export default function useParams() {
	// const { action } = useContext(Cnt);
	useEffect(() => {
		replaceUrl()
	}, [])
	
}

function replaceUrl() {
	const q = new URLSearchParams(window.location.search)
	let path = window.location.pathname
	path = path.slice(1).split('/')[0]
	if (path) return params(path)
	if (q.size) return query(q)
	return null
}