import { status } from 'skin/event/status'
import row from './row';
import control from './control';

const price = (obj, table, story, d, action) => {
    console.log('[Ячейки - Мастер] событие', obj)
    switch (obj.action) {
        case 'row': return row(obj, table, story, d, action)
    }
    switch (obj.type) {
        case 'control': return control(obj, table, story, d, action)        
    }
    return status.action
}

export default price