import { status } from 'skin/event/status';

const add = (obj, form, story, data, d, action) => {
	if(obj.id === '62c7e2d23bd73c1e066e15a8') {
		const id = story.current.info[0]
		const o = {
			id,
		}
		const oo = {
			type: 'form',
			code: 'message',
			action: 'print',
			params: o
		}
		action(oo)
		return status.ignore
	}
};

export default add;
