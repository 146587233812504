//Вернуть дату в нужном формате
function fDate(f, date=new Date()) {
	const d = new Date(date)
	const year = d.getFullYear()
	const month = d.getMonth() + 1
	const day = d.getDate()
	const h = d.getHours()
	const m = d.getMinutes()
	const def = {
		//yyyy-MM-dd
		1:year + '-' + month.toString().padStart(2, "0") + '-' + day,
		//hh:mm
		2: h.toString().padStart(2, "0") + ':' + m.toString().padStart(2, "0"),
		//dd.mm.yyyy
		3: day.toString().padStart(2, "0") + '.' + month.toString().padStart(2, "0") + '.' + year,
		//dd.mm.yyyy hh:mm
		4:
			day.toString().padStart(2, "0") + '.' 
			+ month.toString().padStart(2, "0") + '.' 
			+ year + ' '
			+ h.toString().padStart(2, "0") + ':' 
			+ m.toString().padStart(2, "0"),
	}
	return def[f]
}


export { fDate }
