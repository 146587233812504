import { useState } from 'react';

import api from 'skin/http';
import Btn from '../../cmp/btn';
import Title from '../../cmp/title';
import Field from '../../cmp/field';
import Mes from '../../cmp/mes';
import { type } from 'skin/event';
import m from 'skin/store/mb';
import { observer } from 'mobx-react-lite';

import styles from './styles.module.css';

const Win = (props) => {
	const mb = m.isMobile;
	const [login, setLogin] = useState('');
	const [email, setEmail] = useState('');
	const [mes, setMes] = useState('');

	let clsFields = 'login-main-fields';
    if(mb) clsFields += ' mb-fields';

	return (
		<div className='login-content'>
		<div style = {mb ? {padding:"24px"} : {}} className='login-main-container'>
		<div style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>	
				<Title name = {'Сброс пароля'} />
				{mb && <img 
						className={'signup-close'}
						src='/skin/img/close_circle.svg' alt='x' 
						onClick={log} />}
				
			</div>
			<form style = { mb ? {display:'flex', flexDirection:'column', alignItems:'center', gap:'20px', paddingTop:'20px'} : {}} >
				<div className={clsFields}>
					<Field
						title="Логин"
						placeholder="Введите логин"
						img="/task/img/login_p.svg"
						act={setLogin}
						type="text"
					/>
					<Field
						title="Электронная почта"
						placeholder="Введите почту при регистрации"
						img="/task/img/email_p.svg"
						act={setEmail}
						type="email"
					/>
				</div>
				<Mes text={mes} />
				{!mb && 
				<div className={styles.fields}>
					<Btn title="Сбросить пароль" act={reset} submit />
					<Btn title="Отмена" act={log} />
				</div>}
				{mb && 
				<div className={clsFields}>
				<div className='login-main-btn-group'>
					<Btn title="Сбросить пароль" act={reset} submit />
					<Btn title="Отмена" act={log} />
				</div>
				</div>}
				
			</form>
			</div>
		</div>
	);

	function log() {
		type('login');
	}

	function reset() {
		if (!login) return setMes('Заполните логин!');
		if (!email) return setMes('Заполните электронную почту!');
		setMes('');
		const reg = /^[\w]*$/;
		if (!reg.test(login))
			setMes('Логин должен содержать только латинские символы и цифры!');
		api.patch('api/auth/reset', { login, email })
			.then((response) => {
				alert('Новый пароль отправлен на почту!');
				setTimeout(() => type('login'), 500);
			})
			.catch((e) => {
				console.log('Error ', e.response?.data);
				let msg = e.response?.data?.message;
				if (!msg) return;
				if (!Array.isArray(msg)) msg = [msg];
				setTimeout(() => setMes(msg), 1000);
			});
	}
};

export default observer(Win);
