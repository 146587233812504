//Таблица
export const Table = ({txt, style}) => {
  const rows = txt.split('\n')
  const rows_sep = rows.map(row => row.split(';'))
  return (
    <div className="cmp-format-about-table">
      {rows_sep.map((row,i) => <div  key = {i} className = "cmp-format-about-table_row">
                                <div className = "cmp-format-about-table_row-elem">{row[0]}</div> 
                                <div className = "cmp-format-about-table_row-elem">{row[1]}</div>
                              </div>
                  )}
    </div>

  )
}