import data from "config_task";

//Ссылки на документы флет
export default function Link() {
	const st = { backgroundImage: 'url(skin/img/doc.svg)' };
	const link = data.docs.map((el) => {
		const href = data.url + el.file + ms();
		return (
			<div className="skin-header-burger-drop-user">
				<a
					key={el.file}
					href={href}
					target="_blank"
					rel="noopener noreferrer"
					style={st}
					className="ico-mb"
				>
				</a>
				<div >
				<p>{el.text}</p>
				</div>
			</div>
		);
	});

	return link
}

function ms(){
	const t = new Date()
	return `?${t.getMinutes()}:${t.getSeconds()}`
}