import { status } from 'skin/event/status';
import ignore from './ignore';

const row = (obj, form, story, data, d, action) => {
	switch (obj.table) {
		case 'billCheck':
			return ignore(obj, form, story, data, d, action);
		case 'billTraffic':
			return ignore(obj, form, story, data, d, action);
		default:
	}
	return status.action;
};

export default row;
