import { status } from 'skin/event/status';
import setInput from './set_input'
import setImg from './set_img'
import setEnd from './set_end'

const data = (obj, form, story, data, d, action) => {
	console.log('[Продукт - форма] событие', obj);
	switch (obj.action) {
		case 'setInput':
			return setInput(obj, form, story, data, d, action);
		case 'setEnd': 
			return setEnd(obj, form, story, data, d, action);
		case 'setImg':
			return setImg(obj, form, story, data, d, action);
		default:
			return status.action;
	}
};

export default data;
