import { status } from 'skin/event/status';
import { space } from 'skin/event';

const click = (obj, form, story, data, d, action) => {
	if (obj.id === '660e990f971279001f5ad304') {
		const id = data.s.values.fld.clientId;
		const type = data.s.values.fld.clientType;
		const o = {
			type: 'form',
			title: 'Клиент',
			data: {
				code: 'client',
				info: [id],
			},
		};
		const oo = {
			type: 'form',
			title: 'Клиент',
			data: {
				code: 'mobAg',
				info: [id],
			},
		};
		space(type === 'company' ? oo : o, true);
		return status.ignore;
	}
};

export default click;
