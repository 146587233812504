import { status } from 'skin/event/status';
import table from './table';

const plgList = (obj, ms, story, data, d, action) => {
	console.log('[Зоны обслуживания - Мастер] событие', obj);
	switch (obj.type) {
		case 'table':
			return table(obj, d, action, ms, story, data);
		default:
			return status.action;
	}
};

export default plgList;
