import './company.css';

import iEdit from 'skin/entry/signup/images/edit.svg';
import iMarker from 'skin/entry/signup/images/marker.svg';

import Button from 'skin/entry/signup/cmp/button';
import Field from 'skin/entry/signup/cmp/field';

import Err from 'skin/entry/signup/cmp/err';

import useSuggest from '../suggest/use_suggest';
import address from './query/address';

// Блок информации о найденной организации;
const CompanyInfo = ({err, setError, company, setCompany}) => {
    const suggest = useSuggest(company.address, address, 1000);
    const data = {
        ['ИНН']: company.inn,
        ['Тип']: 'Индивидуальный предприниматель',
        ['Наименование']: company.offName,
        ['ОГРН']: company.ogrn,
        [company.managerTitle]: company.manager,
    };
    if (company.type === 'LEGAL') {
        data['Тип'] = 'Юридическое лицо';
        data['КПП'] = company.kpp;
        data['Адрес'] = company.address;
    }
    return (
        <div className='company-container'>
            <div className='company-header'>
                <span className='company-title'>Организация</span>
                <Button title={'Изменить ИНН'} onClick={_ => setCompany(null)} img={iEdit} type='alt' />
            </div>
            <div className='company-body'>
                {
                    Object
                        .entries(data)
                        .map(([k, v], i) => {
                            return (
                                <div key={i} className={'company-datatype-content'}>
                                    <span className={'company-datatype-name'}>{k}:</span>
                                    <span className={'company-datatype-value'}>{v}</span>
                                </div>
                            );
                        }
                    )
                }
                {
                    company.type === 'INDIVIDUAL'
                    &&
                    <Field
                        type={'suggest'}
                        setError={setError}
                        suggest={suggest}
                        title={'Введите адрес'}
                        value={company.address}
                        setValue={(v) => setCompany({...company, address: v, })}
                        onChange={
                            (e) => {
                                setCompany({...company, address: e.target.value});
                            }
                        }
                        placeholder={'Начните вводить адрес'}
                        img={iMarker}
                    />
                }
            </div>
            <Err msg={err} />
        </div>
    );
};

export default CompanyInfo;