import { status } from 'skin/event/status';
import { dictionary } from 'skin/event';

const dict = (obj, ms, story, data, d, action) => {
	console.log('[Перевод в остатки клик по справочнику - Мастер] событие', obj);
	d = JSON.parse(d)
	const filter = d.info[0]
	const o = {
		...obj,
		filter
	}
	dictionary(o)
	return status.ignore;
};

export default dict;
