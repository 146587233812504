import React, { Suspense } from 'react'
import Loading from 'skin/cmp/loading'
import menu from 'skin/store/menu'
import Head from './head'
import './style.css'

const Main = React.lazy(_ => import('../menu/main'))

const Mbmenu = ({cls_sn, child}) => {
    let cls = [
		'skin-pg-menu',
		'pg-menu',
        'mb-pg-menu'
	]
    let main = <Suspense fallback={Loading()}><Main  mb = {true}/></Suspense>
    if (!menu.s.tree) {
		main = null
		cls.push('off')
	}
    if(menu.s.tree) cls_sn += ' off'
    cls = cls.join(' ');

    return (
        <>
            <menu className={cls}>
                <Head />
                {main}
            </menu>
            <menu className={cls_sn}>{child}</menu>
        </>
    )
}

export default Mbmenu;