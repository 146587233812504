import { status } from 'skin/event/status';
import api from 'skin/http';

const category = (obj, ms, story, data, d, action) => {
	const idP = data.curData.values.fld.cPartId
	const idC = obj.value
	const config = {
		method: 'get',
		url: 'api/other/master/parList/' + idP+ '/'+idC,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	};
	api(config)
		.then(res => {
			data.delList('prod')
			res.data.rows.forEach(el => {
				data.addRow(ms, 'prod', el)
			});
		})
		.catch(console.log);
		return status.action
};

export default category;
