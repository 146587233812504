import api from 'skin/http';
import { status } from 'skin/event/status';

const setInput = (obj, ms, story, data, d, action) => {
    setTimeout(_ => {
        const value = data.curData.values.fld.value
        const config = {
            method: 'post',
            url: 'api/other/master/addr/find',
            data: { query: value },
            headers: {
                nowait: true
            }
        };
        api(config)
            .then(res => {
                const list = data.curData.values.table.address.list
                list.forEach(el => data.delRow(ms, 'address', el.key))
                res.data.forEach(el => {
                    data.addRow(ms, 'address', {
                        key: el.value,
                        list: ['', el.value, el.data.geo_lat, el.data.geo_lon]
                    })
                })
            })
            .catch(_ => { })
    }, 1000)
    return status.action
}

export default setInput