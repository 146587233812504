import {useContext} from 'react'
import { observer } from 'mobx-react-lite'
import {Cnt} from 'skin/context'
import Item from './item'
import './style.css'
import all from 'skin/data/table/control'
import m from "skin/store/mb";
import Burger from './burger'

const Control = props => {
	let cls = [
		'skin-tbl-com-control',
		'tbl-com-control'
	]
	const func = props.view.func
	const {action, view} = useContext(Cnt)
	const arr = props.view?.list ?? []
	const v = getTitle()
	cls = cls.join(' ')
	const mb = m.isMobile;
	if(mb) cls+=' mb-control'
	return (
		<div className={cls}>
			{!mb && all.map(el => <Item key={el.id} data={el} click={click} />)}
			{mb && 
			<>
				<div className="skin-header-burger-drop-user">
				<Item data = {v} click = {_ => open(func)} view = {true} />
				</div>
				<Burger all ={all} click = {click} table = {true} />
			</>}
		</div>
	)
	function click(act) {
		const o = {
			type: 'control',
			action: act
		}
		action(o)
	}
	function open(func) {
		if (func) return
		const o = {
			type: 'view',
			action: 'open'
		}
		action(o)
	}
	function getTitle() {
		if (view === '' || !arr[view]) return '<Не определено>'
		return arr[view]
	}
}

export default observer(Control)
