import { status } from 'skin/event/status';

//Просмотр RFM отчёта
function print(story, action) {
	const dataId = story.current.info[0];
	const o = { dataId };
	const oo = {
		type: 'form',
		code: 'rfmData',
		action: 'print',
		params: o,
	};
	action(oo);
	return status.ignore;
}

export default print;
