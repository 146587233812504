import { status } from 'skin/event/status';
import btn from './btn';
import table from './table';

const msCmpPice = (obj, ms, story, data, d, action) => {
	console.log('[Цена доставки у полигона - Мастер] событие', obj);
	switch (obj.type) {
		case 'table':
			return table(obj, d, action, ms, story, data);
		case 'btn':
			return btn(obj, d, action, ms, story, data);
		default:
			return status.action;
	}
};

export default msCmpPice;
