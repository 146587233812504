import { status } from 'skin/event/status';
import add from './add';
import del from './del';

const table = (obj, form, story, data, d, action) => {
	switch (obj.action) {
		case 'delete':
			return del(obj, form, story, data, d, action);
		case 'add':
			return add(obj, form, story, data, d, action);
		default:
	}
	return status.action;
};

export default table;
