import { status } from 'skin/event/status';
import { message } from 'skin/event';

const row = (obj, ms, story, d, action) => {
	const key = ms.s.teg.table.tegs.row
	const curTeg = story.curData.values.fld.curTeg
	const list = story.curData.values.table.tegs.list
	const idx = list.findIndex(el => el.key === obj.key )
	const teg = list[idx].list[1]
	const mTeg = curTeg.split(', ')
	if (!list[idx].list[0]) {
		if (mTeg.length < 5) {
			story.setCell('tegs', obj.key, 0, '*')
			currentTegs()
			return status.action
		}
		else {
			message(`Неомходимо выбрать до 5 тегов`, 'Теги');
			return status.ignore
		}
	}
	else 
		story.setCell('tegs', obj.key, 0, '')
		currentTegs()
	// if(mTeg.length < 5) {
	// 	if(list[idx].list[0]) story.setCell('tegs', obj.key, 0, '')
	// 	else story.setCell('tegs', obj.key, 0, '*')
	// 	currentTegs()
	// }
	// else {
	// 	message(`Ячейка уже выбрана`, 'Ячейки');
	// }
	

	if(obj.key === key) return status.ignore
	return status.action

	function currentTegs() {
		const x  = mTeg.indexOf(teg)
		let cur 
		if(x < 0) {
			cur = curTeg ? curTeg + ', '+ teg: teg
		}
		else {
			mTeg.splice(x, 1)
			cur = mTeg.join(', ')
		}
		story.setValue('curTeg', cur)
	}
};



export default row;
