import { status } from 'skin/event/status';
import send from './send';
import cancel from './cancel';

const click = (obj, form, story, data, d, action) => {
	switch (obj.id) {
		case '65771a2e104f5f8ab6ff9621': return send(obj, form, story, data, d, action)
		case '65af7553404bcb05394b8389': return cancel(obj, form, story, data, d, action)
		default:
			return status.action
    }
};

export default click;