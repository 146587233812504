import React, { Suspense } from 'react'
import { observer } from 'mobx-react-lite'
import menu from 'skin/store/menu'
import Head from './head'
// import Main from './main'
import Loading from 'skin/cmp/loading'
import './style.css'

const Main = React.lazy(_ => import('./main'))

const Menu = props => {
	let cls = [
		'skin-pg-menu',
		'pg-menu'
	]
	let main = <Suspense fallback={Loading()}><Main /></Suspense>
	if (!menu.s.tree) {
		main = null
		cls.push('off')
	}
	cls = cls.join(' ')
	return (
		<menu className={cls}>
			<Head />
			{main}
		</menu>
	)
}

export default observer(Menu)
