import pay from "./pay"

//Реакция на параметры  в запросе
export default function params(v) {
	const def = {
		pay
	}
	window.history.replaceState(null, '', `/`)
	if (def[v]) return def[v]()
	return null
}