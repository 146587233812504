import { status } from 'skin/event/status'
import { message } from 'skin/event';

const tarProp = (obj, form, story, data, d, action) => {
	const length = data.s.values.table.tarProp.list.length
	if (length >= 2) {
        message('Добавление не активно. Количество пунктов не больше двух', 'Особенности тарифа', null, '!');
		return status.ignore
	}
	return status.action
}

export default tarProp
