import { status } from 'skin/event/status';
import act from './action';
import control from './control';

const userEmp = (obj, table, story, d, action) => {
	console.log('[Сотрудники -таблица] событие', obj);
	switch (obj.type) {
		case 'control':
			return control(obj, table, story, d, action);
		case 'action':
			return act(obj, table, story, d, action);
		default:
			return status.action;
	}
};

export default userEmp;
