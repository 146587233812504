import { status } from 'skin/event/status'
import btnAdd from './btn_add'


const click = (obj, ms, story, data, d, action) => {
	switch (obj.code) {
		case 'add':
			return btnAdd(obj, ms, story, data, d, action)

		default:
			return status.action
	}
}

export default click
