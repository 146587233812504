import company from './company';
import login from './login';
import password from './password';
import password2 from './password2';
import phone from './phone';
import where from './refinfo';

const data = {
    company,
    login,
    password,
    password2,
    phone,
    where,
}

export default data