import { status } from 'skin/event/status';
import year from './year';

const setInput = (obj, ms, story, data, d, action) => {
	switch (obj.name) {
		case 'year':
			return year(obj, ms, story, data, d, action);
		
		default:
			return status.action;
	}
};

export default setInput;
