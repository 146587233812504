import { status } from 'skin/event/status';
import adv from './additive';

const del = (obj, form, story, data, d, action) => {
	switch (obj.table) {
		case 'additive':
			return adv(obj, form, story, data, d, action);
		default:
	}
	return status.action;
};

export default del;
