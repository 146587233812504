import { status } from 'skin/event/status';
import setInput from './set_input'

const data = (obj, ms, story, data, d, action) => {
	console.log('[Продукт - форма] событие', obj);
	switch (obj.action) {
		case 'setInput':
			return setInput(obj, ms, story, data, d, action);
		default:
			return status.action;
	}
};

export default data;
