import { observer } from 'mobx-react-lite';
import Empty from 'skin/cmp/empty';
import m from "skin/store/mb";
import InputArea from './area';
import Barcode from './barcode';
import InputBool from './bool';
import Btn from './button';
import CheckBox from './check_box';
import InputColor from './color';
import InputDT from './dt';
import Editor from './editor';
import InputBase from './input-base';
import InputFile from './input-file';
import InputImg from './input-img';
import Link from './link';
import Map from './map';
import InputNumber from './number';
import Progress from './progress';
import './style.css';
import Text from './text';

const Fields = ({ data, v, e, action }) => {
    let cls = ['skin-cmp-field', 'cmp-field'];
    cls = cls.join(' ');
    const mb = m.isMobile;
    if(mb) cls+= ' mb-field'
    if (!data) return <Empty type={'field'} />;
    const s = data ?? {}; //Структура и стиль
    switch (data?.type) {
        case 'date':
            v = v ? new Date(v).toISOString().split('T')[0] : '';
            return (
                <div className={cls}>
                    <InputBase s={s} v={v} e={e} setValue={setFld}></InputBase>
                </div>
            );
        case 'time':
            return (
                <div className={cls}>
                    <InputBase s={s} v={v} e={e} setValue={setFld}></InputBase>
                </div>
            );
        case 'url':
            return (
                    <Link s={s} v={v}></Link>
            );
        case 'number':
			const style = {minWidth: '80px', width: s.width } 
            return (
                <div className={cls} style={style}>
                    <InputNumber s={s} v={v} e={e} setValue={setFld} action={action}></InputNumber>
                </div>
            );
        case 'checkbox':
            return (
                <div className={cls}>
                    <CheckBox s={s} v={v} e={e} setValue={setFld} action={action}></CheckBox>
                </div>
            );
        case 'dt':
            return (
                <div className={cls}>
                    <InputDT s={s} v={v}></InputDT>
                </div>
            );
        case 'update':
            return (
                <div className={cls}>
                    <InputDT s={s} v={v}></InputDT>
                </div>
            );
        case 'bool':
            return (
                <div className={cls}>
                    <InputBool s={s} v={v} e={e} setValue={setFld}></InputBool>
                </div>
            );
        case 'area':
            return (
                <div className={cls} style={{ width: '100%' }}>
                    <InputArea s={s} v={v} e={e} setValue={setFld}></InputArea>
                </div>
            );
        case 'color':
            return (
                <div className={cls}>
                    <InputColor s={s} v={v} e={e} setValue={setFld}></InputColor>
                </div>
            );
        case 'img':
            return (
                <div className={cls}>
                    <InputImg s={s} v={v} e={e} setValue={setImg}></InputImg>
                </div>
            );
        case 'file':
            return (
                <div className={cls}>
                    <InputFile s={s} v={v} e={e} setValue={setFile}></InputFile>
                </div>
            );
        case 'txt':
            const w = s.width ?? 'auto';
            const align = s.align ?? 'left';
            return (
                <div className={cls} style={{ width: w, textAlign: align }}>
                    <Text s={s} v={v}></Text>
                </div>
            );
        case 'map':
            return (
                <div className='cmp-field-map'>
                    <Map s={s} v={v}></Map>
                </div>
            );
        case 'btn':
            return (
                <div className={cls}>
                    <Btn s={s} action={action}></Btn>
                </div>
            );
        case 'barcode':
            return (
                <div className={cls}>
                    <Barcode s={s} v={v}></Barcode>
                </div>
            );
        case 'progress':
            return (
                <div className={cls}>
                    <Progress s={s} v={v} action={action}></Progress>
                </div>
            );
        case 'editor':
            return (
                <div className={cls} style={{ width: '100%' }}>
                    <Editor s={s} v={v} e={e} setValue={setFld} />
                </div>
            );
        default:
            return (
                <div className={cls}>
                    <InputBase s={s} v={v} e={e} setValue={setFld} action={action}></InputBase>
                </div>
            );
    }

    function setFld(n, v) {
        const o = {
            type: 'data',
            action: 'setInput',
            name: n,
            value: v,
        };
        action(o);
    }

    function setImg(n, v, del) {
        const o = {
            type: 'data',
            action: 'setImg',
            name: n,
            value: v,
        };
        action(o);
    }

    function setFile(n, v, del) {
        const o = {
            type: 'data',
            action: 'setFile',
            name: n,
            value: v,
        };
        action(o);
    }
};

export default observer(Fields);
