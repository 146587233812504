import { status } from 'skin/event/status'

const next = (obj, ms, story, data, d, action) => {
    console.log('[Отчет - Мастер] событие', obj);
    const o = {
		name: 'Список весовых товаров',
		pug: true
	};
    const act = {
        type: 'data',
        action: 'wAdd',
        code: 'makeProd',
        data: o
    }
    action(act)
    return status.action
}

export default next