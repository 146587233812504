import { status } from 'skin/event/status';

const wOut = (obj, ms, story, data, d, action) => {
	console.log('[Загрузка рассылок - Мастер] событие', obj);
	const act = {
		type: 'master',
		action: 'exit',
	};
	action(act);
	return status.ignore;
};

export default wOut;
