import { status } from 'skin/event/status';
import stat from './stat';

const row = (obj, form, story, data, d, action) => {
	switch (obj.table) {
		case 'pmnStat':
			return stat(obj, form, story, data, d, action);
		default:
	}
	return status.action;
};

export default row;
