import { observer } from 'mobx-react-lite';
import user from 'skin/store/user';

const product = (props) => {
	const cmpPr = user.product;
	const tarPr = user.tar.max?.product;
	const st = { margin: '0 10px', textWrap: 'nowrap' };
	if (tarPr)
		return (
			<span style={st}>
				Продуктов: {cmpPr}/{tarPr}
			</span>
		);
};

export default observer(product);
