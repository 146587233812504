import { status } from 'skin/event/status'

const row = (obj, ms, story, data, d, action) => {
	// доступные на списание остатки
	const key = story.s.msPrdWriteoff.table.prdWriteoff.row
	const list = data.curData.values.table.prdWriteoff.list
	const r = list.find((el) => el.key === obj.key)
	// выбрать товар на списание
	data.setValue('one', r.list[0])
	data.setValue('selName', r.list[0])
	if (obj.key === key) {
		return status.ignore
	}
	return status.action
}

export default row
