import { status } from 'skin/event/status';
import prd from './product';

const add = (obj, form, story, data, d, action) => {
	switch (obj.table) {
		case 'mProd':
			return prd(obj, form, story, data, d, action);
		default:
			return status.action;
	}
};

export default add;
