import { status } from 'skin/event/status';
import user from 'skin/store/user';
import api from 'skin/http';
import { message } from 'skin/event';

const prd = (obj, table, story, d, action) => {
	api.get('api/data/count/product')
		.then((res) => {
			const cmpPr = res.data.result;
			const tarPr = user.tar.max?.product;
			user.chPrd(cmpPr);
			if (!tarPr) return status.action;
			if (cmpPr < tarPr) return status.action;
			message(
				'Достигнут максимум продуктов по тарифу',
				'Максимум продуктов',
				null,
				'!'
			);
			return status.ignore;
		})
		.catch(console.log);
};
export default prd;
