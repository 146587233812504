import { status } from 'skin/event/status';
import table from './table';

const listPack = (obj, form, story, data, d, action) => {
	console.log('[Задание на фасовку - форма] событие', obj);
	switch (obj.type) {
		case 'table':
			return table(obj, form, story, data, d, action);
		
		default:
			return status.action;
	}
};

export default listPack;
