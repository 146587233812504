import './style.css'
import user from 'skin/store/user';
import m from "skin/store/mb";
import { observer } from "mobx-react-lite";


const Item = props => {
	let cls = [
		'skin-tbl-com-cnt-item',
		'tbl-com-cnt-item'
	]

	cls = cls.join(' ')
	const mb = m.isMobile;
	let id = props.data.id
	const title = props.data.title
	if(props.view) id = "tb_view";
	const st = {backgroundImage: `url(skin/img/com_${id}.svg)`}
	let ttl = <span className='tbl-head-com-cnt-itm-tit'>{title}</span>
	if(user.short) ttl = null
	return (
		<div className={cls} onClick={() => props.click(id)}>
			<span  className="ico" style={mb ?  {...st, border: "none", width:"20px", height:"20px"} : st} title={title}></span>
			{ttl}
		</div>
	)
}

export default observer(Item)
