import React, { Suspense } from 'react'
import Head from './head'
import Loading from 'skin/cmp/loading'
import './style.css';
import m from "skin/store/mb";
import { observer } from "mobx-react-lite";

const Main = React.lazy(_ => import('./main'))

const Menu = props => {
	let cls = [
		'skin-menu',
		'menu'
	]
	const mb = m.isMobile
	cls = cls.join(' ')
	if(mb) cls+= ' mb-menu'
	return (
		<div className={cls} data-info='Системное меню'>
			<Head mb = {mb}/>
			<Suspense fallback={Loading()}>
				<Main mb = {mb}/>
			</Suspense>
		</div>
	)
}

export default  observer(Menu)
