import { status } from 'skin/event/status';
import master from './master';
import wOut from './wout';

const rewUp = (obj, ms, story, data, d, action) => {
    console.log('[Загрузка отзывов - Мастер] событие', obj);
    switch (obj.type) {
        case 'table': return status.ignore
        case 'master': return story.curPage === 1 ?
            master(obj, ms, story, data, d, action)
            :
            status.action;
        default:
    }
    switch (obj.action) {
        case 'wOut': return wOut(obj, ms, story, data, d, action)
        default: return status.action
    }
}

export default rewUp
