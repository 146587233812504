import cnfSkin from 'config_skin';
import { status } from 'skin/event/status';
import api from 'skin/http';
import table from './table';

const msEx = (obj, ms, story, data, d, action) => {    
    if (obj.action === 'loadData') return new Promise((resolve, reject) => {
        const config = {
            method: 'get',
            url: cnfSkin.url + 'api/exceptiion',
            // url: 'http://127.0.0.1:3040/' + 'api/exceptiion',
        }
        api(config)
            .then(res => {
                if (data.curData.values.table.except.list.length) return resolve(status.action)
                res.data.forEach(el => {
                    const child = el.child?.map(el => el._id).join('|')
                    data.addRow(ms, 'except', { key: el._id, list: ['', el._id, el.parent, el.title, child] })
                })
                const check = data.curData.values.fld.data
                data.curData.values.table.except.list.forEach(el => {
                    if (check.includes(el.key)) data.setCell('except', el.key, 0, 'v')
                })
                return resolve(status.action)
            })
            .catch(err => {
                console.log(err)
                return resolve(status.action)
            })
    })
    switch (obj.type) {
        case 'table': return table(obj, ms, story, data, d, action)
        default: return status.action
    }
}

export default msEx