import './header.css';
import classList from 'skin/entry/signup/cmp/class_list.js';
import iReg from 'skin/entry/signup/images/registration.svg'

// Заголовок формы
const Header = ({ wide }) => {
    return (
        <header className={classList('form-header', !wide && 'form-header-mobile')}>
            <div><img alt={'title'} src={iReg} /></div>
            <h1 className='form-title'>Регистрация</h1>
        </header>
    );
};

export default Header;