import './style.css'
import {stlCol} from 'skin/table/content/main/fn'

const Col = props => {
	let cls = [
		'skin-tbl-main-body-row-col',
		'tbl-main-body-row-col'
	]
	const o = props.def
	if (o?.type === 'id') cls.push('id')
	cls = cls.join(' ')
	const st = stlCol(o, true)
	const val = value(props.data, o)
	return (
		<td className={cls} style={st} title={val}>
			<div>
				{val}
			</div>
		</td>
	)
}

// Обработка присланного значения
export function value(val, def) {
	val = val ?? ''
	if (val === '' || !def?.type) return <br />
	switch (def.type) {
		case 'date':
			return date(val)
		case 'dt':
			return dt(val)
		case 'bool':
			return bool(val)
		default:
			return val
	}
}
// Преобразование даты
function date(val) {
	val = typeof val === 'string' ? new Date(val) : val
	val = val.toLocaleString().split(',')[0]
	return val
}
// Преобразование даты и времени
function dt(val) {
	val = typeof val === 'string' ? new Date(val) : val
	val = val.toLocaleString().split(':')[0]+ ':'+ val.toLocaleString().split(':')[1]
	return val
}
// Декодирование булевского типа
function bool(val) {
	return val === true ? 'Да' : 'Нет'
}

export default Col
