import { message } from "skin/event"


function btn(obj, d, action, ms, story, data) {
	const field = data.curData.values.fld
	const table = data.curData.values.table.msTbPoligons.list
	const select = table.filter(e =>e.list[0] === 'v' )
	const {fix, price, sum} = field
	if (!select.length) {
		message('Укажите хотя бы одно значение', 'Запись цен доставки')
		return
	}
	data.setValue('fix', '')
	data.setValue('price', '')
	data.setValue('sum', '')
	select.forEach(e => {
		const l = ['', e.list[1], e.list[2], fix, sum, price]
		data.setRow('msTbPoligons', e.key, l)
	});
}

export default btn