import { status } from 'skin/event/status';
import { message } from 'skin/event';

const click = (obj, ms, story, data, d, action) => {
	console.log('[Ячейки - Мастер] событие', obj);
	const fld = data.curData.values.fld
	if (!fld.name) {
		message('Выберите товар', 'Ячейки')
		return status.ignore
	}
	const row = {
		key: fld.name,
		name: fld.product,
		list: [fld.txtName]
	}
	const table = data.curData.values.table.product


	const i = table.list.findIndex(e => e.key === fld.name)
	if (i === -1) data.addRow(ms, 'product', row)
	else {
		const a = [...table.list]
		a[i] = row
		table.list = a
	}

	data.setValue('name', '')
	data.setValue('txtName', '')
	return status.action
}

export default click