import { status } from 'skin/event/status';
// import forma from './form'
import table from './table';

const proImg = (obj, form, story, data, d, action) => {
	console.log('[Продукт - форма] событие', obj);
	switch (obj.type) {
		// case 'form':
		// return forma(obj, form, story, data, d, action)
		case 'table':
			return table(obj, form, story, data, d, action);
		case 'control':
			if (obj.table === 'tProProp') {
				obj.action = 'add';
				obj.message = 'Вы хотите изменить комбинацию?';
			}
			return status.action;
		default:
			return status.action;
	}
};

export default proImg;
