import React, { Suspense } from 'react'
import Command from './command'
//import Main from  './main'
import Footer from  './footer'
import Loading from  'skin/cmp/loading'
import './style.css'

const Main = React.lazy(_ => import('./main'))

const Content = props => {
	let cls = [
		'skin-tbl-content',
		'tbl-content'
	]
	cls = cls.join(' ')
	return (
		<div className={cls}>
			<Command view = {props.view} />
			<Suspense fallback={Loading()}>
				<Main body={props.body} view={props.view} col={props.col} />
			</Suspense>
			<Footer />
		</div>
	)
}

export default Content
