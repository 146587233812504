import { status } from 'skin/event/status';

const row = (obj, ms, story, data, d, action) => {
	const key = story.s.pacMaster.table.pacProd.row
	if(obj.key === key) {
		return status.ignore
	}
	return status.action
};

export default row;
