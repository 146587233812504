import { status } from 'skin/event/status';
import control from './control';

const pickup = (obj, table, story, d, action) => {
	console.log('[Самовывоз -таблица] событие', obj);
	switch (obj.type) {
		case 'control':
			return control(obj, table, story, d, action);
		default:
			return status.action;
	}
};

export default pickup;
