import { password as cfg } from "./config";

const password = (v) => {
    if (!v) {
        return 'Введите пароль';
    };
    const len = v.length;
    if (len < cfg.min || len > cfg.max) {
        return `Пароль должен быть длиной от ${cfg.min} до ${cfg.max} символов`;
    };
    if (v.match(cfg.blacklist)) {
        return 'Пароль содержит недопустимые символы. В пароле можно использовать символ нижнего подчёркивания (_), латинские буквы маленького и большого регистров.';
    };
}

export default password;