import { status } from 'skin/event/status';

const row = (obj, table, story, d, action) => {
	if (!obj.current) return status.action;
	console.log(obj.current);
	const act = {
		type: 'data',
		action: 'wOut',
		work: obj.id,
		pug: true,
	};
	action(act);
	return status.ignore;
};

export default row;
