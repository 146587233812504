import { status } from 'skin/event/status';
const next = (obj, ms, story, data, d, action) => {
	const f = data.getAll(ms);
	const value = {
		year: f.all.year,
		month: f.all.month,
		fax: f.all.fax
	};
	const oo = {
		type: 'master',
		code: 'act',
		action: 'print',
		prn: true,
		params: { ...value },
	};
	action(oo)
	return status.action;
};

export default next;
