import { useEffect } from "react";

// Хук для обновления значения таймера;
const useTimer = (timer, setTimer) => {
    useEffect(() => {
        const interval = setInterval(() => {
            if (timer <= 0) return;
            setTimer(timer - 1);
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [timer])
};

export default useTimer;