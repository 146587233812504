import { status } from 'skin/event/status'

const step = (obj, ms, story, data, d, action) => {
	const f = data.getAll(ms);
	d = JSON.parse(d)
	const o = {
		id: d.info[0],
		beg: f.all.beg,
		end: f.all.end,
		zoom: f.all.zoom,
	}
	const oo = {
		type: 'master',
		code: 'prnCell',
		action: 'print',
		prn: true,
		params: o
	}
	action(oo)
	return status.action;
}

export default step;
