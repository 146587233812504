import { status } from "skin/event/status";
import dt from "./data";

const topup = (obj, ms, story, data, d, action) => {
	console.log('obj', obj)
	switch (obj.type) {
		case 'data':
			return dt(obj, ms, story, data, d, action);
		default:
			return status.action;
	}
};

export default topup;