import { useRef, useState, useEffect } from "react";
import './style.css'

const List = props => {
    const [show, setShow] = useState(false);
    const ref = useRef();

    const click = event => {event.stopPropagation(); setShow(!show);}
    const close = _ => setShow(false);

    useEffect(() => {
		const click = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				close();
			}
		};
		setTimeout(() => { document.addEventListener('click', click); });
	  
		return () =>  document.removeEventListener('click', click);
	
	}, []);

    const st2 = {backgroundImage: 'url(skin/img/com_tb_view.svg)', height:'20px', width: '20px', border:'none'}
    return ( 
        <div  onClick={click} style={{display:'flex'}}>
            <span className="ico mb-crumb-list" style = {st2}>
            {show && 
            <div className="mb-crumb-drop" ref={ref}>
                {props.crb.map((el,i) => 
                    <span onClick={() => props.crumb(i)}>{el.title}</span>
                )}
            </div>
            }
            </span>
            
        </div>
    )
}

export default List;