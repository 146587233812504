import { createRoot } from 'react-dom/client';
import './style.css';
import App from './task';
import './task/style.css';
import useParams from './use';

function AppWithCallbackAfterRender() {
	useParams()
	return <App tab="home" />;
}
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<AppWithCallbackAfterRender />);
