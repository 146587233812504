import { status } from 'skin/event/status';
import { space } from 'skin/event';

const cell = (obj, form, story, data, d, action) => {
	const key = story.current.table.cmpRewList.row;
	if (obj.key === key) {
		const o = {
			type: 'master',
			code: 'rewList',
			title: 'Бонусная программа',
			data: {
				code: 'rewList',
				info: obj.info,
			},
		};
		space(o, true);
		return status.ignore;
	}
};

export default cell;
