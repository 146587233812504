import { status } from 'skin/event/status'

const row = (obj, ms, story, data, d, action) => {
	// строка таблицы
	const key = story.s.msDStatus.table.ordStatus.row
	const list = data.curData.values.table.ordStatus.list
	const r = list.find((el) => el.key === obj.key)
	const st = `${new Date(r.list[0]).toLocaleString()}-     -${r.list[1]}-    -${r.list[2]}`
	// выбранная строка (статус) из таблицы
	data.setValue('name', st)
	// id статуса
	data.setValue('statusId', r.info[0])
	if (obj.key === key) {
		return status.ignore
	}
	return status.action
}

export default row
