import { status } from "skin/event/status";
import wOut from "./wOut";

const msData = (obj, ms, story, data, d, action) => {
	console.log("[Загрузка отчёта - Мастер] событие", obj);
	switch (obj.action) {
		case "wOut":
			return wOut(obj, ms, story, data, d, action);
		default:
			return status.action;
	}
};

export default msData;
