import { status } from 'skin/event/status';

const add = (obj, form, story, data, d, action) => {
	if(obj.id === '62a398073f2debc15e781471') {
		const rackId = story.current.info[0]
		const oo = {
			type: 'form',
			code: 'rack',
			action: 'print',
			prn: true,
			params: {rackId}
		}
		action(oo)
		return status.ignore
	}
	return status.action
};

export default add;
