import { status } from 'skin/event/status';
import actionn from './action';
import forma from './form';
import dt from './data';
import control from './control';

const varProd = (obj, form, story, data, d, action) => {
	console.log('[Вариант продукта - форма] событие', obj);
	switch (obj.type) {
		case 'action':
			return actionn(obj, form, story, data, d, action);
		case 'form':
			return forma(obj, form, story, data, d, action);
		case 'data':
			return dt(obj, form, story, data, d, action);
		case 'control':
			return control(obj, form, story, data, d, action);
		default:
	}
	return status.action;
};

export default varProd;
