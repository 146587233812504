import { status } from 'skin/event/status'

const step = (obj, ms, story, data, d, action) => {
	const f = data.getAll(ms);
	const o = {
		qr: f.all.qr.code[0],
		zoom: f.all.zoom,
		count: f.all.value,
	}
	const oo = {
		type: 'master',
		code: 'prnBox',
		action: 'print',
		prn: true,
		params: o
	}
	action(oo)
	return status.action;
}

export default step;
