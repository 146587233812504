import Burger from './burger';
import Left from './left';
import Right from './right';
import './style.css';
import m from "skin/store/mb";
import { observer } from "mobx-react-lite";

const Header = props => {
	let cls = ['skin-header', 'header', 'wrapper'];
	cls = cls.join(' ');
	const mb = m.isMobile;
	if(mb) cls = cls + (' mb');
	return (
		<header className={cls}>
			<Left mb = {mb} mode={props.mode}/>
			{!mb && <Right />}
			{mb && <Burger />}
		</header>
	);
};

export default observer(Header);
