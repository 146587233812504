import {useContext} from 'react'
import {Cnt} from 'skin/context'
import Command from './command'
import "./style.css";
import { observer } from "mobx-react-lite";
import m from "skin/store/mb";

const Head = props => {
	const mb = m.isMobile;
	const {action} = useContext(Cnt)
	let cls = ["skin-tbl-head", "tbl-head"];
	cls = cls.join(" ");
	const st = { backgroundImage: "url(skin/img/close.svg)", width:'30px', height:'30px' };
	return (
		<div className={cls}>
			<Command
				title={props.title}
				view={props.view}
			/>
			{!mb && <span className="ico-r" style={st} onClick={exit}></span>}
		</div>
	)
	//
	function exit() {
		const o = {
			type: 'table',
			action: 'exit'
		}
		action(o)
	}
};

export default observer(Head);
