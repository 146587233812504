import { status } from 'skin/event/status';
import print from './fn/print';

const click = (obj, form, story, data, d, action) => {
	switch (obj.id) {
		// просмотр отчёта
		case '656d6e95a9e6ee36c19bb0db':
			return print(story, action);
		default:
			return status.action;
	}
};

export default click;
