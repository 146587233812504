import { status } from 'skin/event/status';
import user from 'skin/store/user';
import { message } from 'skin/event';

const add = (obj, table, story, d, action) => {
	const cmpE = table.s.body.count;
	const tarE = user.tar.max?.employee;
	if (!tarE) return status.action;
	if (cmpE < tarE) return status.action;
	message(
		'Достигнут максимум сотрудников по тарифу',
		'Максимум сотрудников',
		null,
		'!'
	);
	return status.ignore;
};
export default add;
