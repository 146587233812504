import { status } from 'skin/event/status';
import { kit } from 'skin/data/message';
import { message } from 'skin/event';

const master = (obj, ms, story, data, d, action) => {
	const make = data.s.genPcg.values.fld.make
	const pcg = 'через задание'
	const o = {
		type: 'master',
		action: 'check'
	}
	const aBtn = kit('yesno');
	aBtn[0].action = _ => action(o)
	make === pcg ?
	action(o)
	:
	message(
		`Тип весового товара "${make}" будет изменен на "${pcg}". Продолжить?`,
		'Ячейки',
		aBtn,
		'?'
	)
	return status.ignore
}

export default master