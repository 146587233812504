import { status } from 'skin/event/status'
import tbl from './table'

const modules = (obj, table, story, d, action) => {
    console.log('[Модули] событие', obj)
    switch (obj.type) {
        case 'table': return tbl(obj, table, story, d, action)
        default: return status.action
    }
}

export default modules