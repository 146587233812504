import { status } from 'skin/event/status';
import dt from './data';
import btn from './btn';


const arrToRem = (obj, ms, story, data, d, action) => {
	console.log('[Ячейки - Мастер] событие', obj);
	switch (obj.type) {
		case 'data':
			return dt(obj, ms, story, data, d, action)
		case 'btn':
			return btn(obj, ms, story, data, d, action)
		default:
			return status.action;
	}
};

export default arrToRem;
