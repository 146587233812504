import { status } from 'skin/event/status';

const select = (obj, ms, story, data, d, action) => {
    const list = data.curData.values.table.column.list
    list.forEach(el => {
        if (!el.list[0]) data.setCell('column', el.key, 0, '+')
    })
    return status.action
}

export default select