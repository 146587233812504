import { status } from 'skin/event/status';
import save from './save';

const form = (obj, form, story, data, d, action) => {
	switch (obj.action) {
		case 'save':
			return save(obj, form, story, data, d, action);
	
		default:
	}
	return status.action;
};

export default form;
