import { login as cfg } from "./config";

const login = (v) => {
    if (!v) {
        return 'Укажите логин';
    };
    if (!v[0]?.match(cfg.whitelistStart)) {
        return 'Логин должен начинаться с латинской буквы любого регистра';
    }
    const len = v.length;
    if (len < cfg.min || len > cfg.max) {
        return `Логин должен быть длиной от ${cfg.min} до ${cfg.max} символов`;
    }
    if (v.match(cfg.blacklist)) {
        return 'Логин содержит недопустимые символы. В логине можно использовать символ нижнего подчёркивания (_), латинские буквы маленького и большого регистров';
    };
}

export default login;