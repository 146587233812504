import { status } from 'skin/event/status';
import { message } from 'skin/event';
import { dictionary } from 'skin/event';

const dict = (obj, ms, story, data, d, action) => {
	console.log('[Перевод в остатки клик по справочнику - Мастер] событие', obj);
	const select = data.curData.values.fld.name
	if (!select) {
		message(`Выберите сырье для перевода`, 'Перевод в остатки');
		return status.ignore
	}
	const filter = select.split(' ')[0]
	const o = {
		...obj,
		filter
	}
	dictionary(o)
	return status.ignore;
};

export default dict;
