import { status } from 'skin/event/status';
import act from './act';
import table from './table';

const employee = (obj, form, story, data, d, action) => {
	console.log('[Личный кабинет - форма] событие', obj);
	console.log('data:', data);
	switch (obj.type) {
		case 'table':
			return table(obj, form, story, data, d, action);
		case 'action':
			return act(obj, form, story, data, d, action);
		default:
	}
	return status.action;
};

export default employee;
