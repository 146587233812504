import { message } from 'skin/event';
import { status } from 'skin/event/status';
import user from 'skin/store/user';

const control = (obj, form, story, data, d, action) => {
	console.log('[Вариант продукта - форма] событие', obj);
	const partnerId = data.s.values.fld.partnerId
	const code = user.code
	if (code=== 'grilan' && partnerId) {
		if (obj.action === 'save') return status.action
		switch (obj.table) {
			case 'varProdDiscount':	return status.action
			default: {
					message(`Редактирование партнерского товара не активно`, 'Продукты');
					return status.ignore
			}
		}
	}
};

export default control;
