import Win from './win';
import './style.css';
import Notify from 'skin/cmp/notify';
import Header from 'skin/cmp/header';

import styles from './styles.module.css'

const Reset = ({ data }) => {
	return (
		<div
			className='login'
			style={{ backgroundImage: 'url(/task/img/main.png)' }}>
			<Header />
			<Win />
			<div className={styles.dict}>
				<Notify data={data.notify} />
			</div>
		</div>
	)
}


// const Reset = ({ data }) => {
// 	let cls = ['skin-reset', 'reset-box'];
// 	cls = cls.join(' ');
// 	const stl = {
// 		backgroundImage: 'url(/task/img/pattern.png)',
// 		backgroundSize: 'cover',
// 	};
// 	return (
// 		<div className={cls} style={stl}>
// 			<div className="shadow"></div>
// 			<div className="container">
// 				<Win />
// 				<div className="phone">+7 (904) 408 94 94</div>
// 			</div>
// 			<Notify data={data.notify} />
// 		</div>
// 	);
// };

export default Reset;
