import Field from "skin/entry/signup/cmp/field";

import iCard from 'skin/entry/signup/images/card.svg';
import qCompany from './query/company';
// Поле ввода ИНН организации;
const CompanyField = ({ value: inn, setValue, company, setCompany, setError}) => {
    return (
        <Field
            type={'base'}
			mask={'999999999999'}
            title={'ИНН'}
            placeholder={'Введите ИНН организации'}
            value={inn}
            setValue={setValue}
            setError={setError}
            button={{
                title: 'Проверить',
                type: 'reverse',
                img: iCard,
                onClick: _ => {
					setError(null)
                    if (!inn) {
                        setError('Укажите ИНН');
                        return;
                    };
					inn = inn.replaceAll('_', '')
                    // fetchCompany(inn, setCompany, setError);
					qCompany(inn, setCompany, setError)
                },
            }}
        />
    );
};

export default CompanyField;