import { status } from 'skin/event/status';
import wOut from './wOut';

const msNoteUp = (obj, ms, story, data, d, action) => {
	console.log('[Загрузка рассылок - Мастер] событие', obj);
	switch (obj.action) {
		case 'wOut':
			return wOut(obj, ms, story, data, d, action);
		default:
			return status.action;
	}
};

export default msNoteUp;
