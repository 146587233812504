import { useEffect, useState } from "react";

const usePhone = (value, setValue) => {
    const [phone, setPhone] = useState('');
    // Флаг подтверждения номера;
    const [submit, setSubmit] = useState(false);
    // Отсчёт секунд до повторной отправки смс;
    const [timer, setTimer] = useState(-1);
    // Ошибка при подтверждении кода;
    const [codeErr, setCodeError] = useState();

    // Сброс флага подтверждения при изменении номера телефона в поле ввода;
    useEffect(() => {
        setSubmit(false);
        setTimer(-1);
        setCodeError(null);
        setValue('');
    }, [phone]);

    useEffect(() => {
        if (submit) {
            setCodeError(null);
        };
        if (submit && !value) {
			const p = phone.replace(/[ \-()\+]+/g, '')
            setValue(p);
        }
    }, [submit])

    return {phone, setPhone, submit, setSubmit, timer, setTimer, codeErr, setCodeError};
};

export default usePhone;