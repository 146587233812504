import { status } from 'skin/event/status';
import btn from './btn';
import btnNew from './btn_new';
import btnCopy from './btn_copy';

const click = (obj, ms, story, data, d, action) => {
	switch (obj.code) {
		case 'btn':
			return btn(obj, ms, story, data, d, action)
		case 'btnNew':
			return btnNew(obj, ms, story, data, d, action)
		case 'btnCopy':
			return btnCopy(obj, ms, story, data, d, action)
		default:
			return status.action
	}
};

export default click;
