import { company as cfg } from "./config";

const company = (v) => {
    if (!v) {
        return 'Укажите организацию';
    };
    if (v.type === 'LEGAL') return;
    if (!v.address) {
        return 'Укажите адрес организации.';
    };
    const hasHouse = cfg.housePattern.test(v.address);
    if (!hasHouse) {
        return 'Введите адрес полностью (должен быть указан до дома)';
    }
}

export default company;