import { status } from 'skin/event/status';
// import forma from './form';

const list = (obj, form, story, data, d, action) => {
	console.log('[Рекламный блок - форма] событие', obj);
	switch (obj.type) {
		// case 'form':
		// return forma(obj, form, story, data, d, action);
		default:
			return status.action;
	}
};

export default list;
