import { status } from 'skin/event/status';
import add from './add';
import del from './del';
import { message } from 'skin/event';

const control = (obj, form, story, data, d, action) => {
	const count = obj.table === 'screen' ? data.s.values.table.screen.count : data.s.values.table.screenIOS.count;
    if(count === 4 && obj.action === 'add') {
        message('Максимальное количество скриншотов - 4');
        return status.ignore;
    }
	switch (obj.action) {
		case 'add':
			return add(obj, form, story, data, d, action);
		case 'delete':
			return del(obj, form, story, data, d, action);
		default:
	}
	return status.action;
};

export default control;
