import { status } from 'skin/event/status';
import { space } from 'skin/event';

const click = (obj, form, story, data, d, action) => {
	if (obj.id === '6513de9ab41463a90f6411ee') {
		const id = data.s.values.fld.productId;
		const o = {
			type: 'form',
			title: 'Продукт',
			data: {
				code: 'mProd',
				info: [id],
			},
		};
		space(o, true);
		return status.ignore;
	}
};

export default click;
