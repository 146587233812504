import { status } from 'skin/event/status';
import ctg from './category'
import prd from './product'
import ctgEx from './category_ex'

const add = (obj, form, story, data, d, action) => {
	switch (obj.table) {
		case 'mGoodsCategory':
			return ctg(obj, form, story, data, d, action);
		case 'mGoodsCategoryEx':
			return ctgEx(obj, form, story, data, d, action);
		case 'mProd':
			return prd(obj, form, story, data, d, action);
		default:
			return status.action;
	}
};

export default add;
