import { status } from "skin/event/status";
import table from './table'

const mobAg = (obj, form, story, data, d, action) => {
	console.log('[Клиент Флет - форма] событие', obj);
    switch (obj.type) {
        case 'table': return table(obj, form, story, data, d, action)
        default: return status.action
    }
}

export default mobAg