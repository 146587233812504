import { status } from 'skin/event/status';

const next = (obj, ms, story, data, d, action) => {
	console.log('[Отчет - Мастер] событие', obj);
	const f = data.getAll(ms);
	const o = {
		name: 'Активность пользователей',
		in: { ...f.date },
	};
	const act = {
		type: 'data',
		action: 'wAdd',
		code: 'usAct',
		data: o,
	};
	action(act);
	return status.action;
};

export default next;
