import { status } from 'skin/event/status';

const row = (obj, ms, story, data, d, action) => {
	const key = story.s.msPack.table.propItem.row
	const list = data.curData.values.table.propItem.list
	const r = list.find(el => el.key === obj.key)
	data.setValue('txtMod', r.list[0])
	data.setValue('prdVal', r.list[1])
	if(obj.key === key) {
		return status.ignore
	}
	return status.action
};

export default row;
