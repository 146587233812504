import { status } from 'skin/event/status';

const row = (obj, ms, story, data, d, action) => {
	const list = data.curData.values.table.address.list
	const i = list.findIndex(el => el.key === obj.key)
	list.forEach((el, index) => {
		if (index === i) {
			const val = list[i].list[0] === '+' ? '' : '+'
			data.setCell('address', el.key, 0, val)
		} else data.setCell('address', el.key, 0, '')
	})
	data.setValue('address', list[i].key)
	data.setValue('lat', list[i].list[2])
	data.setValue('lon', list[i].list[3])
	return status.ignore
};

export default row;
