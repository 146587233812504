import { message } from 'skin/event';
import { status } from 'skin/event/status';
import api from 'skin/http';

function fact(form, story, action) {
	const id = story.current.info[0]
	const config = {
		methdo: 'get',
		url: 'api/other/form/prod/fact/' + id
	}
	api(config)
		.then(res => {
			const o = {
				type: 'form',
				action: 'reload'
			}
			form.func(false)
			action(o)
			message(`Код товара добавлен успешно`, 'Товары')
		})
		.catch(_ => {
			const o = {
				type: 'action',
				action: 'close'
			}
			action(o)
			message(`Код товара не добавлен.\n Обратитесь к администратору`, 'Товары')
		})
		return status.ignore
}

export default fact