import { status } from 'skin/event/status';
import { space } from 'skin/event';

const cell = (obj, form, story, data, d, action) => {
	const key = story.current.table.pickCell.row;
	if (obj.key === key) {
		const info = [data.s.values.fld._id];
		const o = {
			type: 'master',
			code: 'pickCell',
			title: 'Ячейки',
			data: {
				code: 'pickCell',
				info,
			},
		};
		space(o, true);
		return status.ignore;
	}
};

export default cell;
