import { useState } from "react";

import QRCode from "react-qr-code";
import Popup from 'skin/popup';

import './style.css'

export default function QR({ src }) {
    const [active, setActive] = useState(false)
    return (
        <>
            <div className='login-qr-main'onClick={_ => setActive(true)}>
                <p className='login-title-qr'>QR-code</p>
                <Popup active={active} setActive={setActive}>
                    <div className='login-qr-container'>
                        <p className='login-qr-title'>Скачать приложение ФЛЕТ</p>
                        <div className='login-qr'>
                            <QRCode value={src} style={{width: '100%', height: '100%'}}/>
                        </div>
                    </div>
                </Popup>
            </div>
        </>
    )
}