import { status } from 'skin/event/status'

const step = (obj, ms, story, data, d, action) => {
	const f = data.getAll(ms);
	d = JSON.parse(d)
    console.log(f.all);
    const o = {
        zoom: f.all.zoom,
        count: f.all.count,
        prod: f.all.prod
    }
    const oo = {
        type: 'master',
        code: 'prnCode',
        action: 'print',
        prn: true,
        params: o
    }
	action(oo)
	return status.action;
}

export default step;