import { status } from 'skin/event/status';
import dictionary from './dictionary';
import setInput from './set_input';

const data = (obj, ms, story, data, d, action) => {
	switch (obj.action) {
		case 'dictionary':
			return dictionary(obj, ms, story, data, d, action);
		case 'setInput':
			return setInput(obj, ms, story, data, d, action);
		default:
			return status.action;
	}
};

export default data;
