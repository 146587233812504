import { status } from 'skin/event/status'
import row from './row'

const table = (obj, ms, story, data, d, action) => {
	console.log('[Ячейки - Мастер] событие', obj);
	switch (obj.action) {
		case 'row': 
			return row(obj, ms, story, data, d, action)
		default:
			return status.action
	}
}

export default table