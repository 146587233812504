import { status } from 'skin/event/status';

const chooseAll = (obj, ms, story, data, d, action) => {
	const ctg = data.curData.values.fld.txtCtg
	if (!ctg) return status.ignore
	const list = data.curData.values.table.prod.list
	list.forEach(el => {
		data.setCell('prod', el.key, 0, '+' )
		data.setCell('prod', el.key, 4, ctg )
	});
	return status.action;
};

export default chooseAll;
