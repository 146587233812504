import { status } from "skin/event/status";
import table from "./table";

const msSegment = (obj, ms, story, data, d, action) => {
	console.log("[Группа системы RFM - Мастер] событие", obj);
	switch (obj.type) {
		case "table":
			return table(obj, d, action, ms, story, data);
		default:
			return status.action;
	}
};

export default msSegment;
