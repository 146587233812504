import { shadow } from 'skin/event';
import Head from './head';
import Item from './item';
import './style.css';
import { observer } from 'mobx-react-lite';
import m from 'skin/store/mb';

const List = ({ on, setOn, pages, current }) => {
	const mb = m.isMobile;
	let cls = ['skin-form-head-ttl-com-list', 'form-head-ttl-com-list'];
	cls = cls.join(' ');
	if(mb) cls+= ' mb-form-head-ttl-com-list'
	if (!on) return null;
	const st = { backgroundImage: 'url(skin/img/close.svg)' };
	const c = current.page;

	return (
		<div className={cls}>
			<Head />
			<ul>
				{pages.map((el, i) => (
					<Item
						key={i}
						data={el}
						current={c}
						close={() => close()}
						item={i}
					/>
				))}
			</ul>
			<div
				className="form-head-ttl-com-lst-close"
				style={st}
				onClick={close}
			></div>
		</div>
	);
	// Закрыть список экранов
	function close() {
		shadow();
		setOn(false);
	}
};

export default List;
