import cnfSkin from 'config_skin';
import { status } from 'skin/event/status';
import api from 'skin/http';

const exGrp = (obj, form, story, data, d, action) => {
    console.log('[Группа исключений - форма] событие', obj);
    if (obj.action === 'loadData') return new Promise((resolve, reject) => {
        const list = data.s.values.fld.data
        Promise.all(list?.map(el => get(el)))
            .then(r => {
                r.forEach(el => {
                    data.addRow(form, 'exception', { key: el._id, list: [el.title, el.type === 'folder' ? 'Да' : 'Нет'] })
                })
                resolve(status.action)
            })
            .catch(_ => resolve(status.action))
    })

    switch (obj.action) {
        case 'row': return status.ignore
        default: return status.action
    }
};

export default exGrp;

function get(id) {
    return new Promise((resolve, reject) => {
        const config = {
            method: 'get',
            url: cnfSkin.url + `api/exceptiion/${id}`,
            // url: 'http://127.0.0.1:3040/' + `api/exceptiion/${id}`,
        }
        api(config)
            .then(res => resolve(res.data))
            .catch(reject)
    })
}