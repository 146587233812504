import { status } from 'skin/event/status';

const control = (obj, table, story, d, action) => {
    switch (obj.action) {
        case 'edit': return status.ignore
        case 'view': return status.ignore
        default: return status.action
    }
}

export default control