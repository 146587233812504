import { message } from 'skin/event';
import { status } from 'skin/event/status';

const cancelAll = (obj, ms, story, data, d, action) => {
    // Списать остатки товара полностью
    const list = data.curData.values.table.prdWriteoff.list;
    const combi = data.curData.values.fld.combi;
    const name = data.curData.values.fld.name.split(',')[0];
// определить строки таблицы
    const r = name !== combi ? list.filter((el) => el.itemsName.includes(combi)) : list;

    if (!r.length) {
        message('Выбранный товар не найден');
        return status.ignore;
    }

    // перезаписать в таблицу
    r.forEach((el) => {
        const row = el.key;
        const column = 1;
        const value = 0;
        if (value !== el.list[1]) data.setCell('prdWriteoff', row, column, value);
    });
    return status.action;
};

export default cancelAll;