import menu from "skin/store/menu";

const Head = (props) => {
	let cls = ["skin-pg-mn-head", "pg-mn-head"];
	cls = cls.join(" ");
	const st_p = { backgroundImage: "url(skin/img/collapse.svg)", width: '35px', height: '35px' };
	const st_reload = { backgroundImage: "url(skin/img/mn_reload.svg)",width: '35px', height: '35px' };
	const el_p = menu.s.tree ? (
		<>
			<span className="ico-r" style={st_reload} onClick={reload} title="Обновить данные"></span>
			<span style={st_p} onClick={menu.closeAll}></span>
		</>
	) : null;
	return (
		<div className={cls}>
			{el_p}
		</div>
    )
}

function reload() {
	menu.getMenu();
}

export default Head;