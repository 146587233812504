import React, { useState, useEffect } from 'react';
import { stackBak, stackClear, stackSet } from 'skin/event';
import './style.css';
import { observer } from "mobx-react-lite";
import m from "skin/store/mb";
import List from './crumbList';

const Crumb = (props) => {

	const [shouldHide, setShouldHide] = useState(false);

    useEffect(() => {
        const checkClasses = () => {
            const tblFuncElement = document.querySelector('.tbl-func');
            const skinMasterElement = document.querySelector('.form-fnc-head');
            setShouldHide(!!tblFuncElement || !!skinMasterElement);
        };

        checkClasses();

        const interval = setInterval(checkClasses, 100);

        return () => clearInterval(interval);
    }, []);

    if (shouldHide) return null;

	const crb = props.stack;
	const mb = m.isMobile;
	let cls = ['skin-crumb', 'crumb', 'd-flex', 'ai-center'];
	cls = cls.join(' ');
	if(mb) cls+=' mb-crumb'
	const all = crb.length;
	if (all === 0) return <article className={cls}></article>;
	const stl1 = !mb ? { backgroundImage: 'url(skin/img/home_1.svg)' } : { backgroundImage: 'url(skin/img/mn_home.svg)', border: "none", minWidth:'30px', minHeight:'30px' } ;
	const stl2 = !mb ? {
		backgroundImage: 'url(skin/img/mn_up.svg)',
		marginLeft: '15px',
	} :{
		backgroundImage: 'url(skin/img/mn_up.svg)',
		border: 'none',
		minWidth:'30px',
		minHeight:'30px'
	 } ;

	return (
		<article className={cls}>
			<div style= { mb ? {display:'flex', alignItems:'center', width:'90vw', maxWidth:'400px'} : {display: 'flex', alignItems: 'center'}}>
			<span
				className="ico-r"
				style={stl1}
				onClick={() => stackClear()}
			></span>
			<span
				className="ico-r"
				style={stl2}
				onClick={() => stackBak()}
			></span>

			{(crb.length >= 3 && mb) &&
			<>
				<p>....&nbsp;/&nbsp;</p>
				<span key={crb.length-1}
					onClick={() => crumb(crb.length-1)}
					className="pr-1 breadcrumb mb-breadcumb"> {crb[crb.length-1].title} </span>
			</>}

			{(crb.length < 3 && mb) &&
				crb.map((el, i) => {
					return (
						<span
							key={i}
							onClick={() => crumb(i)}
							className="pr-1 breadcrumb mb-breadcrumb"
						>
							{el.title}
						</span>
					);
				}
			)}

			{!mb && crb.map((el, i) => {
				return (
					<span
						key={i}
						onClick={() => crumb(i)}
						className="pr-1 breadcrumb"
					>
						{el.title}
					</span>
				);
			})}
			</div>

			{(mb && crb.length > 1) &&  <List crb = {crb} crumb = {crumb} />}

			
			
		</article>
	);

	// Переход по крошкам на определенную позицию все с права удаляется
	function crumb(pos) {
		if (pos < 0) return;
		stackSet(pos);
	}
};

export default observer(Crumb);
