import { useContext } from 'react'
import { Cnt } from 'skin/context'
import Head from './head'
import Item from './item'
import './style.css'
// import { observer } from 'mobx-react-lite'
import m from 'skin/store/mb'

const List = props => {
	const mb = m.isMobile;
	const {action} = useContext(Cnt)
	if (!props.data.on) return null
	let cls = [
		'skin-tbl-head-com-list',
		'tbl-head-com-list'
	]
	cls = cls.join(' ')
	if(mb) cls += ' mb-tbl-head-com-list'
	const st = {backgroundImage: 'url(skin/img/close.svg)'}
	const a = props.data.arr
	return (
		<div className={cls}>
			<Head />
			<ul>
				{a.map((el, i) => <Item key={i} data={el} current={props.current} item={i} />)}
			</ul>
			<div className='tbl-head-com-lst-close' style={st} onClick={close}></div>
		</div>
	)
	// Закрыть список представлений
	function close() {
		const o = {
			type: 'view',
			action: 'close'
		}
		action(o)
	}
}

export default List
