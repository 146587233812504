import { dictionary, message } from 'skin/event';
import { status } from 'skin/event/status';

const month = (obj, ms, story, data, d, action) => {
	const info = data.curData.values.fld.year
	if (!info) {
		message(`Укажите год для формирования акта`, 'Акт оказания услуг');
		return status.ignore
	}
	const o = {
		...obj,
		info: [info]
	}
	dictionary(o)
	return status.ignore;
};

export default month;
