import { useState } from "react";
import fields from './fields.js';

// Хук с содержимым всех полей;
const useReg = () => {
    const [data, setData] = useState(
        Object.values(fields).reduce((arr, v) => {
            arr[v.name] = v.def ?? '';
            return arr;
        }, {})
    );
    const [err, setError] = useState({});

    const updateData = (k, v) => {
        setData({
            ...data,
            [k]: v
        });
    };

    const getValue = (k) => data[k];

    const updateError = (k, v) => {
        const newErr = {...err};
        newErr[k] = v;

        setError(newErr);
    };

    const getError = (k) => err[k];

    return {result: data, setData, err, setError, updateData, getValue, updateError, getError};
};

export default useReg;