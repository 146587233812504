import Help from "skin/page/header/right/help"
import Info from "skin/page/header/right/info"
import menu from "skin/store/menu";
import './style.css'

const Right = props => {
    
    return (
        <div className="skin-undheader-right">
            <div className="skin-header-burger-drop-user">
                <span onClick={menu.tree} className="ico mb-und"  style={{backgroundImage: "url(skin/img/mode_menu.svg)"}}></span>
            </div>
            <div className="skin-header-burger-drop-user"><Help mb = {props.mb} /></div> 
			<div className="skin-header-burger-drop-user"><Info mb = {props.mb} /></div>
            
        </div>
    )
}

export default Right