import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import Dialog from "skin/cmp/dialog";
import user from "skin/store/user";
import Empty from "./empty";
import Pull from "./pull";

function List({onClose, setCount, show}) {
   const list = user.list
   useEffect(() =>{
	user.msg()
	.catch(console.error)
	return _ => {
		localStorage.setItem('msg', user.last)
		setCount(0) 
	}
},[setCount])
   const cmp = list.length ? <Pull list={list} setCount={setCount}/> : <Empty />
    return ( 
		<Dialog title="Cooбщения" onClose = {onClose} show={show} miss={true}>
			{cmp}
		</Dialog>
    )
}

export default observer(List)
  