import { useState, useContext } from 'react';
import { Cnt } from 'skin/context';
import m from "skin/store/mb";
import { observer } from "mobx-react-lite";
import View from './view';
import './style.css';
import List from './list';

const Command = () => {
	const mb = m.isMobile;
	const [on, setOn] = useState(false);
	let cls = ['skin-form-head-ttl-command', 'form-head-ttl-command'];
	cls = cls.join(' ');
	console.log('on', on)
	const { title, pages, current, pgCount, action } = useContext(Cnt);

	const st = { backgroundImage: 'url(skin/img/reload.svg)', width:'30px', height:'30px' };
	const dlm = !pgCount || pgCount < 3 ? null : <span className="dlm"></span>;

	return (
		<div className={cls}>
			<span className="ico-r" style={st} onClick={click}></span>
			<span className="form-head-ttl-com-name">{title}</span>
			{!mb && 
			<>
				{dlm}
				<View
				setOn={setOn}
				current={current}
				pages={pages}
				pgCount={pgCount}
			/>
			</>}
			<List on={on} setOn={setOn} current={current} pages={pages} />
		</div>
	);

	function click() {
		const o = {
			type: 'form',
			action: 'reload',
		};
		action(o);
	}
};

export default observer(Command);
