import Item from "./item"
import "./style.css"

//Отформатированный текст
export default function Format({txt, style}) {
	if (!txt) return null
    txt = txt ?? ''
	const re = /\n\s*\n/
	const a = txt.split(re)
	return (
		<>
		{a.map((el, i) => <Item key={i} txt={el}  style={style}/>)}
		</>
	)
}