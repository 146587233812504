import { message } from "skin/event";
import { status } from "skin/event/status";
import api from "skin/http";


const act = (obj, form, story, data, d, action) => {
	if (obj.action !== 'click') return status.action
	const id = data.s.values.fld.id
	const opt = {
        method: 'GET',
		url: 'api/other/form/employee/login/' + id,
	}
	api(opt)
	.then(r => {
		const s = ['Для получения уведомлений необходимо:', '1)перейти в телеграм https://t.me/fletNotice_bot;',`2)выполнить команду /login ${r.data}`]
		message(s, 'Подключение уведомлений Телеграм', null, '!')
	})
	.catch(err =>  message('Произошла ошибка, повторите попытку позднее', 'Подключение уведомлений Телеграм', null, '!'))
	return status.ignore
}

export default act;