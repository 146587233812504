import { status } from 'skin/event/status';
import control from './control';
import table from './table';
// import forma from './form';

const about = (obj, form, story, data, d, action) => {
	console.log('[Графика - форма] событие', obj);
	switch (obj.type) {
		case 'control':
			return control(obj, form, story, data, d, action);
		case 'table':
			return table(obj, form, story, data, d, action);

		// case 'form':
		// return forma(obj, form, story, data, d, action);
		default:
	}
	return status.action;
};

export default about;
