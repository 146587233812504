import { space } from 'skin/event';

//Реакция на параметр pay, переход в мастер пополнения баланса компании
export default function pay() {
	console.log('pay')
	const o = {
		type: 'master',
		title: 'Пополнение баланса',
		data: {
			code: 'topup'
		}
	}
	space(o, true);
}