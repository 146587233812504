

//Кнопка
export default function Button({obj, close}) {
	let cls = ['ico-mb', obj.cls]
	cls = cls.join(' ')
	return (
		<span onClick={close} style={{backgroundImage: obj.name, backgroundRepeat:'no-repeat'}} className={cls}>
		</span>
	)
}