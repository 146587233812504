import api from 'skin/http';

//Получение адреса компании
function address(v, setData) {
	v = encodeURI(v)	
	const opt = {
		method: 'GET',
		url: 'api/other/signup/address',
		params: {
			val: v
		},
		headers: {
			nowait: true
		}
	}
	api(opt)
	.then(r => {
		const a = r.data?.suggestions || []
		setData(a)
	})
	.catch(err => console.log('err', err))
}

export default address