import { space, stackBak } from 'skin/event';
import prn from 'skin/print';

// Действия в Мастере по type
function msAct(obj, data, action, d, ms, story) {
	switch (obj.action) {
		// Получены даннные
		case 'loadData':
			break;

		// Сформированы Пустые данные под структуру
		// Начинаем получать данные
		case 'defData':
			data.getData(d, action, ms, story);
			break;

		// Получена структуры мастера
		case 'loadDef':
			data.defData(action, ms);
			break;

		case 'back':
			story.back(ms);
			break;

		case 'next':
			story.next(action, ms);
			break;

		case 'exit':
			clear();
			stackBak();
			break;

		case 'finish':
			clear();
			space(obj.finish, true, 'back');
			break;

		case 'reload':
			data.getData(d, action, ms, story, obj?.table);
			break;

		// Валидация мастера
		case 'check':
			ms.check(d, story, data, action);
			break;

		// Сохранение мастера
		case 'step':
			ms.step(d, story, data, action);
			break;
		// Печать с мастера
		case 'print':
			prn(obj, ms, clear);
			break;

		default:
			alert('[Мастер] Действие не определено');
	}
	function clear() {
		ms.close();
		story.kill();
		data.kill();
	}
}

export default msAct;
