import { status } from 'skin/event/status';
import print from './fn/print';
import make from './fn/make';
import mCode from './fn/m_code';

const add = (obj, form, story, data, d, action) => {
	switch (obj.id) {
		case '62b1cbb323097c8581638c76':
			return print(story, action)
		case '63529d0804faf0156bbf2653':
			return make(form, story, action)
		case '639039fd1d52cb32e7262205':
			return mCode(form, story, action)
		default:
			return status.action;
	}
};

export default add;
