import { value } from "../row/col";
import "./style.css";
const Items = ({body, cols, click}) => {

const b = body?.map((el) => { return {list: el.list, key: el.key}});


const result = b?.map((el, i) => {
    let row = {...el.list}
    let newRow = {}
    for(const key in row) {
        newRow[cols[key]?.ttl] = row[key];
    }
    return newRow;
})


const rows = result?.map((el, i) => {
    return(
        <div key = {i} onClick={_ => click(b[i].key)} className="skin-main-item">
            {Object.entries(el).map((key, i) => {
                return(
                    <div className="skin-main-item-content" key={i}>
                        <div className="skin-main-item-content-header">
                            {key[0]}
                        </div>
                        <div className="skin-main-item-content-text">
                            {value(key[1], cols[i])}
                        </div>
                    </div>
                ) 
            })}
        </div>
    )
})

return (
    <div className="skin-main-list">
        {rows}
    </div>
)

}

export default Items;