import { status } from 'skin/event/status';
import api from 'skin/http';

const save = (obj, form, story, data, d, action) => {
	console.log('[Сохранение продукта - форма] событие', obj);
	const fd = data.getChanged(form)
	const id = story.current.info[0]
	const config = {
		method: 'put',
		url: 'api/other/form/prod/'+id ,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		data: fd
	};
	api(config)
		.then(( ) => {
			const o = {
				type: 'form',
				action: 'reload',
			};
			action(o);
		})
		.catch(console.log);
	return status.ignore;
};

export default save;
