import Button from "skin/entry/signup/cmp/button";
// import Button from "skin/entry/cmp/btn";
import classList from 'skin/entry/signup/cmp/class_list.js';
import { type } from 'skin/event';
import signup from "./fn";
import './footer.css';

// Нижная часть формы, где находятся кнопки регистрации и отмены;
const Footer = ({wide, data, err, setError}) => {
	console.log('data', data)
    return (
        <footer className={classList('signup-form-footer', !wide && 'signup-form-footer-mobile')}>
            <Button title={'Зарегистрироваться'} onClick={_ => signup(data, setError)}/>
            <Button title={'Отмена'} onClick={() =>type('login')}/>
        </footer>
    );
};

export default Footer;