import Dict from 'skin/dict';
import Message from 'skin/message';
import Page from 'skin/page';
import Info from 'skin/info';
import Case from './case';
import Shadow from './shadow';
import Wait from './wait';
import SingOut from 'skin/auth/logout';
import './style.css';
import Notify from 'skin/cmp/notify';

const Space = (props) => {
	let cls = ['skin-space', 'space'];
	cls = cls.join(' ');

	return (
		<div className={cls}>
			{/* <div className="phone">
				<span>
					Использование системы доступно только для пользователей ПК
				</span>
				<div className="logout" onClick={click}>
					<p>Выйти</p>
				</div>
			</div> */}
			<div className="cmp">
				<Page data={props.data}>
					<Case space={props.data.space} />
				</Page>
				<Dict data={props.data.dictionary} />
				<Message data={props.data.message} />
				<Info data={props.data.info} />
				<Shadow data={props.data.shadow} />
				<Wait data={props.data.wait} />
				<Notify data={props.data.notify} />
			</div>
		</div>
	);
};

function click() {
	SingOut();
}
export default Space;
