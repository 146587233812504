import { status } from 'skin/event/status';
import add from './add';

const control = (obj, table, story, d, action) => {
	switch (obj.action) {
		case 'add':
			return add(obj, table, story, d, action);

		default:
			return status.action;
	}
};
export default control;
