import { status } from 'skin/event/status';
import { dictionary } from 'skin/event';

const dict = (obj, ms, story, data, d, action) => {
	console.log('[Сравнение отчётов - Мастер] событие', obj);
	console.log(d);
	d = JSON.parse(d);
	const id = d.info?.at(0);
	const o = {
		...obj,
		info: [id],
	};
	dictionary(o);
	return status.ignore;
};

export default dict;
