import './style.css'


export default function Popup({ active, setActive, children }) {
    return (
        <div className={active ? 'popup-active' : 'popup-modal'} onClick={_ => setActive(false)}>
            <div
                className={'popup-container'}
                onClick={e => e.stopPropagation()}>
                <img 
                    className={'popup-close'}
                    src='/skin/img/close_circle.svg' alt='x' 
                    onClick={_ => setActive(false)} />
                {children}
            </div>
        </div>
    )
}