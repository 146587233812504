import api from 'skin/http';

//Подтверждение кода
function submit(phone, code) {
	return new Promise((resolve, reject) => {
		const opt = {
			method: 'POST',
			url: 'api/other/signup/phone/submit',
			headers: {
				nowait: true
			},
			data: {
				phone,
				code
			}
		}
		api(opt)
		.then(resolve)
		.catch(reject)
	})
	
}

export default submit