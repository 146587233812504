import './code.css';
import iSms from 'skin/entry/signup/images/sms.svg';

import useTimer from './timer.js';
import useCode from "./use_code.js";

import codeStatus from './code_status.js';
import InputMask from 'react-input-mask';

import parseNumber from '../phone/parse_number.js';
import { useEffect } from 'react';
import { checkCode } from './code_check';
import fail from './code_sidebar/fail';

// Компонент поля подтверждения кода, высланного по указанному номеру телефона;
// При удачном подтверждении, устанавливает setSubmit -> true;
// При ошибке подтверждении кода, устанавливает setError -> причина;
const Code = ({ ...props }) => {
    const { phone, timer, setTimer, submit, setSubmit, err, phoneError, setPhoneError, setError } = {...props};
        
    const { value, setValue, onChange, pending, setPending } = useCode(submit);
    const parsedCode = parseNumber(value);

    // Рендеринг компонента, при изменении значения таймера;
    useTimer(timer, setTimer);

    // JSX с состоянием кода (введён/ожидает ввода/может перезапросить смс/отправлен/ошибка/подтверждён);
    const status = codeStatus({ phone, setCode: setValue, value: parsedCode, pending, setPending, ...props });

    // Отправка кода на проверку при введении 4 цифр;
    useEffect(() => {
        const len = parsedCode?.length || 0;
        if (!submit && !pending && len === 4) {
            checkCode(parsedCode, setPending, setSubmit, setError, phone, setTimer);
        }
    }, [parsedCode]);
    return (
    <>
        <div className='form-code'>
            <div className='code-input'>
                <div className='img-container'><img alt={'sms'} src={iSms}/></div>
                <InputMask mask={'9999'} placeholder='_ _ _ _' value={value} disabled={pending || submit} onChange={e => onChange(e.target.value)}/>
            </div>
            <div className='code-status'>
                {status}
            </div>
        </div>
        { fail(err) }
    </>
    );
};

export default Code;