import { status } from 'skin/event/status'
import wOut from './wOut'
import row from './row'
import click from './btn'

const ctgUp = (obj, ms, story, data, d, action) => {
	console.log('[Загрузка категорий - Мастер] событие', obj)
	switch (obj.action) {
		case 'wOut':
			return wOut(obj, ms, story, data, d, action)
		case 'row':
			return row(obj, ms, story, data, d, action)
		case 'click':
			return click(obj, ms, story, data, d, action)
		default:
			return status.action
	}
}

export default ctgUp
