// Действия в фильтрах
function tableAct(obj, data, action, d, ms, story) {
	switch (obj.action) {
		// Обработка клика по строке в таблице
		case 'row':
			story.setTblRow(obj);
			break;
		// case 'delete':
		// 	ms.delRow(obj.row, obj.table, action);
		// 	break;
		// case 'add':
		// 	ms.add(obj.table, data, action);
		// 	break;
		default:
			alert('[Мастер] [Таблица] Действие не определено');
	}
}

export default tableAct;
