import { useEffect, useRef } from "react";
import m from "skin/store/mb";
import user from "skin/store/user";

//Состояние бургера
export default function useBurger(onClose) {
	const ref = useRef();
	const refD = useRef();
	const mb = m.isMobile;

    useEffect(() => {
		user.get();
	}, []);

    useEffect(() => {
		const click = (event) => {
			if (!ref.current) return
			if (ref.current.contains(event.target)) return
			if (refD.current) return
			onClose();
		};
		setTimeout(() => { document.addEventListener('click', click); }, 100);
		return () =>  document.removeEventListener('click', click);
	
	}, [onClose]);

	return {mb, ref, refD}
}