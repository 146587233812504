import { status } from 'skin/event/status';

const row = (obj, ms, story, data, d, action) => {
    const key = story.s.arrToPac.table.product.row
    const list = data.s.arrToPac.values.table.product.list
    list.forEach(el => {
        if (el.key === key) {
            data.setValue('name', key)
            data.setValue('txtName', el.list[0])
            data.setValue('txtPrd', el.list[1])
            data.setValue('prd', el.prd)
            data.setValue('value', el.list[2])
            data.setValue('comment', el.list[3])
        }
    })
    if (obj.key === key || obj.table === 'package') {        
        return status.ignore
    }
	return status.action
};

export default row;
