import { status } from 'skin/event/status';

const row = (obj, ms, story, data, d, action) => {
	const list = data.curData.values.table.prod.list
	let ctg = data.curData.values.fld.txtCtg
	const i = list.findIndex(el => el.key === obj.key)
	const val = list[i].list[0] === '+' ? '' : '+'
	ctg = val === '+' ? ctg : ''
	data.setCell('prod', obj.key, 0, val )
	data.setCell('prod', obj.key, 4, ctg )
	return status.ignore
};

export default row;
