import { useState } from 'react';

import Popup from 'skin/popup';
import QR from './qr';
import './style.css'


const App = ({ mb, item, url }) => {
    const [active, setActive] = useState(false)
    let cls = 'login-app';
    if(mb) cls+= ' mb-app'
    return (
        <>
            <Popup active={active} setActive={setActive}>
                <div className={cls}>
                    <p className='login-app-title'>Скачать приложение ФЛЕТ</p>
                    <div className='login-app-line'>
                        {item.google && <div className='login-app-href'>
                            <a href={item.google} target='_blank' rel="noreferrer">
                                <img src='/skin/img/google_play.svg' alt=''
                                    className='login-app-store' />
                            </a>
                            <QR src={item.google}/>
                        </div>}
                        {item.appstore && <div className='login-app-href'>
                            <a href={item.appstore} target='_blank'rel="noreferrer">
                                <img src='/skin/img/app_store.svg' alt=''
                                    className='login-app-store' />
                            </a>
                            <QR src={item.appstore}/>
                        </div>}
                        {item.appgalery && <div className='login-app-href'>
                            <a href={item.appgalery} target='_blank' rel="noreferrer">
                                <img src='/skin/img/app_galery.svg' alt=''
                                    className='login-app-store' />
                            </a>
                            <QR src={item.appgalery} />
                        </div>}
                    </div>
                    <div className='login-app-line'>
                        <a href={url + item.href + ms()}>
                            <button className='login-app-btn'>Скачать .apk</button>
                        </a>
                    </div>
                </div>
            </Popup>
            <div className={cls} onClick={_ => setActive(true)}>
                <img className='login-app-img' src={item.img} alt=''/>
                <p className='login-app-name'>{item.title}</p>
            </div>
        </>
    )
}

export default App

function ms(){
	const t = new Date()
	return `?${t.getMinutes()}:${t.getSeconds()}`
}