import { message } from 'skin/event';
import { status } from 'skin/event/status';

const one = (obj, ms, story, data, d, action) => {
    let value = 0;
    // Списать частично остатки товара
    const list = data.curData.values.table.prdWriteoff.list;
    const one = data.curData.values.fld.one;
    const make = data.curData.values.fld.make;

    // определить строку таблицы
    const r = list.find((el) => el.list[0] === one);
    if (!r) {
        message('Выбранный товар не найден');
        return status.ignore;
    }
    // Записать в таблицу
    const remainderV = r.list[2];
    const row = r.key;
    const column = 1;

    // если товар штучный
    if (make === null) {
        value = parseInt(data.curData.values.fld.value);
        data.setValue('value', value);
    } else {
        value = data.curData.values.fld.value;
    }

    if (value < 0 || value > remainderV) {
        message('Количество на списание некорректно');
        return status.ignore;
    }
    if (value !== r.list[1]) data.setCell('prdWriteoff', row, column, value);
    return status.action;
};

export default one;
