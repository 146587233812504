export const login = {
    min: 3,
    max: 32,
    blacklist: /[^a-zA-Z0-9_]/,
    whitelistStart: /[a-zA-Z]/,
};

export const password = {
    min: 8,
    max: 32,
    blacklist: /[^a-zA-Z0-9_]/,
}

export const company = {
    housePattern: /д \d+/,
};