import { status } from 'skin/event/status';
import master from './master';

const delFile = (obj, ms, story, data, d, action) => {
    console.log('[Печать кодов - Мастер] событие', obj);
    switch (obj.type) {
		case 'master':
			return master(obj, ms, story, data, d, action);
		default:
			return status.action
	}
}

export default delFile