import { observer } from "mobx-react-lite";
import Undheader from "./undheader";
import menu from "skin/store/menu";
import Header from "./header";
import Footer from "./footer";
import Crumb from "./crumb";
import Menu from "./menu";
import "./style.css";
import Command from "./footer/command";
import Mbmenu from "./mbmenu";
import m from "skin/store/mb";

const Page = props => {
	const mb = m.isMobile;
	let cls = ["skin-page", "page"];
	cls = cls.join(" ");
	let cls_sn = ["sandbox"];
	if (!menu.s.tree) cls_sn.push("full");
	cls_sn = cls_sn.join(" ");
	if(mb) cls_sn += ' mb-sandbox'
	const mode = props.data.space.type
	return (
		<div className={cls}>
			{((!mb) || (mb && (mode !== "table") && (mode !== "master") && (mode !== "form"))) && <Header mode={mode} />}
			{mb && <Undheader mb = {mb} mode={mode} />}
			{Boolean(props.data.stack.length) && <Crumb stack={props.data.stack} />}
			<article className="main">
				{!mb && 
				<>
					<Menu />
					<div className={cls_sn}>{props.children}</div>
				</>}
				{mb && <Mbmenu cls_sn = {cls_sn} child = {props.children}/>}
			</article>
			{!mb && <Footer data={props.data} mb={props.mb} /> }
			{mb && <Command mb = {mb}/> }
		</div>
	);
};

export default observer(Page);
