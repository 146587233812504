import { useEffect, useState } from "react";
import user from 'skin/store/user';
import List from "./list";
import './style.css';

const Burger = props => {
    const [show, setShow] = useState(false);
    const click = _ => setShow(!show);
    const close = _ => setShow(false);
    
	useEffect(() => {
		user.get();
	}, []);

    return (
		<>
            <span onClick={click} style = {{backgroundImage: 'url(skin/img/burger.svg)', backgroundRepeat:'no-repeat', width:'30px', height:'30px'}}>
            </span>
			{show &&  <List onClose = {close}/>}
		</>
            
    )
}

export default Burger;