const data = {
	code: 'market',
	domen: 'www.market.flet.su',

	// url: 'http://127.0.0.1:3050/',
	// url: 'http://192.168.1.99:3050/',
	// url: 'http://192.168.1.101:3050/',
	url: 'https://market-api.flet.su/',
	// url: 'https://a-market-api.flet.su/',
	copyright: 'Copyright 2022 © Flet',
	userForm: 'employee',
	tech: {
		phone: '+79275343409',
		phoneText: '+7 (927) 534 34 09',
		email: '',
	},
	apk: [
		{
			id: 'demo',
			title: 'Демо',
			href: 'file/flet.apk',
			img: '/skin/img/demo.svg',
		},
		{
			id: 'cour',
			title: 'Курьер',
			href: 'file/courier.apk',
			img: '/skin/img/courier.svg',
		},
		{
			id: 'pick',
			title: 'Склад',
			href: 'file/picker.apk',
			img: '/skin/img/picker.svg',
		},
		{
			id: 'cafe',
			title: 'Умная выдача',
			href: 'file/cafe.apk',
			img: '/skin/img/cafe.svg',
			google: 'https://play.google.com/store/apps/details?id=com.ivolgait.cafe&pli=1',
			appstore: 'https://apps.apple.com/app/%D1%84%D0%BB%D0%B5%D1%82-%D1%83%D0%BC%D0%BD%D0%B0%D1%8F-%D0%B2%D1%8B%D0%B4%D0%B0%D1%87%D0%B0/id6475040841',
			// appgalery: 'appgalery'
		},
	],
	docs: [
		{
			file: 'file/offer-flet.pdf',
			text: 'Оферта',
		},
		{
			file: 'file/contract.pdf',
			text: 'Договор',
		},
	],
};
export default data;
