import { status } from 'skin/event/status';
import { space } from 'skin/event';

const btnNew = (obj, ms, story, data, d, action) => {
	console.log(
		'[Перевод в остатки, клик "Добавить новый" - Мастер] событие',
		obj
	);
	const o = {
		type: 'master',
		title: 'Добавление товара',
		data: { code: 'msProd', info: [], type:'Приход' },
	};
	space(o, true);
	return status.ignore;
};

export default btnNew;
