import { status } from 'skin/event/status';
import { space } from 'skin/event';

const row = (obj, form, story, data, d, action) => {
	const key = story.current.table.pac.row;
	if (obj.key === key) {
		const info = [key];
		const o = {
			type: 'master',
			title: 'Задание на фасовку',
			data: {
				code: 'msPack',
				type: 'row',
				info,
			},
		};
		space(o, true);
		return status.ignore;
	}
	return status.action
};

export default row;
