import { status } from 'skin/event/status';
import request from '../fn';

const category = (obj, ms, story, data, d, action) => {
	const hex = obj.value
	request(hex)
	.then(r => {
		const {light, dark} = r
		data.setValue('background', light.color)
		data.setValue('text', light.onColor)
		data.setValue('darkBack', dark.color)
		data.setValue('darkText', dark.onColor)
	})
	.catch(err => console.log('err', err))
	return status.action
};

export default category;
