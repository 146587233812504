import { makeAutoObservable } from 'mobx';
import def from './def';
import pick from './pick';
import list from './list';
import orders from './orders';
import about from './about';
import graphics from './graphics';
import usRack from './us_rack';
import varProd from './var_prod';
import prod from './prod';
import mProd from './m_prod';
import mProdEx from './m_prod';
import message from './message';
import appColor from './color';
import employee from './employee';
import payConfig from './pay_config';
import goods from './goods';
import mGoods from './m_goods';
import mGoodsEx from './m_goods';
import bidList from './bid_list';
import cmpRew from './cmp_rew';
import elProp from './el_prop';
import listPack from './list_pack';
import proImg from './pro_img';
import proList from './pro/list';
import proCategory from './pro/category';
import proProduct from './pro/product';
import rfmData from './rfm_data';
import app from './app';
import traffic from './traffic';
import tariff from './tariff';
import payment from './payment';
import bill from './bill';
import exGrpMrkt from './ex_grp'
import mobAg from './mob_ag'

class Data {
	s = {
		def,
		pick,
		list,
		orders,
		about,
		graphics,
		usRack,
		varProd,
		prod,
		message,
		appColor,
		employee,
		payConfig,
		goods,
		bidList,
		cmpRew,
		mGoods,
		mProd,
		elProp,
		listPack,
		proImg,
		mGoodsEx,
		mProdEx,
		proList,
		proCategory,
		proProduct,
		rfmData,
		app,
		traffic,
		tariff,
		payment,
		bill,
		exGrpMrkt,
		mobAg
	};
	constructor(props) {
		makeAutoObservable(this);
	}
}

const data = new Data();
export default data;
