import './style.css';

const balance = ({blc, tar, adm, mb}) => {
	let cls = ['skin-hdr-r-balance', 'hdr-r-balance', 'balance'];
	const st = {}
	if(!adm) st.display = 'none'
	cls = cls.join(' ');
	if(mb) cls+= ' bal-mb'
	return (
		<div className={cls} style={st}>
			<span className={mb ? 'skin-hdr-r-drop-bal' : ' '}>Баланс <u>{+blc.toFixed(2)}</u> руб.</span>
			{tar?.name && (<span className={mb ? 'skin-hdr-r-drop-tar' : ' '}>Тариф <strong>{tar.name}</strong></span>)}
		</div>
	);
}


export default balance
