import Format from "skin/cmp/format"
import { fDate } from "skin/cmp/format/fn"


//Сообщение пользователя
export default function Item({value, date, end}) {
	const fdate = fDate(3, date) + ", " + fDate(2, date) 
  return (
    
    <div className='cmp-msg-list-item'>
        <div className="cmp-msg-list-item-body">
         <Format txt={value} />
		</div>
        <div className="cmp-msg-list-item-date">
            <Format txt={fdate}/>
        </div>
		{!end && <hr/>}
    </div>
  )
}