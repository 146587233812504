import { status } from 'skin/event/status';
import dt from 'skin/store/master/data'

const row = (obj, ms, story, data, d, action) => {
    const list = story.curData.values.table.arrToRem.list
    list.forEach(el => {
        if (el.key === obj.key) {
            dt.setValue('name', el.list[0])
            dt.setValue('value', el.list[1])
			dt.setValue('pName', el.list[2])
        }
    });
    const key = ms.s.arrToRem.table.arrToRem.row
    if (obj.key === key) {
        return status.ignore
    }
	return status.action
};

export default row;
