import { status } from 'skin/event/status';
import master from './master';


const pcg = (obj, ms, story, data, d, action) => {
	console.log('[Ячейки - Мастер] событие', obj);
	switch (obj.action) {
		case 'next':
			return master(obj, ms, story, data, d, action)
		default:
			return status.action
	}
}

export default pcg