import { status } from 'skin/event/status';
import next from './next';

const master = (obj, ms, story, data, d, action) => {
    console.log('[Ячейки - Мастер] событие', obj);

    switch (obj.action) {
        case 'next':
            return next(obj, ms, story, data, d, action)
        default:
			return status.action
    }
}

export default master