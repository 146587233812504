import { status } from 'skin/event/status';

const row = (obj, ms, story, data, d, action) => {
	const key = story.s.msMod.table.product.row
	const list = data.s.msMod.values.table.product.list
	list.forEach(el => {
		if (el.key === key) {
			data.setValue('name', key)
		}
	})
	if (obj.key === key) return status.ignore
	return status.action
}

export default row