import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';
import m from "skin/store/mb";
import user from 'skin/store/user';
import Balance from './balance';
import Help from './help';
import Info from './info';
import Logout from './logout';
import Message from './message';
import Personal from './personal';
import Product from './product';
import './style.css';
const Right = (props) => {
	let cls = ['skin-header-right', 'header-right', 'd-flex jc-end ai-center'];
	cls = cls.join(' ');
	const mb = m.isMobile;
	const ref = useRef()
	useEffect(() => {
		user.get();
	}, []);
	
	return (
		<div className={cls}>
			<Product />
			<Balance blc={user.blc} tar={user?.tar} adm={user.adm} />
			<Personal avatar={user.avatar} info={[user.id]} />
			<span className="user-name" title={user.name}>
				{user.name}
			</span>
			<Logout />
			<Message mb ={mb}  refD={ref}/>
			<Help />
			<Info />
		</div>
	);
};

export default observer(Right);
