import { status } from 'skin/event/status';
import table from './table';
import dt from './data'
import btn from './btn'

const parList = (obj, ms, story, data, d, action) => {
	console.log('[Партнерские товары - Мастер] событие', obj);
	switch (obj.type) {
		case 'table':
			return table(obj, ms, story, data, d, action);
		case 'data':
			return dt(obj, ms, story, data, d, action);
		case 'btn':
			return btn(obj, ms, story, data, d, action);
		default:
			return status.action;
	}
};

export default parList;
