import iOk from 'skin/entry/signup/images/ok.svg';
import iWait from 'skin/entry/signup/images/wait.svg';
import './code.css';

import fail from './code_sidebar/fail';
import idle from './code_sidebar/idle';
import submitBar from './code_sidebar/submit';

import { checkCode, requestCode } from "./code_check";

// Рендеринг состояния верификации номера телефона (кода по смс);
const codeStatus = ({ phone, submit, err, pending, setPending, value, setCode, timer, setTimer, setSubmit, setError, phoneError, setPhoneError }) => {
    switch(true) {
        case (submit):
            return <div className='img-container green-circle-bound'><img alt='ok' src={iOk}/></div>
        case (err != null):
            return idle({ timer, onClick: _ => requestCode(phone, setTimer, setError, setCode) })
        case(pending):
            return <div className='img-container green-circle-bound'><img alt='wait' src={iWait}/></div>
        case(value.length === 4):
            return submitBar({ timer/*, onClick: _ => checkCode(value, setPending, setSubmit, setError, phone, setTimer)*/ })
        default:
            return idle({ timer, onClick: _ => requestCode(phone, setTimer, setError, setCode) })
    }
};

export default codeStatus;