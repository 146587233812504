import cnfTask from 'config_task'
import user from 'skin/store/user';
import { status } from 'skin/event/status';

//Просмотр карточки товара в мобильном приложении
function print(story, action) {
	const productId = story.current.info[0]
		const o = {
			productId,
			imgUrl: `${cnfTask.url}company/${user.code}/img/`
		}
		const oo = {
			type: 'form',
			code: 'varProd',
			action: 'print',
			params: o
		}
		action(oo)
		return status.ignore
}

export default print;