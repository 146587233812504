import { status } from 'skin/event/status'
import user from 'skin/store/user';

const step = (obj, ms, story, data, d, action) => {
	const f = data.getAll(ms);
	const o = {
		first: f.all.first,
		second: f.all.second,
		scale: f.all.scale,
		companyId: user.companyId
	}
	const oo = {
		type: 'master',
		code: 'printPlc',
		action: 'print',
		prn: true,
		params: o
	}
	action(oo)
	return status.ignore;
}

export default step;
