import { message } from 'skin/event';
import { status } from 'skin/event/status';

function check(data, story, action) {
	const cur = story.curPage;
	switch (cur) {
		case 0: {
			const values = data.s.delFile.values.fld;
			const option = Object.values(values).includes(true);
			if (!option) {
				message(`Действие не определено`, 'Форматирование данных');
				return status.ignore;
			}
			break;
		}
		case 1: {
			const o = {
				type: 'master',
				action: 'exit',
			};
			action(o);
			return status.ignore;
		}
		default:
			return status.action;
	}
}

export default check;
