import { status } from 'skin/event/status';
import api from 'skin/http';
import { message } from 'skin/event';

const prod = (obj, form, story, data, d, action) => {
	const id = obj.row.key;
	const config = {
		method: 'delete',
		url: 'api/other/form/goods/del/' + id,
	};
	api(config)
		.then((res) => {
			const err = res.data.error;
			if (err) return message(err, 'Пример продуктов', null, '!');
			const o = {
				type: 'form',
				action: 'reload',
			};
			action(o);
		})
		.catch(console.log);
	return status.ignore;
};

export default prod;
