import { status } from 'skin/event/status';
import { message } from 'skin/event';

const next = (obj, ms, story, data, d, action) => {
	console.log('[Ячейки - Мастер] событие', obj);
    const cell = story.curData.values.fld.object
    const place = story.curData.values.table.msCell.list.length
    let k = 0
    for(let key in cell) {
        if(cell[key] !== '') k++
    }
    if(k !== place) {
        const delta =  place - k
        message(`Необходимо выбрать ячейки в количестве ${delta} шт.`, 'Ячейки');
        return status.ignore
    }
	return status.action
};

export default next;
