import { status } from 'skin/event/status';
import table from './table';
import btn from './btn';
import master from './master';

const rewDown = (obj, ms, story, data, d, action) => {
    console.log('[Выгрузка отзывов - Мастер] событие', obj);

    switch (obj.type) {
        case 'table': return table(obj, ms, story, data, d, action);
        case 'btn': return btn(obj, ms, story, data, d, action);
        case 'master':
            return story.curPage === 1 ?
                master(obj, ms, story, data, d, action)
                :
                status.action;
        default: return status.action;
    }
}

export default rewDown