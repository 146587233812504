import { status } from 'skin/event/status';
import table from './table';

const orders = (obj, form, story, data, d, action) => {
	console.log('[Пополнение - форма] событие', obj);
	switch (obj.type) {
		case 'table':
			return table(obj, form, story, data, d, action);
		default:
	}
	return status.action;
};

export default orders;
