import { space } from "skin/event";


//Реакция на query параметры в запросе
export default function query(v) {
	const o = {
		type: 'table',
		title: 'Пополнение',
		data: {
			code: 'bill',
		},
	};
	space(o, true);
	window.history.replaceState(null, '', `${window.location.pathname}`)
}