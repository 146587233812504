import { status } from 'skin/event/status';

function table(obj, d, action, ms, story, data) {
	switch (obj.action) {
		case 'row':
			if (obj.table !== 'plg') return status.action;
			data.setCell(obj.table, obj.key, 0, 'v');
			return status.ignore;
		default:
			return status.action;
	}
}

export default table;
