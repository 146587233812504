import './field.css';
import Base from './types/base';
import Company from './types/company/company';
import Option from './types/option';
import Phone from './types/phone/phone';
import Suggest from './types/suggest/suggest';

const Field = ({type, ...props}) => {
    switch(type) {
        case 'suggest':
            return <Suggest {...props} />
        case 'phone':
            return <Phone {...props} />;
        case 'company':
            return <Company {...props} />;
        case 'options':
            return <Option {...props} />
		default:
			return <Base {...props}/>
    };
};

export default Field;