import { useEffect, useState } from 'react'
import Field from '../../cmp/field'
import Title from '../../cmp/title'
import Btn from '../../cmp/btn'
import Mes from '../../cmp/mes'
import Login from 'skin/auth/login';
import { type } from 'skin/event';
import Apps from './apps'
import Moment from 'react-moment';
import './style.css'
import m from 'skin/store/mb';
import { observer } from 'mobx-react-lite';


const Win = (props) => {
    const mb = m.isMobile;
    const [login, setLogin] = useState('')
    const [pass, setPass] = useState('')
    const [mes, setMes] = useState('')
    const [ip, setIp] = useState(null)
    
    let clsFields = 'login-main-fields';
    if(mb) clsFields += ' mb-fields';

    useEffect(_ => {
        fetch('https://ru.sxgeo.city/json/', { method: 'GET' })
            .then(res => res.json())
            .then(data => {
                if (data.ip) setIp(data.ip)
            })
            .catch(err => console.log(err))
    })
    return (
        <div className='login-content'>
            <div style = {mb ? {padding:"24px"} : {}} className='login-main-container'>
                <Title name = {'Авторизация'}/>
                <form style = { mb ? {display:'flex', flexDirection:'column', alignItems:'center', gap:'20px', paddingTop:'20px'} : {}} >
                    <div className={clsFields}>
                        <Field
                            title='Логин'
                            placeholder='Введите логин'
                            img='/task/img/login_p.svg'
                            act={setLogin}
                            type='text'
                            mb = {mb}
                        />
                        <Field
                            title='Пароль'
                            placeholder='Введите пароль'
                            img='/task/img/pass_p.svg'
                            act={setPass}
                            type='password'
                            mb = {mb}
                        />
                    </div>
                    <Mes text={mes} />

                    {!mb && <div className={clsFields}>
                        <Btn
                            title='Войти'
                            act={log}
                            submit
                        />
                        <Btn
                            title='Регистрация'
                            act={signup}
                        />
                        <Btn
                            title='Сбросить пароль'
                            act={reset}
                        />
                    </div>}
                    {mb && 
                    <div className={clsFields} style={{display:'flex', flexDirection:'column', alignItems:'center', width:'100%', gap:'10px'}}>
                        <Btn
                            title='Войти'
                            act={log}
                            submit
                        />
                        <div className='login-main-btn-group'>
                            <Btn
                                title='Регистрация'
                                act={signup}
                            />
                            <Btn
                                title='Сбросить пароль'
                                act={reset}
                            />
                        </div>
                    </div>}
                </form>
            </div>
            <Apps mb = {mb} />
            {ip && !mb && <div className='login-info'>
                <p className='login-info-text'>IP-адрес: {ip}</p>
                <p className='login-info-text'>Браузер: {browser()}</p>
                <p className='login-info-text'>Дата и время: <Moment format="DD.MM.YYYY, HH:mm:ss" interval={1000} /></p>
            </div>}            
        </div>
    )

    function log(e) {
        if (!login) return setMes('Заполните логин!')
        if (!pass) return setMes('Заполните пароль!')
        setMes('')
        e.preventDefault()
        return Login(login, pass, setMes)
    }

    // Регистрация
    function signup() {
        type('signup');
    }

    // Сброс пароля
    function reset() {
        type('reset')
    }

    function browser() {
        const b = window.navigator.userAgent.toLowerCase()
        if (b.includes('edg')) return 'Microsoft Edge'
        if (b.includes('firefox')) return 'Firefox'
        if (b.includes('opr')) return 'Opera'
        if (b.includes('chrome')) return 'Chrome'
        if (b.includes('safari')) return 'Safari'
        return 'Internet Explorer'
    }
}

export default observer(Win)

// const data = {
//     ip: '192.168.1.1.',
//     browser: 'Safari',
//     date: new Date().toLocaleDateString()
// }