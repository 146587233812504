import { status } from 'skin/event/status';
import yandex from './yandex';

const click = (obj, table, story, d, action) => {
	console.log('click')
	switch (obj.id) {
		case '67126ff8dcd724001e199e1b':
			return yandex(obj, table, story, d, action);
		default:
			return status.action;
	}
};
export default click;
