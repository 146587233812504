import App from './app'
import data from '../../../../../config_task'
import './style.css'

const Apps = ({mb}) => {
    let cls = 'login-main-apps';
    if (mb) cls += ' mb-apps';
    return (
        <div className={cls}>
            <p className='login-main-apps-title'>Скачать приложение</p>
            <div className='login-main-apps-line'>
                {data.apk.map(el => <App mb = {mb} key={el.id} item={el} url={data.url}/>)}
            </div>
        </div>
    )
}

export default Apps