import { status } from 'skin/event/status'
import btn from './btn'
import table from './table'
import control from './control'


const msProT = (obj, ms, story, data, d, action) => {
	console.log('[Продукт - форма] событие', obj)
	switch (obj.type) {
		case 'table': 
			return table(obj, ms, story, data, d, action)
		case 'btn': 
			return btn(obj, ms, story, data, d, action)
		case 'control':
			return control(obj, ms, story, data, d, action);
		default:
			return status.action
	}
}

export default msProT