import { status } from 'skin/event/status';
import act from './act';

const mGoods = (obj, table, story, d, action) => {
	console.log('[Категория - Таблица] событие', obj);
	switch (obj.type) {
		case 'action':
			return act(obj, table, story, d, action)
		
		default:
	}
	return status.action;
};

export default mGoods;
