import { status } from 'skin/event/status';
import table from './table';
import frm from './form'
import act from './action';
const bid = (obj, form, story, data, d, action) => {
    console.log('[Комплектация - форма] событие', obj);
    switch (obj.type) {
        case 'table':
            return table(obj, form, story, data, d, action);
		case 'form':
            return frm(obj, form, story, data, d, action);
		case 'action':
            return act(obj, form, story, data, d, action);
        default:
			return status.action
    }
}

export default bid