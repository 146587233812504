import { status } from 'skin/event/status';
import check from './fn/check';

const control = (obj, form, story, data, d, action) => {
	switch (obj.table) {
		case 'rfmDataGroup':
			return check(obj, form, story, data, d, action);
		default:
			return status.action;
	}
};

export default control;
