import m from "skin/store/mb";
import Exit from './exit';
import './style.css';
import useDialog from './use';
/**
 * Функциональный компонент, принимающий title, children, onClose и show в качестве props.
 * Использует HTML-элемент <dialog> для создания диалогового окна.
 * Примечание:
	Поддержка HTML-элемента <dialog> браузерами ограничена
 * @param {Object} props
 * title: Заголовок диалогового окна.
 * children: Содержимое диалогового окна.
 * onClose: Функция, вызываемая при закрытии диалогового окна.
 * show: Флаг, определяющий, открыто ли диалоговое окно.
 * ref используется для доступа к элементу <dialog> и управления им.
 * close закрывает диалоговое окно и вызывает onClose, если она предоставлена. 
 * miss если true, то не закрывает диалоговое окно при нажатии вне окна
 * @returns 
 */
export default function Dialog({ 
	title,
	children,
	onClose,
	show = false,
	miss = false,
	className ,  // Добавьте prop для классов
	style = {}, // Добавьте prop для стилей
}) {	
	const mb = m.isMobile
	let cl = 'cmp-dialog'
	if (mb) cl += '-mb'
	const cls = [cl];
	if (className) cls.push(className)
	const {dialogRef, close, pl} = useDialog(show, miss)
	if (!show) return null
	const clos =  e => {
		console.log('clos')
		if (e.target === dialogRef.current) return onClose()
	}
	const cnt = mb ? cl + "-content " + pl : cl + "-content"
	return (
		<dialog  ref={dialogRef} onClose={clos}  className={cls.join(' ')} >
			{!mb && <Exit close={close}/>}
			<div className={cnt} style={style}>
				<div className={cl + "-header"}>
					{mb && <Exit close={close}/>}
					{title && <h2>{title}</h2>}
				</div>
				<div className={cl + "-body"}>
					{children}
				</div>
			</div>
		</dialog>
	);
}