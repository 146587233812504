import { status } from 'skin/event/status'

const next = (obj, ms, story, data, d, action) => {
	const list = data.curData.values.table.column.list
	const fld = data.curData.values.fld
	const column = list.filter((el) => el.list[0]).map((el) => el.list[1])
	// if (!prod.length) return status.action
	const o = {
		name: 'Выгрузка файла с товарами',
		in: {
			category: fld.txtCtg,
			categoryId: fld.categoryId,
			column,
		},
		pug: true,
	}
	const act = {
		type: 'data',
		action: 'wAdd',
		code: 'msProdDwnl',
		data: o,
	}
	action(act)
	return status.action
}

export default next
