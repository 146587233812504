import { status } from 'skin/event/status';
import { kit } from 'skin/data/message';
import { message } from 'skin/event';

const master = (obj, ms, story, data, d, action) => {
	console.log('action', obj.action);
	const make = data.s.genNo.values.fld.make
	const no = 'нет'
	const o = {
		type: 'master',
		action: 'check'
	}
	const aBtn = kit('yesno');
	aBtn[0].action = _ => action(o)
	make === no ?
	action(o)
	:
	message(
		`Тип весового товара "${make}" будет изменен на "${no}". Продолжить?`,
		'Ячейки',
		aBtn,
		'?'
	)
	return status.ignore
}

export default master