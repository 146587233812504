import { status } from 'skin/event/status';

const row = (obj, ms, story, d, action) => {
	const key = ms.s.defTar.table.defTar.row
	const list = story.curData.values.table.defTar.list
	const idx = list.findIndex(el => el.list[1] === true )
	if (obj.key !== list[idx]?.key) {
		if (idx>=0) {
			story.setCell('defTar', list[idx].key, 1, false)
		}
			story.setCell('defTar', obj.key, 1, true)
	}
	if(obj.key === key) {
		return status.ignore
	}
	return status.action
};



export default row;
