import { status } from 'skin/event/status';
import api from 'skin/http';

const pickCell = (obj, form, story, data, d, action) => {
	const config = {
		method: 'put',
		url: 'api/other/form/pick/cell/' + obj.row.key,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	};
	api(config)
		.then(( ) => {
			const o = {
				type: 'form',
				action: 'reload',
			};
			action(o);
		})
		.catch(console.log);
	return status.ignore;
};

export default pickCell;
