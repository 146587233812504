import m from 'skin/store/mb';
import { observer } from 'mobx-react-lite';
import './style.css'

const Left = ({action, title}) => {
	const mb = m.isMobile;
	let cls = [
		'skin-ms-ttl-left',
		'ms-ttl-left'
	]
	cls = cls.join(' ')
	if(mb) cls+=' mb-ms-ttl-left'
	const st = {backgroundImage: 'url(skin/img/reload.svg)'}
	const st2 = { backgroundImage: 'url(skin/img/close.svg)', backgroundSize: "12px", width: '30px', height:'30px' };
	return (
			<>
				{mb &&
				<div className={cls}>
					<div style={{display:'flex', alignItems:'center'}}>
						<span className='ico-r' style={st} onClick={reload}></span>
						<p>{title}</p>
					</div>
					<div style={{display:'flex'}}>
						<span className='ico-r' style={st2} onClick={close}></span>
					</div>
				</div>	
				}
				{!mb && 
				<> 
				<div className={cls}>
					<span className='ico-r' style={st} onClick={reload}></span>
					<p>{title}</p>
				</div>
				</>}
			</>
				
	)

	// Перечитать мастер
	function reload() {
		const o = {
			type: 'master',
			action: 'reload'
		}
		action(o)
	}
	function close() {
		const o = {
			type: 'master',
			action: 'exit',
		};
		action(o);
	}
}

export default observer(Left)
