import { status } from 'skin/event/status'
import bidList from './bid_list'

const table = (obj, form, story, data, d, action) => {
	switch (obj.action) {
		case 'row':
			return bidList(obj, form, story, data, d, action)
		default:
	}
	return status.action
}

export default table
