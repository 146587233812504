import { message } from 'skin/event';
import { status } from 'skin/event/status';
import api from 'skin/http';
//Отправка заявки
export default function cancel(obj, form, story, data, d, action) {
	const fld = data.s.values.fld
	const id = fld._id
	const config = {
		method: 'get',
		url: 'api/other/form/bidList/cancel/' + id
	}
	api(config)
	.then(res => {
	})
	.catch(err => {
		const dt = err?.response?.data?.msg
		console.log('err', dt)
		if (dt) message(dt, 'Отмена заявки')
	})
	return status.ignore
}