import { status } from 'skin/event/status';
import add from './add';

const control = (obj, form, story, data, d, action) => {
	console.log('[Комплектация - форма] событие', obj);
		if(obj.table === 'list') return status.ignore
	switch (obj.action) {
		case 'add':
			return add(obj, form, story, data, d, action);
	
		default:
	}
	return status.action;
};

export default control;
