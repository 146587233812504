import msAct from './master';
import dataAct from './data';
import tableAct from './table';

const def =  {
	master: msAct,
	table: tableAct,
	data: dataAct,
};
export default def
