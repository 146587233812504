import { status } from 'skin/event/status';
import { space } from 'skin/event';

const btnCopy = (obj, ms, story, data, d, action) => {
	console.log(
		'[Перевод в остатки, клик "Скопровать товар" - Мастер] событие',
		obj
	);
	let select = data.curData.values.fld.name;
	select = select.split(' ')[0];
	const o = {
		type: 'master',
		title: 'Копирование партнерского товара',
		data: { code: 'prdCopy', info: [select], type:'prdCopy' },
	};
	space(o, true);
	return status.ignore;
};

export default btnCopy;
