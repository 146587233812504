const { default: api } = require("skin/http")


function request(hex) {
	return new Promise((resolve, reject) => {
		hex = encodeURIComponent(hex)
		const opt = {
			method: "GET",
			url: 'other/color/' + hex,
			headers: {
				nowait: true
			}
		}
		console.log('opt', opt)
		api(opt)
		.then(r => resolve(r.data))
		.catch(reject)
	})
}

export default request