import Left from "./left";
import Right from "./right";
import './style.css'


const Undheader = props => {
    let cls = ['skin-undheader', 'undheader', 'wrapper'];
    cls = cls.join(' ');

    return (
        <div className={cls}>
            <Left mode = {props.mode}/>
            <Right mb = {props.mb} />
        </div>
    )
}

export default Undheader