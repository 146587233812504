import { status } from 'skin/event/status';
import actionn from './action';

const message = (obj, form, story, data, d, action) => {
	console.log('[Сообщение - форма] событие', obj);
	switch (obj.type) {
		case 'action':
			return actionn(obj, form, story, data, d, action);
		default:
	}
	return status.action;
};

export default message;
