const { default: axios } = require('axios');
const { crtEvent } = require('skin/event');

const b = '5445162689:AAGu0NcYc7Kq5N7HlZqvwjwcyw-6b6KbHng';
const chId = '275535167';
/**
 *
 * @param {String} token Токен
 * @param {String} text Сообщение
 * @param {String} chat_id ИД Получателя
 * @returns
 */
function send(error, url) {
	const debug = url === 'http://127.0.0.1:3050/';
	let msg = [];
	msg.push(error.toString());
	if (error.response) {
		// Запрос был сделан, и сервер ответил кодом состояния, который
		// выходит за пределы 2xx
		
		let data = error.response?.data
		data ??= ''
		data = typeof data === 'object' ? JSON.stringify(data, null, 2) : data
		const status = error.response?.status
			? JSON.stringify(error.response?.status, null, 2)
			: 'null';
		msg.push('response.data:' + data);
		msg.push('response.status:' + status);
		msg.push('config:' + JSON.stringify(error.config, null, 2) )
		if (error.toString() === 'AxiosError: Network Error')
			note([
				'Нет ответа от сервера или превышено время ожидания!',
				'Проверьте интернет соединение или обратитесь к администратору.',
			]);
		else
			note([
				data,
			]);
	} else if (error.request) {
		// Запрос был сделан, но ответ не получен
		// `error.request`- это экземпляр XMLHttpRequest в браузере и экземпляр
		// http.ClientRequest в node.js
		msg.push('Запрос был сделан, но ответ не получен!');
		note(msg);
		const req = error.request
			? JSON.stringify(error.request, null, 2)
			: 'null';
		msg.push('error.request:' + req);
		note(msg);
	} else {
		// Произошло что-то при настройке запроса, вызвавшее ошибку
		msg.push('Error: ', error.message);
		note('Запрос не удалось отправить! Ошибка настроек!', error.message);
	}
	msg.push('config: ', JSON.stringify(error?.config?.params ?? {}, null, 2));
	msg = msg.join('\n');
	console.log('MSG', msg)
	// не стучим в бот в локальной сети
	if (debug) return;
	var config = {
		method: 'post',
		url: 'https://api.telegram.org/bot' + b + '/sendMessage',
		headers: {
			'Content-Type': 'application/json',
		},
		data: { text: msg, chat_id: chId },
	};

	axios(config)
		.then((_) => console.log(msg))
		.catch((err) => console.log(err));
}

function note(txt) {
	const obj = {
		action: 'notify',
		type: 'add',
		data: { txt, type: 'error' },
	};
	crtEvent(obj);
}
module.exports = { send };
