import { useEffect, useState } from 'react';
import Error from '../error';
import Label from './lable';
import './style.css';

const CheckBox = ({ s, v, e, setValue, action }) => {
	const {
		_id,
		name,
		off,
		title = '',
		disabled = false,
		width = '30px',
		style = {padding: '5px'},
		type = 'type',
		autoComplete = 'off',
	} = s;

	// Значение
	const [val, setVal] = useState(v);
	// Массив ошибок - получаем пропсами из mobx
	const [err, setErr] = useState(e);
	// Индикатор Показывали ошибки или нет
	const [status, setStatus] = useState(false);

	useEffect(() => {
		setErr(e);
	}, [e]);
	let cls = ['skin-checkbox', 'checkbox'];

	if (err) cls.push('error');
	cls = cls.join(' ');
	if (off) style.display = 'none';

	return (
		<div className={cls} style={style}>
			
			<div className='input' onClick={onFocus}>
				<input
					id={_id}
					type={type}
					name={name}
					value={val}
					autoComplete={autoComplete}
					style={{width, aspectRatio: '1/1'}}
					onChange={onChange}
					title={title}
				/>
				
			</div>
			<Label label={s?.label} />
			{/* Ошибки */}
			{status ? <Error err={err} onClick={() => setStatus(false)} /> : null}
		</div>
	);

	function onChange(e) {
		setStatus(false);
		setErr(null);
		setVal(e.target.checked);

		setValue(name, e.target.checked, type);
	}

	// Показываем список ошибок
	function onFocus(e) {
		if (err && !status) {
			setStatus(true);
		}
	}

	
};

export default CheckBox;
