import { useRef } from 'react';
import './suggest.css';

import Button from 'skin/entry/signup/cmp/button';
import Err from 'skin/entry/signup/cmp/err';

const Suggest = ({err, setError, readOnly=false, suggest: {focus, setFocus, data}, title, value, setValue, onChange, placeholder, button, img}) => {
    const ref = useRef(null);

    const onFocus = (e) => {
        setFocus(true);
    };
    const onBlur = (e) => {
        setFocus(false);
    };

    const show = focus && data?.length > 0;

    return (
        <div className='suggest-container'>
            <span className='suggest-title'>{ title }</span>
            <div style={show ? {
                borderRadius: '16px 16px 0 0',
            } : {}} className='suggest-input'>
                <div className='inline-input' onClick={() => {ref.current && ref.current.focus()}}>
                    <input
                        style={readOnly ? {cursor: 'default'} : {}}
                        ref={ref}
                        readOnly={readOnly}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        value={value}
                        onChange={(e) => {
                            onChange(e);
                        }}
                        placeholder={placeholder}
                    />
                    {button && <Button iref={ref} {...button} />}
                    {img && <div className='img-container'><img src={img} alt={'img'}/></div>}
                </div>
                { show &&
                    <div className='dropdown-frame'>
                        <ul>
                            {
                                data.map((o, index) => (
                                    <li key={`adrItem${index}`} onMouseDown={(e) => {
                                        setError(null);
                                        setValue(o.value);
                                        setFocus(false);
                                    }}>
                                    {o.value}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                }
            </div>
            <Err msg={err} />
        </div>
    );
};

export default Suggest;