import { status } from 'skin/event/status';
import { message } from 'skin/event';

const click = (obj, ms, story, data, d, action) => {
    console.log('[Ячейки - Мастер] событие', obj);
    const fld = data.curData.values.fld
    const prd = fld.txtPrd + " " + fld.txtVal
    if (!fld.name) {
        message(`Выберите товар`, 'Ячейки');
        return status.ignore
    }
    if (!fld.txtPrd) {
        message(`Выберите продукт`, 'Ячейки');
        return status.ignore
    }
    if (!fld.value) {
        message(`Заполните количество`, 'Ячейки');
        return status.ignore
    }
    const row = {
        key: fld.name + '|' + fld.prd,
        prd: fld.prd,
        list: [fld.txtName, prd, fld.value, fld.comment]
    }
    const table = data.curData.values.table.product
    const i = table.list.findIndex(e => e.key === fld.name)
    if (i === -1) {
        data.addRow(ms, 'product', row)
    } else {        
        const a = [...table.list]
        a[i] = row
        table.list = a
    }
    
    data.setValue('name', '')
    data.setValue('txtName', '')
    data.setValue('prd', '')
    data.setValue('txtPrd', '')
    data.setValue('txtVal', '')
    data.setValue('value', '')
    data.setValue('comment', '')
	return status.action
};

export default click;