import { status } from 'skin/event/status'

const msSelectModAdm = (obj, ms, story, data, d, action) => {
    if (obj.action === 'setInput' && obj.name === 'multy') {
        let multy = obj.value
        const v = ms.s.fld.find(el => el.name === "value")
        v.disabled = !multy
        if (multy) {
            delete v.disabled
            ms.s.fld = [...ms.s.fld]
        } else {
            data.setValue('value', 1)
        }
    }

    return status.action
}

export default msSelectModAdm