import { status } from 'skin/event/status';
import dt from './data';

const parList = (obj, ms, story, data, d, action) => {
	console.log('[Цветовая схема - Мастер] событие', obj);
	switch (obj.type) {
		case 'data':
			return dt(obj, ms, story, data, d, action);
		default:
			return status.action;
	}
};

export default parList;
