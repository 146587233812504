import { status } from 'skin/event/status';
import { message } from 'skin/event';
import dt from 'skin/store/master/data'

const click = (obj, ms, story, data, d, action) => {
  console.log('[Ячейки - Мастер] событие', obj);
  const txtCell = story.curData.values.fld.txtCell
  const key = ms.current.table.msCell.row
  const txtRack = story.curData.values.fld.txtRack
  const cellId = story.curData.values.fld.cellId
  if (!key) {
    message(`Выбирете корзину`, 'Корзина');
    return status.ignore
  }
  // if(story.curData.values.fld.object === '') story.curData.values.fld.object = {}
  const o = story.curData.values.fld.object
  let k = 0
  for (let key in o) {
    k += o[key] === cellId ? 1 : 0
  }
  if (k) {
    message(`Ячейка уже выбрана`, 'Ячейки');
    return status.ignore
  }
  if (cellId === null) {
    message(`Выберите ячейку`, 'Ячейки');
    return status.ignore
  }
  o[key] = cellId
  dt.setCell('msCell', key, 1, txtRack, _ => txtRack)
  dt.setCell('msCell', key, 2, txtCell, _ => txtCell)
  return status.action
};

export default click;
