import { status } from 'skin/event/status';
import { kit } from 'skin/data/message';
import { message, space } from 'skin/event';

const add = (obj, form, story, data, d, action) => {
	console.log('[Комплектация - форма] событие', obj);
	switch (obj.table) {
		case 'pickCell':
            const aBtn = kit('yesno');
            const info = data.s.values.fld._id
            const  o = { 
                type: 'master',
                code: 'pickCell',
                data: {
                    code: 'pickCell',
                    info,
                },
            };
            aBtn[0].action = (_) => space(o, true);
            const m = ['Вы хотите добавить ячейку']
            message(m, 'Ячейки', aBtn, '?');
            return status.ignore
		default:
	}
	return status.action;
};

export default add;
