import './style.css'

const Title = ({name}) => {
	return (
		<div className='login-main-title'>
			<img className='login-main-title-img' src={'/task/img/login.svg'} alt="" />
			<p className='login-main-title-txt'>{name}</p>
		</div>
	);
};

export default Title;
