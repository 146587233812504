import Content from './content'
import './style.css'
import m from "skin/store/mb";
import { observer } from 'mobx-react-lite'

const Message = props => {
	const mb = m.isMobile;
	if (!props.data) return null
	let cls = [
		'skin-message',
		'message'
	]
	cls = cls.join(' ')
	if(mb)cls+=' mb-message'
	return (
		<div className={cls}>
			<div className='mess-shadow'></div>
			<Content data={props.data} />
		</div>
	)
}

export default Message
