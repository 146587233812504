import { status } from 'skin/event/status';
import dictionary from './dictionary';

const data = (obj, ms, story, data, d, action) => {
	console.log('[Сравнение отчётов - Мастер] событие', obj);
	switch (obj.action) {
		case 'dictionary':
			return dictionary(obj, ms, story, data, d, action);
		default:
			return status.action;
	}
};

export default data;
