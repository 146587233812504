import { Cnt } from 'skin/context';
import { useContext, useState } from 'react';
import Item from './item';
import { status } from 'skin/event/status';
import './style.css';
import list from 'skin/data/form/control'; //Базовый набор команд
import m from "skin/store/mb";
import { observer } from 'mobx-react-lite'
import Burger from 'skin/table/content/command/control/burger';
import View from 'skin/form/head/title/command/view';
import List from 'skin/form/head/title/command/list';

const Control = () => {
	const l = list ? list : [];
	const mb = m.isMobile;
	let cls = ['skin-frm-com-control', 'frm-com-control'];
	const { title, pages, current, pgCount, action } = useContext(Cnt);
	const [on, setOn] = useState(false);
	cls = cls.join(' ');
	if(mb) cls+=' mb-control'
	return (
		<div className={cls}>
			{!mb && l.map((el) =>  <Item key={el.id} data={el} click={click} />)}
			{mb && <View setOn={setOn}
				current={current}
				pages={pages}
				pgCount={pgCount}/>}
			<List on={on} setOn={setOn} current={current} pages={pages} />
			{mb && <>
			<div></div>
			<Burger all = {l} click = {click} />
			</> }
		</div>
	);

	function click(id) {
		const o = {
			type: 'control',
			action: id,
		};
		const act = action(o);
		if (act === status.ignore) return;
	}
};

export default observer(Control);
