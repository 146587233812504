import { status } from 'skin/event/status';
import btn from './btn';
import table from './table'
import dict from './data'

const msPrdWriteoff = (obj, ms, story, data, d, action) => {
    console.log('[Ячейки - Мастер] событие', obj);
    switch (obj.type) {
        case 'btn':
            return btn(obj, ms, story, data, d, action);
        case 'table':
            return table(obj, ms, story, data, d, action);
        case 'data':
            return dict(obj, ms, story, data, d, action);
        default:
            return status.action;
    }
};

export default msPrdWriteoff;
