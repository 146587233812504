import classList from 'skin/entry/signup/cmp/class_list';
import './block.css';

// Группы полей ввода информации;
const Block = ({ wide, content }) => {
    return (
        <section className={classList('form-block', !wide && 'form-block-mobile')} >
            { content }
        </section>
    );
};

export default Block;