import { status } from 'skin/event/status';
import api from 'skin/http';
import { space, message } from 'skin/event';

const category = (obj, form, story, data, d, action) => {
	const id = story.current.info[0]
	const config = {
		method: 'get',
		url: 'api/other/form/goods/check/category/'+id,
		
	};
	api(config)
		.then(res => {
			if (!res.data.ok) {
				message(['Добавление подчиненной категории невозможно.',' В данной категории допускается создание только товаров, либо только категорий '], 'Категории и товары')
				return status.ignore;
			}
			const o = {
				type: 'master',
				title: 'Добавление категории',
				data: {
					code: 'msCtgEx',
					info: story.current.info ?? [],
					type: 'table',
				},
			};
			return space(o, true);
		})
		.catch(_ => message(`Добавление произошло с ошибками .\n Обратитесь к администратору`, 'Категории и товары'));
	return status.ignore;
};

export default category;
