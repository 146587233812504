import Block from 'skin/entry/signup/cmp/block';
import classList from 'skin/entry/signup/cmp/class_list';
import './body.css';

// Компонент - основной контейнер, где располагаются компоненты
// для заполнения информации;
const Body = ({ wide, blocks }) => {
    return (
        <main className={classList('form-body', !wide && 'form-body-mobile')}>
            {blocks.map(((o, i) => <Block wide={wide} key={`block-${i}`} content={o} />))}
        </main>
    );
};

export default Body;