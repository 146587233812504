import { message } from 'skin/event';
import { status } from 'skin/event/status';

const click = (obj, ms, story, data, d, action) => {
	const prdVal = +data.curData.values.fld.prdVal || ''
	const productId = data.curData.values.fld.productId
	const key = story.s.msPack.table.propItem.row
	if (!key) {
		const msg = productId ? `Выберите модификацию товара из предложенных ниже` : 'Выберите получаемый продукт'
		message(msg, 'Запись количества товара')
	}
	data.setCell('propItem', key, 1, prdVal)
	// data.setValue('prdVal', '')
	// data.setValue('txtMod', '')
	return status.action
}

export default click