import { status } from 'skin/event/status';
import cPart from './c_part'
import category from './category'

const dict = (obj, ms, story, data, d, action) => {
	switch (obj.code) {
		case 'cPart':
			return cPart(obj, ms, story, data, d, action);
		case 'category':
			return category(obj, ms, story, data, d, action);
		default:
			return status.action;
	}
};

export default dict;
