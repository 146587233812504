import { status } from 'skin/event/status';

const next = (obj, table, story, d, action) => {
	// if (obj.id === "638ef2b8702f822632a5228e") {
	// 	console.log('[Отчет - Мастер] событие', obj);
	// 	const o = {
	// 		name: 'Список весовых товаров',
	// 		pug: true
	// 	}
	// 	const act = {
	// 		type: 'data',
	// 		action: 'wAdd',
	// 		code: 'makeProd',
	// 		data: o
	// 	}
	// 	action(act)
	// 	return status.ignore
	// }
	return status.action
};

export default next;
