import col from 'skin/data/table/col'

//
function stlCol(o, type) {
	type = type ?? false
	const st = {}
	if (!o) return st
	let w = o.width ?? ''
	let t = o.type ?? ''
	if (w === '' && col[t]?.width) w = col[t].width
	if (w !== '') {
		st.width = w
		st.paddingLeft = 0
		st.paddingRight = 0
	}
	if (type && col[t]?.align) st.textAlign = col[t]?.align
	return st
}

export {stlCol}
