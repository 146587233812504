import { status } from 'skin/event/status';
import click from './click';

const act = (obj, table, story, d, action) => {
	console.log('[Ячейки - Мастер] событие', obj);
	switch (obj.action) {
		case 'click':
			return click(obj, table, story, d, action);
		default:
	}
	return status.action;
};

export default act;
