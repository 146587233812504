import { status } from 'skin/event/status';
import request from '../fn';

const outline = (obj, ms, story, data, d, action) => {
	const hex = obj.value
	request(hex)
	.then(r => {
		const {light, dark} = r
		data.setValue('txtOutline', light.color)
		data.setValue('outlineVariant', light.onColor)
		data.setValue('darlOutline', dark.color)
		data.setValue('darkOutlineVar', dark.onColor)
	})
	.catch(err => console.log('err', err))
	return status.action
};

export default outline;
