import { status } from 'skin/event/status'

const bidList = (obj, form, story, data, d, action) => {
	switch (obj.table) {
		case 'bidList':
			return status.action
		default:
	}
	return status.ignore
}

export default bidList
