import { status } from 'skin/event/status';
import act from './action';
import forma from './form';
import dt from './data';

const prod = (obj, form, story, data, d, action) => {
	console.log('[Продукт - форма] событие', obj);
	switch (obj.type) {
		case 'action':
			return act(obj, form, story, data, d, action);
		case 'form':
			return forma(obj, form, story, data, d, action);
		case 'data':
			return dt(obj, form, story, data, d, action);
		default:
			return status.action;
	}
};

export default prod;
