import { useEffect, useState } from "react";

const useSuggest = (value, fn, frequence=1000) => {
    const [focus, setFocus] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (value?.length <= 0) {
            setData([]);
            return;
        };
        const interval = setTimeout(() => fn(value, setData), frequence);
        return () => clearTimeout(interval);
    }, [value])

    return {focus, setFocus, data, setData};
}

export default useSuggest;