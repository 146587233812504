import { status } from 'skin/event/status'
// import { message } from 'skin/event'
import dataUrl from '../../../../../../config_task'

const click = (obj, ms, story, data, d, action) => {
	// const code = data.curData.values.fld.code
	const href = dataUrl.url + `/file/ctg_example.xlsx`
	const link = document.createElement('a')
	link.href = href
	link.download = `ctg_example.xlsx`
	link.click()
	return status.action
}

export default click
