import Win from './win';
// import Links from './links';
import './style.css';
// import Wait from 'skin/space/wait';
// import Shadow from 'skin/space/shadow';
// import Dict from 'skin/dict';
import Notify from 'skin/cmp/notify';
// import data from '../../config_task';

import Header from 'skin/cmp/header';

const Login = (props) => {
	return (
		<div
			className='login'
			style={{ backgroundImage: 'url(/task/img/main.png)' }}>
			<Header />
			<Win />
			{/* <div className='login-dict'>
				<Notify data={props.data} />
			</div> */}
		</div>
	)
}

// const Login = (props) => {
// 	let cls = ['skin-login', 'login-box'];
// 	cls = cls.join(' ');
// 	const stl = {
// 		backgroundImage: 'url(/task/img/pattern.png)',
// 		backgroundSize: 'cover',
// 	};
// 	const stlf1 = {
// 		backgroundImage: 'url(/task/img/flet.png)',
// 		backgroundSize: 'cover',
// 	};
// 	const stlf2 = {
// 		backgroundImage: 'url(/task/img/f.png)',
// 		backgroundSize: 'contain',
// 		backgroundRepeat: 'no-repeat',
// 	};

// 	const phone = 'tel' + data.tech.phone;
// 	return (
// 		<div className={cls} style={stl}>
// 			<div className="shadow"></div>
// 			<div className="flet" style={stlf1}></div>
// 			<div className="f" style={stlf2}></div>
// 			<div className="container">
// 				<Win />
// 				<div className="phone">
// 					<a href={phone}>{data.tech.phoneText}</a>
// 				</div>
// 				<Links />
// 			</div>
// 			<Notify data={props.data} />
// 		</div>
// 	);
// };

export default Login;
