import { status } from 'skin/event/status'

const table = (obj, form, story, data, d, action) => {
	switch (obj.action) {
		case 'row': return status.ignore
		default:
	}
	return status.action
}

export default table