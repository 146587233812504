import check from "./query/check";
import submit from "./query/submit";

// Время между запросами кодов через смс;
export const cooldown = 60;

// Запрос на отправку смс с новым кодом;
export const requestCode = (phone, setTimer, setError) => {
   
    setError(null);
	check(phone)
	.then(_ => {
		setTimer(cooldown)
	})
	.catch(err => {
		console.log('err', err)
	})
	setTimer(50);
    // Отправить запрос на получение смс;
    // requestSms(phone);
};

// Отправка введённого кода на сервер и проверка на правильность;
export const checkCode = (code, setPending, setSubmit, setError, phone, setTimer) => {
	//value, setPending, setSubmit, setError, phoneError, setPhoneError, phone, setTimer
    setPending(true);
    // Заменить на fetch;
	submit(phone, code)
	.then(r => {
		if (!r.data.error) setSubmit(true)
		setError(r.data.error)
	})
	.catch(err => {
		console.log('err', err)
		setError(err.toString());
	})
	.finally(_ => {
		setPending(false)
		setTimer(0)
	})
    // setTimeout(() => {
    //     if (code === '1234') {
    //         setSubmit(true);
    //     } else {
    //         setError('Неверный код.');
    //     }
    //     setPending(false);
    // }, 2000)
};