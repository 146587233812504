import './options.css';
import { useRef } from 'react';

import Button from 'skin/entry/signup/cmp/button';
import Err from 'skin/entry/signup/cmp/err';
import useOption from './use_options';

const Option = ({err, setError, title, value, setValue, onChange, placeholder, button, img, dict}) => {
    const {focus, setFocus} = useOption();
    const ref = useRef(null);

    const onFocus = (e) => {
        setFocus(true);
    };
    const onBlur = (e) => {
        setFocus(false);
    };

    const show = focus;

    return (
        <div className='options-container'>
            <span className='options-title'>{ title }</span>
            <div style={show ? {
                borderRadius: '16px 16px 0 0',
            } : {}} className='options-input'>
                <div className='inline-option-input' onClick={() => {ref.current && ref.current.focus()}}>
                    <input
                        ref={ref}
                        readOnly={true}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        value={value}
                        onChange={(e) => {
                            onChange(e);
                        }}
                        placeholder={placeholder}
                    />
                    <Button iref={ref} title={button.label} type={'reverse'} img={button.img} onClick={() => setFocus(true)} />
                    {img && <div className='img-container'><img src={img} alt={'img'}/></div>}
                </div>
                { show &&
                    <div className='dropdown-frame'>
                        <ul>
                            {
                                dict.map((o, index) => (
                                    <li key={`adrItem${index}`} onMouseDown={(e) => {
                                        setError(null);
                                        setValue(o);
                                        setFocus(false);
                                    }}>
                                    {o}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                }
            </div>
            <Err msg={err} />
        </div>
    );
};

export default Option;