import m from "skin/store/mb";
import Button from "./button";

//Закрытие диалогового окна
export default function Exit({close, off = false}) {
	const mb = m.isMobile;
	const icon = {name: 'url(skin/img/close.svg)', color: 'red', cls: 'cmp-dialog-close'}
	const iconBack = {name:"url(skin/img/mn_up.svg)", color:"def", cls: 'cmp-dialog-back'};
	if (mb & !off) return <Button obj={iconBack} close={close}/>
	return <Button obj={icon} close={close}/>
}