import './button.css';

import classList from 'skin/entry/signup/cmp/class_list.js';

const disabledStyles = {
    reverse: 'disabledReverseBtn',
};

// Компонент - кнопка;
const Button = ({ title, isError=false, disabled=false, type='normal', img, onClick, iref }) => {
    const image = img && <div className='alt-img-container'><img src={img} alt={'btnimg'}/></div>;
    return (
        <a className={classList(`${type}Btn`, disabled && (disabledStyles[type] || 'disabledBtn'), isError && 'errorBtn')} onClick={disabled ? (() => {}) : (_ => {
            if (iref && iref.current) iref.current.focus();
            onClick();
        })}>
            {type === 'reverse' && image}
            <span>{title}</span>
            {type === 'alt' && image}
        </a>
    );
};

export default Button;