import { makeAutoObservable } from 'mobx';

class Mb {
  width = window.innerWidth;
  isMobile = window.innerWidth < 1000;

  constructor() {
    makeAutoObservable(this);

    window.addEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    this.width = window.innerWidth;
    this.isMobile = this.width < 1000;
  }
}

export default new Mb();