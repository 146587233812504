import Empty from 'skin/cmp/empty';
import Head from './head';
import Body from './body';
import './style.css';
import Command from './content/command';
import { observer } from 'mobx-react-lite';
import m from "skin/store/mb";
import Items from 'skin/table/content/main/body/items';

const Table = ({ data, action, v, story }) => {
	if (!data) return <Empty type={'table'} />;
	let cls = ['skin-cmp-table', 'cmp-table'];
	if (data?.border) cls.push('border');
	cls = cls.join(' ');

	const title = data?.title ? (
		<div className="title">
			{data?.title} 
		</div>
	) : null;

	//Кол-во страниц для таблицы. Берем из данных
	const pages = v?.page ? v.page : 1;

	const mb = m.isMobile;
	// текущая страница из story
	const page = story?.page ?? 1;
	// текущая строка из story
	const current = story?.row ?? '';
	const w = data.width ?? '100%'
	const mW = data.minWidth ?? '600px'
	const cols = {};
	data.col?.map((el,i) => {
		cols[i] = {ttl: el.title, type: el.type};
	})

	if(mb) cls+=' mb-table';
	return (
		<div className={cls} style={{width:w}}>
			{title}
			<Command
				data={data}
				page={page}
				pages={pages}
				setPage={setPage}
				action={action}
				mb={mb}
			/>
			<div style={{overflow: !mb ? 'auto' : ''}}>
			{!mb && 
			<>
				<Head col={data.col ?? []} minWidth={mW} />
				<Body
					data={v}
					def={data.col}
					minWidth={mW}
					current={current}
					rowClick={rowClick}
					action={action}
				/>
			</>
			}
			{mb && <Items body = {v.list} cols = {cols} click={rowClick} />}
			</div>
		</div>
	);

	// Кликнули на строке таблицы
	function rowClick(key) {
		const i = v.list.find((e) => e.key === key);
		const o = {
			type: 'table',
			action: 'row',
			key: key,
			table: data.code,
			form: {
				code: data.form.edit.code ?? data.code,
				title: data.form.edit.title,
			},
			info: i.info,
		};
		action(o);
	}

	function setPage(a, v) {
		const o = {
			type: 'page',
			action: a,
			table: data.code,
		};
		if (v) o.page = v;

		action(o);
	}
};

export default observer(Table);
