import { useEffect, useRef } from 'react'
import styles from './styles.module.css'
import './style.css'
import m from 'skin/store/mb';
import { observer } from 'mobx-react-lite';
import Burger from './burger';

const Header = () => {
    const mb = m.isMobile;
    const bg = useRef(null)
    // function hdlScroll() {
    //     const height = bg.current?.offsetHeight * 2
    //     bg.current.style.opacity = window.scrollY < height ? window.scrollY / height - 0.2 : 0.8
    // }

    // useEffect(_ => {
    //     window.addEventListener('scroll', hdlScroll)
    //     return _ => window.removeEventListener('scroll', hdlScroll)
    // }, [])

    return (
        <header className='login-header'>
            <div className='login-header-content'>
                <a className='login-header-content-left' href='https://flet.su'>
                    <img
                        className='login-header-logo'
                        src={'/task/img/logo_h.svg'}
                        alt='logo'
                    />
                </a>
                {!mb && 
                <nav className='login-header-content-right'>
                    <div className='login-header-content-nav'>
                        <ul className='login-header-content-nav-list'>
                            {data.map(item =>
                                <li key={item.id} className='login-header-content-nav-item'>
                                    <a
                                        className='login-header-content-nav-item-content'
                                        href={'https://flet.su' + item.href}
                                    >
                                        {item.title}
                                    </a>
                                </li>
                            )}
                        </ul>
                    </div>
                </nav>
                }
                {mb && <Burger data = {data} />}
                
            </div>
        </header>
    )
}

export default observer(Header)


const data = [
    {
        id: 'about',
        title: 'о продукте',
        href: '/#about',
    },
    {
        id: 'tariff',
        title: 'тарифы',
        href: '/#tariff',
    },
    {
        id: 'case',
        title: 'кейсы',
        href: '/#case',
    },
    {
        id: 'instruction',
        title: 'инструкции',
        href: '/#instruction',
    },
    {
        id: 'market',
        title: 'маркетинг',
        href: '/marketing'
    },
    {
        id: 'contact',
        title: 'контакты',
        href: '/contact'
    }
]