import {message} from 'skin/event'
import def from 'skin/data/message'
import './style.css'

const Title = props => {
	let cls = [
		'skin-mess-cnt-title',
		'mess-cnt-title'
	]
	cls = cls.join(' ')
	let type = props.data.type ?? 'i'
	if (def.type[type]) type = def.type[type]
	const title = props.data.title ?? 'Информация'
	const st = {backgroundImage: `url(skin/img/mess_t_${type}.svg)`, minWidth: '33px', minHeight: '33px', backgroundRepeat:"no-repeat", backgroundSize:'30px'}
	const st2 = {backgroundImage: 'url(skin/img/close.svg)'}
	return (
		<div className={cls}>
			<div style={{display:'flex', alignItems:'center', gap:'20px'}}>
				<span style = {st}></span>
				<p>{title}</p>
			</div>
			<span className='mess-cnt-close' style={st2} onClick={close}></span>
		</div>
	)

	function close() {
		message()
	}
}

export default Title
