import { status } from 'skin/event/status';
const row = (obj, form, story, data, d, action) => {
    switch (obj.table) {
        case 'modTar': {
            const cur = story.current.table.modTar.row
            if (obj.key === cur) return status.ignore
			return status.action
        }
        default: return status.action
    }
}

export default row