import { useEffect, useState } from 'react';
import Error from '../error';
import './style.css';
import cnfTask from 'config_task';
import user from 'skin/store/user';

const InputImg = ({ s, v, e, setValue }) => {
	const {
		_id,
		size = 5, //по умолчанию max size = 1мб
		name,
		title = '',
		disabled = false,
		scale = false,
		width = '100px',
		height = '100px',
		def,
		style = {},
	} = s;
	// Дефолтные картинки и преобразование размера в байты
	const imgU = def ? def : 'skin/img/ImgUpload.png';
	const max = +size * 1048576;

	// Значение
	const [val, setVal] = useState();

	// Массив ошибок - получаем пропсами из mobx
	const [err, setErr] = useState();
	// Индикатор Показывали ошибки или нет
	const [status, setStatus] = useState(false);

	// Превью изображения
	const [file, setFile] = useState();
	const [picked, setPicked] = useState(false);

	useEffect(() => {
		if (picked) {
			// Показываем выбранную картинку
			let reader = new FileReader();
			reader.onloadend = () => {
				setVal(reader.result);
			};
			reader.readAsDataURL(file);
			setPicked(false);
		}
	}, [picked, file, name]);

	useEffect(() => {
		setErr(e);
	}, [e]);

	useEffect(() => {
		if (typeof v !== 'string') setVal('');
		else if (v && v !== imgU && v !== 'new') {
			if (v.startsWith('https')) setVal(v);
			else setVal(`${cnfTask.url}company/${user.code}/img/${v}`);
		} else {
			setVal(imgU);
		}
	}, [v, imgU]);

	let cls = ['skin-input-img', 'input-img'];
	if (err) cls.push('error');
	cls = cls.join(' ');

	const stl = {
		backgroundImage: 'url("skin/img/com_edit.svg")',
	};
	const stlD = {
		backgroundImage: 'url("skin/img/com_delete.svg")',
	};
	style.height = height;
	style.width = width;

	const scl = scale ? 'scale' : 'not-scale';

	return (
		<div className={cls} style={style} onClick={onFocus}>
			{s?.label ? <div className="title">{s?.label}</div> : null}

			<img
				className={scl}
				name={name}
				src={val}
				alt={s?.label}
				onError={() => setVal(imgU)}
				onFocus={onFocus}
				title={title}
			/>
			{!disabled ? (
				<span
					className="del ico"
					onClick={del}
					style={stlD}
					disabled={disabled}
				></span>
			) : null}
			{!disabled ? (
				<label style={stl} onChange={onChange}>
					<input id={_id} type="file" name={name} />
				</label>
			) : null}
			{/* Ошибки */}
			{status ? (
				<Error err={err} onClick={() => setStatus(false)} />
			) : null}
		</div>
	);

	function onChange(e) {
		e.preventDefault();
		setStatus(false);
		setErr(null);

		const { files } = e.target;
		if (files && files.length > 0) {
			if (files[0].size > max) {
				setErr(`Размер изображение превышает ${size}мб`);
				setStatus(true);

				setValue(name, null);
				return;
			}

			let fd = new FormData();
			fd.append(name, files[0]);
			setValue(name, fd);

			// Превью изображения
			setPicked(true);
			setFile(files[0]);
		}
	}

	// Удаление
	function del() {
		setValue(name, '');

		setVal(imgU);
		setStatus(false);
		setErr(null);
	}

	// Показываем список ошибок
	function onFocus(e) {
		if (err && !status) {
			setStatus(true);
		}
	}
};

export default InputImg;
