import { useRef, useEffect } from "react"
import user from 'skin/store/user';
import { observer } from "mobx-react-lite";
import Item from "../../item";

const ListForm = ({onClose, all, click}) => {
    const ref = useRef();


    useEffect(() => {
		user.get();
	}, []);

    useEffect(() => {
		const click = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				onClose();
			}
		};
		setTimeout(() => { document.addEventListener('click', click); });
	  
		return () =>  document.removeEventListener('click', click);
	
	}, []);

    return ( 
        <div ref={ref} className= "skin-header-burger-drop">
			{all.map((el) => 
			<div key = {el.id} className="skin-header-burger-drop-user">
				<Item key = {el.id} data={el} click = {click} />
			</div>)}
        </div>
    )	
}



export default observer(ListForm);