import { type } from "skin/event";
import api from "skin/http";
import validateAll from "../validation/all";


//Регистрация компании
function signup(data, setError) {
	const f = validateAll(data, setError)
	if (!f) return 
	api.post('api/auth/signup', data)
	.then((res) => {
		alert(
			'Учетная запись создана!\nДля продолжения авторизируйтесь.'
		);
		setTimeout(() => type('login'), 500);
	})
	.catch((e) => {
		
		console.log('Error ', e.response?.data);
		let msg = e.response?.data?.message;
		console.log(e);
		if (!msg) return;
		// if (!Array.isArray(msg)) msg = [msg];
		// setTimeout(() => setMes(msg), 1000);
	});
}

export default signup