import { status } from 'skin/event/status';
import row from './row';

const media = (obj, table, story, d, action) => {
	console.log('[Статистика - Таблица] событие', obj);
	switch (obj.action) {
		case 'row':
			return row(obj, table, story, d, action);

		default:
			return status.action;
	}
};

export default media;
