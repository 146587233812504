import Field from 'skin/entry/signup/cmp/field';
import iPhone from 'skin/entry/signup/images/phone.svg';
import Code from "../code/code";
import { requestCode } from '../code/code_check.js';
import parseNumber from './parse_number.js';
import './phone.css';
import usePhone from './use_phone.js';

// Компонент для получения и проверки номера телефона пользователя;
const Phone = ({ title, value, setValue, err, setError, ...props }) => {
    const { phone, setPhone, submit, setSubmit, timer, setTimer, codeErr, setCodeError } = usePhone(value, setValue);
    // Значение поля ввода без учёта маски;
    const phoneNumber = parseNumber(phone);
    // Нужно ли показывать кнопку "Подтвердить", которая показывает
    // поле кода и высылает смс;
    const showSubmitBtn = timer === -1 && !submit && phoneNumber.length === 11;
    return (
        <div className='phone-block'>
            <Field
                type={'base'}
                err={err}
                setError={setError}
                mask={"+7 (\\999) 999 99-99"}
                title={title}
                value={phone}
                setValue={setPhone}
                placeholder={'+7 (9__) ____ __-__'}
                img={!showSubmitBtn && iPhone}
                button={
                    showSubmitBtn &&
                    {
                        title: 'Подтвердить',
                        type: 'reverse',
                        img: iPhone,
                        onClick: _ => requestCode(phoneNumber, setTimer, setError)
                    }
                }
            />
            {timer !== -1 && <span className="phone-hint">На указанный номер отправлено СМС с кодом</span>}
            {timer >= 0 && <Code phone={phoneNumber} timer={timer} setTimer={setTimer} submit={submit} setSubmit={setSubmit} {...props} err={codeErr} phoneError={err} setPhoneError={setError} setError={setCodeError} />}
        </div>
    );
};

export default Phone;