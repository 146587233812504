import { status } from "skin/event/status";
import print from "./fn/print";
import make from "./fn/make";
import mCode from "./fn/m_code";
import fact from "./fn/fact";
import barcode from "./fn/barcode";

const add = (obj, form, story, data, d, action) => {
    switch (obj.id) {
        // просмотр товара в моб. приложении
        case "638f28074db1752b846163ae":
            return print(story, action);
        // просмотр этикетки штучного товара
        case "649eccd57f4b0c1d1508937f":
            return barcode(story, data, action);

        case "63ee230f32446f03a52a28b9":
            return print(story, action);
        // Присвоить код весовому товару
        // case '638f29874db1752b846163b0':
        case "63ff380788c433a57c0f3a4d":
            return make(form, story, action);
        // Сгенерировать штрихкод товара
        // case '6390435a1d52cb32e7262217':
        case "63ff387688c433a57c0f3a4f":
            return mCode(form, story, action);
        // Вес по факту
        case "63ff385e88c433a57c0f3a4e":
            return fact(form, story, action);
        default:
            return status.action;
    }
};

export default add;
