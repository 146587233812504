import { status } from 'skin/event/status'

const next = (obj, ms, story, data, d, action) => {
    console.log('[Отчет - Мастер] событие', obj);
    const f = data.getAll(ms)
    const o = {
        name: 'Синхронизация с 1С',
        pug: true,
        in: {
            fnc: {
                party: f.all.party,
                group: f.all.group,
                provider: f.all.provider,
                commodity: f.all.commodity,
                arrival: f.all.arrival,
                sale: f.all.sale
            },
            date: {
                sale: {
                    from: f.date.sFrom,
                    to: f.date.sTo
                }
            }
        }
    }
    const act = {
        type: 'data',
        action: 'wAdd',
        code: 'syncTo',
        data: o
    }
    action(act)
    return status.action
}

export default next