import { status } from 'skin/event/status';
import all from './b_all';
import one from './b_one';
import cancelAll from './b_cancel_all'

const click = (obj, ms, story, data, d, action) => {
    switch (obj.code) {
        case 'btnAll':
            return all(obj, ms, story, data, d, action);
        case 'cancelAll':
            return cancelAll(obj, ms, story, data, d, action);
        case 'btnOne':
            return one(obj, ms, story, data, d, action);
        default:
            return status.action;
    }
};

export default click;
