import { status } from 'skin/event/status'
import { message } from 'skin/event'

const click = (obj, ms, story, data, d, action) => {
	console.log('[Ячейки - Мастер] событие', obj);
	const fld = data.curData.values.fld
	if (!fld.item) {
		message('Выберите свойство', 'Ячейки')
		return status.ignore
	}
	const row = {
        key: fld.prop + '|' +  fld.item,
        name: fld.prop + '|' + fld.item,
        list: [fld.txtProp + ' ' + fld.txtItem]
    }
	const table = data.curData.values.table.propT
	const i = table.list.findIndex(el => el.key.split('|')[1] === fld.prop.split('|')[1])
    if (i === -1) data.addRow(ms, 'propT', row)
    else {
        const a = [...table.list]
        a[i] = row
        table.list = a
    }
    table.list = table.list.sort((a, b) => {
        if (a.key.split('|')[0] < b.key.split('|')[0]) return -1
        return 1
    })
    data.setValue('item', '')
    data.setValue('prop', '')
    data.setValue('txtProp', '')
    data.setValue('txtItem', '')
	return status.action
}

export default click