import mode from 'skin/data/mode';

const Left = props => {
    const md = props.mode ?? 'menu'
    let st = mode[md]?.img ?? ''
	st = { backgroundImage: `url(skin/img/mode_${st}.svg)` };
	const name = mode[md]?.name ?? ''

    return (
            <button className="btn-i info">
                <span style={st}></span>
                <span className='mode'>{name}</span>
            </button>
    )
    
}

export default Left