import { status } from 'skin/event/status';
import request from '../fn';

const secondary = (obj, ms, story, data, d, action) => {
	const hex = obj.value
	request(hex)
	.then(r => {
		const {light, dark} = r
		data.setValue('txtSecondary', light.color)
		data.setValue('onSecondary', light.onColor)
		data.setValue('secondaryContainer', light.colorContainer)
		data.setValue('onSecondaryContainer', light.onColorContainer)
		data.setValue('darkSecond', dark.color)
		data.setValue('darkOnSecond', dark.onColor)
		data.setValue('darkSecondCont', dark.colorContainer)
		data.setValue('darkOnSecondCont', dark.onColorContainer)
	})
	.catch(err => console.log('err', err))
	return status.action
};

export default secondary;
