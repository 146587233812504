import { status } from 'skin/event/status';
import { message } from 'skin/event';

const add = (obj) => {
	if (!obj.table)	{
		message('Добавление не активно', 'О приложении', null, '!');
		return status.ignore
	}
	return status.action
};

export default add;
