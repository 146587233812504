import { status } from 'skin/event/status';
import сhooseAll from './choose_all'
import add from './add'

const click = (obj, ms, story, data, d, action) => {
	switch (obj.code) {
		case 'сhooseAll':
			return сhooseAll(obj, ms, story, data, d, action);
		case 'add':
			return add(obj, ms, story, data, d, action);
		default:
			return status.action;
	}
};

export default click;
