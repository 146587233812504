import { status } from 'skin/event/status'
import click from './click'

const btn = (obj, ms, story, data, d, action) => {
	switch (obj.action) {
		case 'click':
			return click(obj, ms, story, data, d, action)
		default:
			return status.action
	}
}

export default btn
