import { useRef, useEffect } from "react"
import user from 'skin/store/user';
import { observer } from "mobx-react-lite";
import Item from "../../item";
import {useContext} from 'react'
import {Cnt} from 'skin/context'

const List = ({onClose, all, click}) => {
    const ref = useRef();
	
	const {action} = useContext(Cnt)
    useEffect(() => {
		user.get();
	}, []);

    useEffect(() => {
		const click = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				onClose();
			}
		};
		setTimeout(() => { document.addEventListener('click', click); });
	  
		return () =>  document.removeEventListener('click', click);
	
	}, []);

    return ( 
        <div ref={ref} className= "skin-header-burger-drop">
			{all.map((el) => 
			<div key = {el.id} className="skin-header-burger-drop-user">
				<Item key = {el.id} data={el} click = {click} />
			</div>)}
			<div className="skin-header-burger-drop-user">
				<Item data = {{title: "Очистить фильтры", id: "clear"}} click = {_ => clear()} />
			</div>
			
        </div>
    )
	
	function clear() {
		const o = {
			type: 'filter',
			action: 'clear'
		}
		action(o)
	}
	
}



export default observer(List);