import { status } from "skin/event/status";
import { message } from 'skin/event';
//Просмотр карточки товара в мобильном приложении
function barcode(story, data, action) {
    const productId = story.current.info[0];
    if (data.s.values.fld.make === "нет") {
		// штучный товар
		const o = {
			productId,
		};
		const oo = {
			type: "form",
			code: "mProdBrcd",
			action: "print",
			params: o,
		};
        action(oo);
    } else {
		// нештучный товар
        const o = {
			type: 'action',
			action: 'close'
		}
		action(o)	
        message(`Просмотр недоступен (Нештучный товар)`, "Этикетка");
    }

    return status.ignore;
}

export default barcode;
