import { makeAutoObservable } from 'mobx';
import def from './def';
import goods from './goods';
import mGoods from './m_goods';
import mediaVk from './media';
import mediaOk from './media';
import userEmp from './user_emp';
import pickup from './pickup';
import priceMod from './price_mod';
import modules from './module';
import payment from './payment';

class Data {
	s = {
		def,
		goods,
		mGoods,
		mediaVk,
		mediaOk,
		userEmp,
		pickup,
		priceMod,
		modules,
		payment
	};
	constructor(props) {
		makeAutoObservable(this);
	}
}

const data = new Data();
export default data;
