import { status } from 'skin/event/status';
import table from './table';
import act from './action';
import control from './control';
import forma from './frm';

const mGoods = (obj, form, story, data, d, action) => {
	console.log('[Категория - форма] событие', obj);
	switch (obj.type) {
		case 'table':
			return table(obj, form, story, data, d, action);
		case 'action':
			return act(obj, form, story, data, d, action);
		case 'control':
			return control(obj, form, story, data, d, action);
		case 'form':
			return forma(obj, form, story, data, d, action);
		default:
	}
	return status.action;
};

export default mGoods;
