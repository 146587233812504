import { status } from 'skin/event/status'
import chkAll from './chk_all'

const click = (obj, ms, story, data, d, action) => {
	switch (obj.code) {
		case 'chkAll':
			return chkAll(obj, ms, story, data, d, action);
		default:
			return status.action;
	}
};

export default click;