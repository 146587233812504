import { status } from 'skin/event/status';
import api from 'skin/http';

const add = (obj, ms, story, data, d, action) => {
	const ctg = data.curData.values.fld.categoryId
	const list = data.curData.values.table.prod.list
	const partnerId = data.curData.values.fld.partnerId
	if (!ctg || !list.length || !partnerId) return status.ignore
	const config = {
		method: 'post',
		url: 'api/other/master/parList/add',
		data: {partnerId, ctg, list}
	};
	api(config)
		.then(res => {
			data.delList('prod')
			data.setValue('categoryId', '')
			data.setValue('cPartId', '')
			data.setValue('txtCtg', '')
			data.setValue('txtPart', '')
		})
		.catch(console.log);
	return status.action;
};

export default add;
