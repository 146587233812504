import { status } from 'skin/event/status';

const frm = (obj, form, story, data, d, action) => {
	if (obj.action === 'reload' && obj.table === 'bidList') {
		const d = {
			type: 'form',
			action: 'reload'
		}
		action(d)
		return status.ignore
	}
		return status.action;
};

export default frm;