import { status } from 'skin/event/status';
import table from './table';
import btn from './btn'
import control from './control'

const mod = (obj, ms, story, data, d, action) => {
	console.log('[Ячейки - Мастер] событие', obj);
	switch (obj.type) {
		case 'table': return table(obj, ms, story, data, d, action)
		case 'btn': return btn(obj, ms, story, data, d, action)
		case 'control': return control(obj, ms, story, data, d, action)
		default:
			return status.action
	}
}

export default mod