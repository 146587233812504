import { useEffect, useRef } from 'react';

//Состояние компонента диалог
export default function useDialog(show, miss) {
	const dialogRef = useRef();
	const close = _ => {
		console.log('close dialog')
		dialogRef.current.close()
	}
	useEffect(() => {
		if (!show) return
		document.body.classList.add('noscroll')
		if (dialogRef.current) dialogRef.current.showModal()
		const onClick = e => {
	if (!dialogRef.current) return 
			if (e.target.closest('.cmp-dialog-header')) return
			if (e.target.closest('.cmp-dialog-body')) return
			console.log('miss click')
			if (!miss) dialogRef.current.close()
		}
		const root = document.getElementById('root')
		root.addEventListener('click', onClick)
		return _ =>{
			root.removeEventListener('click', onClick)
		    document.body.classList.remove('noscroll')}
	}, [show, miss])
	
	const pl = navigator.userAgent.match(/iPhone|iPad|iPod/i) ? 'ios' : 'android'
	return {dialogRef, close, pl}
}