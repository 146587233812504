import user from 'skin/store/user';
import { status } from 'skin/event/status';

const finish = (obj, ms, story, data, d, action) => {
	const prd = user.product + 1;
	user.chPrd(prd);
	return status.action;
};

export default finish;
