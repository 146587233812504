import { status } from 'skin/event/status';
import { message } from 'skin/event';
import user from 'skin/store/user';

const setInput = (obj, form, story, data, d, action) => {
	const partnerId = data.s.values.fld.partnerId
	if(partnerId && user.code === 'grilan') {
		switch (obj.name) {
			case 'disable':
				return status.action
			case 'ord':
				return status.action
			default:
				message(`Редактирование партнерского товара не активно`, 'Продукты');
				return status.ignore
		}
	}
	else {
		switch (obj.name) {
			case 'disable':{
				message(`Редактирование партнерки не активно`, 'Продукты');
				return status.ignore
			}
			case 'ord':{
				message(`Редактирование партнерки не активно`, 'Продукты');
				return status.ignore
			}
			default:
				return status.action
		}
	}
};

export default setInput;
