import { status } from 'skin/event/status';
import table from './table';
import btn from './btn'
import dt from './data';

const arrToRem = (obj, ms, story, data, d, action) => {
	console.log('[Ячейки - Мастер] событие', obj);
	switch (obj.type) {
		case 'data':
			return dt(obj, ms, story, data, d, action)
		case 'table':
			return table(obj, d, action, ms, story, data);
		case 'btn':
			return btn(obj, ms, story, data, d, action);
		default:
			return status.action;
	}
};

export default arrToRem;
