import { status } from 'skin/event/status';
import { toJS } from 'mobx';
import { message } from 'skin/event';

function check(obj, form, story, data, d, action) {
	const type = toJS(data).s.values.fld['owner.type'];
	if (type === 'company') return status.action;
	message(
		'Работа с группами в системном отчёте не доступна',
		'Группы',
		null,
		'!'
	);
	return status.ignore;
}

export default check;
