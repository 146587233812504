// const config = {
// 	extraPlugins: 'justify,editorplaceholder,colorbutton,font',
// 	editorplaceholder: '...',
// 	toolbar: [
// 		{
// 			name: 'clipboard',
// 			items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'],
// 		},
// 		{ name: 'editing', items: ['Scayt'] },
// 		{ name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
// 		{ name: 'insert', items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar'] },
// 		{ name: 'tools', items: ['Maximize'] },
// 		{ name: 'document', items: ['Source'] },
// 		'/',
// 		{ name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', , '-', 'RemoveFormat'] },
// 		{
// 			name: 'paragraph',
// 			items: [
// 				'NumberedList',
// 				'BulletedList',
// 				'-',
// 				'Outdent',
// 				'Indent',
// 				'-',
// 				'Blockquote',
// 				'JustifyLeft',
// 				'JustifyRight',
// 				'JustifyCenter',
// 				'JustifyBlock',
// 				'Font',
// 			],
// 		},
// 		{ name: 'styles', items: ['Styles', 'Format','Size'] },
// 		{ name: 'about', items: ['About'] },
// 	],
// 	format_tags: 'p;h1;h2;h3;pre',
// 	removeDialogTabs: 'image:advanced;link:advanced',
// 	removeButtons: 'Subscript,Superscript,Anchor,About',
// }

const config = {
	uiColor: '#FFFFFF',
	toolbarGroups: [
		{ name: 'clipboard', groups: ['undo', 'clipboard'] },
		{ name: 'editing', groups: ['spellchecker'] },
		{ name: 'links', groups: ['links'] },
		{ name: 'insert', groups: ['insert'] },

		{ name: 'tools', groups: ['tools'] },
		{ name: 'colors' },
		{
			name: 'paragraph',
			groups: ['list', 'indent', 'blocks'],
		},
		{ name: 'document', groups: ['mode'] },
		'/',
		{ name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
		{
			name: 'paragraph',
			groups: ['align'],
		},
		{ name: 'styles', groups: ['styles'] },
	],
	extraPlugins: 'editorplaceholder,colorbutton, justify,font',
	editorplaceholder: '...',
	removeButtons: 'About,Subscript,Superscript,Anchor',
}

export default config