import { status } from 'skin/event/status';

const setEnd = (obj, form, story, data, d, action) => {	
	if (obj.name !== 'scale.description') return status.action
	const text = data.s.values.fld['scale.description']
	const max = text.split('\n').reduce((res, el) => el.length > res ? el.length : res, 0)
	const str = text.split('\n').length
	data.setValue('symb', max)
	data.setValue('str', str)
};

export default setEnd;
