import cnfTask from 'config_task';
import { status } from "skin/event/status";

const dt = (obj, ms, story, data, d, action) => {
	if (obj.action  !== 'wOut') return status.action
	const url = cnfTask.url + 'api/work/out/pug?';
	const q = new URLSearchParams({ id:obj.work }).toString();
	window.location.href = url + q;
};

export default dt;
