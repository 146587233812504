import { message } from 'skin/event';
import { status } from 'skin/event/status';

function check(data, story, action) {
	const cur = story.curPage;
	switch (cur) {
		case 0: {
			const row = story.current?.table?.dlvTims?.row;
			if (!row) {
				message(`Выберите время выезда!`, 'Формирование выездов');
				return status.ignore;
			}
			data.curData.values.fld.dlvTimeId = row;
			break;
		}
		default:
			return status.action;
	}
	// TODO добавлено 07.02.24 
	return status.action;
}

export default check;
