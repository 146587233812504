import { status } from 'skin/event/status'

function combi(obj, ms, story, data, d, action) {
	// название товара
	const name = data.curData.values.fld.name
	// доступные на списание (сохраненный оригинал списка на сервере)
	const reserve = data.curData.values.table.prdWriteoff.reserve
	// выбранный фильтр на форме
	const filter = obj.value

	// отфильтрованный список
	if (name === filter) {
		data.curData.values.table.prdWriteoff.list = reserve
		return status.action
	}
	data.curData.values.table.prdWriteoff.list = reserve.filter((row) =>
		row.itemsName.includes(filter)
	)
	return status.action
}

export default combi
