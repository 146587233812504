import { status } from 'skin/event/status';
import { message } from 'skin/event';
import { dictionary } from 'skin/event';

const cPart = (obj, ms, story, data, d, action) => {
	const info = data.curData.values.fld.partnerId
	if (!info) {
		message(`Укажите партнера`, 'Партнерские продукты');
		return status.ignore
	}
	const o = {
		...obj,
		info: [info]
	}
	dictionary(o)
	return status.ignore;
};

export default cPart;
