import {useContext} from 'react'
import {Cnt} from 'skin/context'
import { observer } from 'mobx-react-lite'
import m from "skin/store/mb";
import Page from 'skin/table/page';
import './style.css';

const Footer = props => {
	const mb = m.isMobile;
	const {action} = useContext(Cnt)
	let cls = [
		'skin-tbl-footer',
		'tbl-footer'
	]
	cls = cls.join(' ')
	return (
		<>
		{!mb && 
		<div className={cls}>
			<button className='btn primary' onClick={clear}>Очистить</button>
			<Page />
		</div>}
		</>
		
	)
	// Очистить фильтры
	function clear() {
		const o = {
			type: 'filter',
			action: 'clear'
		}
		action(o)
	}
}

export default observer(Footer)
