import formAct from './form';
import dataAct from './data';
import pageAct from './page';
import tableAct from './table';
import controlAct from './control';
import actionAct from './action';

const def = {
	form: formAct,
	table: tableAct,
	page: pageAct,
	control: controlAct,
	action: actionAct,
	data: dataAct,
};

export default def