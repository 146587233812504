import { observer } from 'mobx-react-lite';
import List from './list';
import './style.css';
import useMsg from './use';

const Message = ({children, mb, close, refD}) => {
	let cls = [mb ? 'skin-hdr-r-message-mb': 'skin-hdr-r-message', 'hdr-r-message', mb ? 'ico-mb' : 'ico'];
	cls = cls.join(' ');
	const st = { backgroundImage: 'url(skin/img/list.svg)' };
	const {click, show, count, setCount, clos} = useMsg(refD, close)
	return (
		<>
			<span
				className={cls}
				style={st}
				title='Сообщения'
				onClick={click}
			>
				{children}
				{count > 0 && <div className= {'skin-hdr-r-message-count'}>{count}</div>}
			</span>
			{show && <List onClose = {clos} setCount={setCount} show={show} ></List>}
		</>
	)
}

export default  observer(Message)
