import { status } from 'skin/event/status';
import loadData from './load_data';

const form = (obj, form, story, data, d, action) => {
	switch (obj.action) {
		case 'loadData':
			return loadData(obj, form, story, data, d, action);
		default:
	}
	return status.action;
};

export default form;
