import './style.css';
import cnfTask from 'config_task'

const help = props => {
	let cls = ['skin-hdr-r-help', 'hdr-r-help', 'ico', props.mb ? 'mb-und' : ''];
	cls = cls.join(' ');
	const st = { backgroundImage: 'url(skin/img/help.svg)'};
	return (
		<span
			className={cls}
			style={st}
			title='Вопрос-ответ'
			onClick={_ => click()}
		></span>
	)
}

function click() {
	const url = "https://support.flet.su/"
	// const url = cnfTask.url + 'api/print/help'
	window.open(url)
}
export default help
