import { message } from 'skin/event';
import api from 'skin/http';
import { status } from 'skin/event/status';

const click = (obj, ms, story, data, d, action) => {
	console.log('[Получаемые продукты - Мастер] событие', obj);
	const id = data.curData.values.fld.productId
	const name = data.curData.values.fld.txtPrd
	let value = data.curData.values.fld.txtVal
	if (!value) {
		message(`Выберите значение строки`, ms.bookTitle)
		return status.ignore
	}
	value = value.match(/(\d+)([а-яё]+)/)
	const val = +value[1]
	const unit = value[2]
	const code = data.curData.values.fld.txtUn
	const curQr = data.curData.values.table.pacProd.list.length ? data.curData.values.table.pacProd?.list[0]?.code : code
	if (curQr && code !==curQr) {
		message(`Товары должны быть одного типа`, ms.bookTitle)
		return status.ignore
	}
	if (obj.code === 'btnOne') {
		const row = {
			key: id,
			code,
			list: [code, name, val, unit ]
		}
		data.addRow(ms, 'pacProd', row)
	};
	if (obj.code === 'btnAll') {
		const config = {
			method: 'get',
			url: 'api/other/master/pacMaster/' + code,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		};
		api(config)
			.then(res => {
				res.data.rows.forEach(el => {
					data.addRow(ms, 'pacProd', el)
				});
			})
			.catch(console.log)
			.finally(_ => {return status.action})
	}
	return status.action
	}

export default click;
