import { status } from 'skin/event/status';
import tarProp from './tarProp';


const add = (obj, form, story, data, d, action) => {
	switch (obj.table) {
		case 'tarProp':
			return tarProp(obj, form, story, data, d, action);
		default:
	}
	return status.action;
};

export default add;