import Item from './item';
import './style.css';
import all from './list';
import m from "skin/store/mb";
import { observer } from 'mobx-react-lite'
import Burger from 'skin/table/content/command/control/burger';

const Control = ({ action, data }) => {
	const mb = m.isMobile;
	let cls = ['skin-cmptbl-com-control', 'tbl-com-control'];
	cls = cls.join(' ');

	return (
		<div className={cls}>
			{all.map((el) => (<Item key={el.id} data={el} click={click} />))}
		</div>
	);

	function click(item) {
		const o = {
			type: 'control',
			action: item,
			table: data.code,
		};
		action(o);
	}
};

export default observer(Control);
