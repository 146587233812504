import cnfTask from 'config_task';
import { observer } from "mobx-react-lite";

import user from 'skin/store/user';

import Balance from "../../right/balance";
import Logout from "../../right/logout";
import Message from "../../right/message";
import Personal from "../../right/personal";
import Link from './link';
import useBurger from './use';

const List = ({onClose}) => {
    const {mb, ref, refD} = useBurger(onClose)

    return ( 
        <div ref={ref} className= "skin-header-burger-drop">
            <div className="skin-header-burger-drop-user">  
                <Personal avatar={user.avatar} info={[user.id]} />
			    <span className="user-name" title={user.name}>
				    {user.name}
			    </span>
                <Logout mb = {mb}/>
            </div>
            <div><Balance blc={user.blc} tar={user?.tar} adm={user.adm} mb = {mb} /></div>
			<Message mb = {mb} close={onClose} refD ={refD}> <p>Сообщения</p></Message>
			<Link />
			<p style={{fontSize:'12px', textAlign:'center'}}>{cnfTask.copyright}</p>
        </div>
    )
}


export default observer(List);