import { status } from 'skin/event/status';
import category from './category';
import outline from './outline';
import primary from './primary';
import secondary from './secondary';

const setInput = (obj, ms, story, data, d, action) => {
	switch (obj.name) {
		case 'primary':
			return primary(obj, ms, story, data, d, action);
		case 'secondary':
			return secondary(obj, ms, story, data, d, action);
		case 'outline':
			return outline(obj, ms, story, data, d, action);
		case 'category':
			return category(obj, ms, story, data, d, action);
		
		default:
			return status.action;
	}
};

export default setInput;
