import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { space } from 'skin/event';
import cnfSkin from 'config_skin';
import tskSkin from 'config_task'
import api from 'skin/http';

// import arr from "./menu_test";

class Data {
	s = {
		load: false,
		parent: '',
		current: '',
		open: {},
		tree: false,
		double: false,
		// list: arr,
		list: [],
	};
	constructor(props) {
		makeAutoObservable(this);
		this.closeAll = this.closeAll.bind(this);
		this.click = this.click.bind(this);
		this.mnUp = this.mnUp.bind(this);
		this.home = this.home.bind(this);
		this.tree = this.tree.bind(this);
		this.getMenu = this.getMenu.bind(this);
	}
	// Вкл/Выкл дерево меню
	tree() {
		this.s.tree = !this.s.tree;
	}
	// Свернуть все элементы
	closeAll() {
		this.s.open = {};
		this.s.parent = '';
		this.s.current = '';
	}
	// Клик на древовидном меню
	click(id) {
		const a = this.s.list.slice();
		const i = a.findIndex((el) => el._id === id);
		if (i < 0) return;
		if (a[i].type === 'folder') {
			if (this.s.parent === a[i]._id) {
				this.s.open[id] = !this.s.open[id];
			} else {
				this.s.parent = a[i]._id;
			}
		} else {
			this.action(i);
		}
		this.s.list = a;
	}
	// Клик на большом меню
	mnClick(id) {
		const a = this.s.list.slice();
		const i = a.findIndex((el) => el._id === id);
		if (i < 0) return;
		if (a[i].type === 'folder') {
			if (!this.s.doube) {
				this.s.current = id;
			}
			if (this.s.current === id) {
				this.s.open[id] = true;
				this.s.current = '';
				this.setParent(id);
			}
			this.s.current = id;
		} else {
			this.action(i);
		}
		this.s.list = a;
	}
	// Установить нового родителя
	setParent(id) {
		this.s.parent = id ?? '';
	}
	// Выйти из папки
	mnUp(id) {
		if (!this.s.parent) return;
		if (this.s.double && id && this.s.current !== id) {
			this.s.current = id;
			return;
		}
		this.s.current = this.s.parent;
		const a = this.s.list;
		const o = a.find((el) => el._id === this.s.parent);
		this.setParent(o.parent);
	}
	// Выйти в начало меню
	home() {
		this.s.parent = '';
		this.s.current = '';
	}
	// Поиск пункта меню по его коду
	findCommand(val) {
		const a = this.s.list;
		let o = a.find((el) => el.code === val);
		if (!o) o = a.find((el) => el.title === val);
		if (!o) return;
		this.setParent(o?.parent ?? '');
		this.s.current = o._id;
	}
	// Запуск на исполнение
	action(n) {
		const o = this.s.list[n];
		if (this.s.current !== o._id) {
			this.s.current = o._id;
			if (this.s.double) return;
		}
		if (!o.action) {
			alert('Действие не определено');
			return;
		}
		o.action.title = o.action.title ?? o.title;
		const obj = toJS(o.action);
		space(obj, true, true);
	}
	// Переключатель чтения данных меню
	setLoad(val = false) {
		this.s.load = val;
	}
	// Получить данные по меню
	getMenu() {
		const self = this;
		const config = {
			method: 'get',
			url: cnfSkin.url + 'api/menu',
		};
		const cnf = {
			method: 'get',
			url: tskSkin.url + 'api/other/menu'
		}
		Promise.all([
			api(config),
			api(cnf)
		])
			.then(([response, mrkt]) => {
				const r = response.data.result;
				const except = mrkt.data.result;
				const role = mrkt.data.role
				runInAction((_) => {
					self.s.load = true;
					self.s.list = r.filter(el => !except.includes(el._id) || role.includes('root'));
					self.s.except =  except;
					self.s.parent = '';
					self.s.current = '';
					self.s.open = {};
				});
			})
			.catch(console.log);
	}
}

export default new Data();
